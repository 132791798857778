import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ZReportDetail,
  ReportSearch,
  ZReportSummary,
} from 'src/app/models/report.model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { ReportService } from 'src/app/services/report.service';
import { StoreService } from 'src/app/services/store.service';

import '../../../../assets/calibri-normal';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';
import { CellDef } from 'jspdf-autotable';
@Component({
  selector: 'z-report-summary',
  templateUrl: './z-report-summary.component.html',
  styleUrls: ['./z-report-summary.component.scss'],
  providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class ZReportSummaryComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  dataSource: ZReportSummary[];
  readonly allowedPageSizes = [10, 15, 'all'];

  readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  displayMode = 'compact';

  showPageSizeSelector = true;

  showInfo = true;

  showNavButtons = true;
  constructor(
    public storeService: StoreService,
    public helperService: HelperService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private reportService: ReportService,
    private globalService: GlobalService
  ) {
    this.avg = this.avg.bind(this);
  }

  ngOnInit(): void { }

  onSearched(e: ReportSearch): void {
    this.globalService.show();

    this.reportService.getZReporSummaryReport(e).then(
      (res) => {
        this.dataSource = res;
        setTimeout(() => {
          this.globalService.hide();
        });
      },
      (e) => {
        this.globalService.hide();
      }
    );
  }

  clear(): void {
    this.dataSource = [];
  }
  toplam(key: string, key2?: string): number {
    if (HelperService.isNullOrUndefined(this.dataSource)) {
      return 0;
    }
    return this.dataSource.reduce((accumulator, currentValue) => {
      if (!HelperService.isNullOrUndefined(key2)) {
        return (
          accumulator +
          Number(currentValue[key] ?? 0) -
          Number(currentValue[key2] ?? 0)
        );
      }
      return accumulator + Number(currentValue[key] ?? 0);
    }, 0);
  }
  avg(e): string {
    // console.log('avg:', e);
    if (!e) {
      return this.decimalPipe.transform(0, '1.2-2') + '%';
    }

    return this.decimalPipe.transform(e, '1.2-2') + '%';
  }

  exportExcel(e: HTMLElement): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Z Özet Raporu');
console.log('exportExcel',this.dataGrid.instance)
    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Z Özet Raporu.xlsx'
        );
      });
    });
  }

  exportPDF(): void {
    // console.log(this.dataGrid);
    const doc = new jsPDF();
    doc.setFont('calibri', 'normal');
    const statusLookUp: any[] = [];
    const headers: PdfMakeModel[] = [
      {
        key: 'buildingName',
        display: 'Yapı Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'storeName',
        display: 'Mağaza Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'zReportDate',
        display: 'Taih',
        customValue: (el) =>
          this.datePipe.transform(el.zReportDate, 'dd.MM.yyyy'),
      },
      {
        key: 'ecrSerialId',
        display: 'ECR Serial',
      },
      {
        key: 'zReportNumber',
        display: 'Z No',
        cell: { styles: { cellWidth: 20 } },
        customValue: (el) =>
          this.decimalPipe.transform(el.zReportNumber ?? 0, '1.0-0'),
      },
      {
        key: 'ekuNo',
        display: 'Eku No',
        cell: { styles: { cellWidth: 20 } },
        customValue: (el) => this.decimalPipe.transform(el.ekuNo ?? 0, '1.0-0'),
      },
      {
        key: 'receiptCount',
        display: 'Fiş Sayısı',
        cell: { styles: { halign: 'right' } },

        // customValue: (el) => this.decimalPipe.transform(el.quantity, '1.0-2'),
      },
      {
        key: 'receiptTotal',
        display: 'Fiş Toplamı',
        cell: { styles: { halign: 'right' } },
        // customValue: (el) => el.vatRate + '%',
        customValue: (el) =>
          this.currencyPipe.transform(el.receiptTotal ?? 0, '₺'),
      },
      {
        key: 'receiptVatTotal',
        display: 'Fiş KDV Toplamı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.receiptVatTotal ?? 0, '₺'),
      },
      {
        key: 'billCount',
        display: 'Fatura Sayısı',
        cell: { styles: { halign: 'right' } },
      },
      {
        key: 'billTotal',
        display: 'Fatura Toplamı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.billTotal ?? 0, '₺'),
      },
      {
        key: 'billVatTotal',
        display: 'Fatura KDV toplamı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.billVatTotal ?? 0, '₺'),
      },
      {
        key: 'otherCount',
        display: 'Diğer  işlem sayısı',
        cell: { styles: { halign: 'right' } },
      },
      {
        key: 'otherTotal',
        display: 'Diğer işlem Toplamı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.otherTotal ?? 0, '₺'),
      },



      // {
      //   key: 'status',
      //   display: 'Durum',
      //   lookup: (key) => statusLookUp.find(el => el.status == key)?.text
      // }
    ];
    const summary: CellDef[] = [
      {
        content: 'Toplamlar',
        colSpan: 4,
      },

      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('zReportNumber'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('ekuNo'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('receiptCount'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('receiptTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(
          this.toplam('receiptVatTotal'),
          '₺'
        ),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('billCount'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('billTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('billVatTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('otherCount'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('otherTotal'), '₺'),
      },
    ];
    this.helperService.makePdf(
      'Z Özet Raporu',
      this.dataSource,
      headers,
      false,
      summary
    );
  }
}
