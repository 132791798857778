
<div class="row d-flex justify-content-center" style="margin-top: 10%;">
  <div class="col-3 dx-field justify-content-center">
    <form class="needs-validation">
      <div class="form-group">
          <label for="password">Yeni Şifre</label>
          <input type="password" class="form-control" id="password" [(ngModel)]="password" aria-describedby="emailHelp" [ngModelOptions]="{standalone: true}" placeholder="Yeni Şifre">    
        </div>
      
        <div class="form-group">
          <label for="confirmPassword">Şifre Tekrarı</label>
          <input type="password" class="form-control" id="confirmPassword" [(ngModel)]="confirmPassword" aria-describedby="emailHelp" [ngModelOptions]="{standalone: true}" placeholder="Şifre Tekrarı">      
        </div>
  </form>
    <div class="dx-field d-flex justify-content-center">
      <button type="button" (click)="validate()" class="btn btn-resetpwd">Şifremi Sıfırla</button>
  </div>
  </div>
</div>
