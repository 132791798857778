import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';
import { confirm } from 'devextreme/ui/dialog';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Building, Store } from 'src/app/models/store-model';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-building-operations',
  templateUrl: './building-operations.component.html',
  styleUrls: ['./building-operations.component.scss']
})
export class BuildingOperationsComponent implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) table: ElementRef;

  popupTitle: string = "";
  buildingFormPopupVisible: boolean = false;
  buildingId: string = ""
  public theBoundCallback: Function;
  storesPopupVisible: boolean = false;

  apiKeyPopupVisible: boolean = false;
  storeId: string = ""
  storeUserPopupVisible: boolean = false
  storeFormPopupVisible: boolean = false;
  operationMenuItems: any[] = [
    {
      title: 'EXCELL İNDİR',
      tag: 'excel',
    },
    {
      title: 'PDF İNDİR',
      tag: 'pdf',
    },
    {
      title: 'YAPI EKLE',
      tag: 'add',
    },
  ];
  dataSource: Building[] = [];
  filterForm: FormGroup;
  filterFormSubscription: Subscription;
  constructor(public storeService: StoreService,
    public glb: GlobalService,
    private fb: FormBuilder,
    public userService: UserService,
    private nbMenuService: NbMenuService,
    private helperService: HelperService) {
    this.getBuildings()

    const formControls: any = {
      id: [],
      name: [],
      city: [],
    };
    this.filterForm = this.createFilterForm(formControls);
  }
  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);
    this.triggerMobileMenu();
  }

  ngOnDestroy(): void {
    if (!HelperService.isNullOrUndefined(this.filterFormSubscription)) {
      this.filterFormSubscription.unsubscribe();
    }
  }
  triggerMobileMenu() {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'build-operation'),
        map(({ item }) => item),
      )
      .subscribe((item: any) => {
        // console.log(item)
        // Export excell
        if (item.tag === 'excel') {
          this.exportexcel(this.table.nativeElement);
          return;
        }
        // Export PDF
        if (item.tag === 'pdf') {
          this.exportPDF()
          return;
        }
        // Add User
        if (item.tag === 'add') {
          this.newBuilding();
          return;
        }
      });
  }

  getBuildings() {
    this.glb.loadingText = "Yapılar Listeleniyor..."
    this.glb.loadingVisible = true
    this.storeService.getBuildings().then(o => {
      this.glb.loadingVisible = false;
      this.dataSource = HelperService.Filter<Building>(this.storeService.buildingList, {});
    })
  }

  public theCallback() {
    this.buildingFormPopupVisible = false;
    this.apiKeyPopupVisible = false;
    this.storeFormPopupVisible = false;
    this.storeUserPopupVisible = false;
    this.storeService.getBuildings().then(o => {
      this.filterForm.reset();
    });


  }

  delete(buildingId, name?) {
    const result = confirm("Yapıyı silmek istediğinize emin misiniz?", "Yapı Sil");
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.glb.loadingText = "Siliniyor..."
        this.glb.loadingVisible = true
        this.storeService.deleteBuilding(buildingId).then(o => {
          if (o) {
            this.glb.loadingVisible = false;
            this.glb.pushToast('Yapı Silindi', "success", 2000)
            this.getBuildings()
          }
        }, (e: HttpErrorResponse) => {
          console.error('Delete build Error:', e);
          if (e?.error?.message === 'in_use_cannot_delete') {
            this.glb.pushToast(`${name} Yapısı silinemez.Lütfen önce tüm ilişkili verileri silin. `, "error", 3000)

          }
        })
      }
    })
  }

  edit(id) {
    this.popupTitle = "Yapı Düzenle"
    this.buildingId = id
    this.buildingFormPopupVisible = true
  }



  newBuilding() {
    this.popupTitle = "Yeni Yapı Ekle"
    this.buildingId = ""
    this.buildingFormPopupVisible = true
  }


  showStores(e: Building) {
    this.storesPopupVisible = true;
    this.storeService.activeBuilding = e;
  }

  newStore() {
    this.storeId = ""
    this.popupTitle = "Yeni Mağaza"
    this.storeFormPopupVisible = true;
  }

  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe(filters => {
     // console.log("Filters:", filters);
      this.dataSource = [...HelperService.Filter<Building>(this.storeService.buildingList, filters)];
     // console.log("dataSource:", this.dataSource);
    });
    return form;
  }


  /** ------- Excel test  */
  /*name of the excel-file which will be downloaded. */
  exportexcel(e: HTMLElement) {
    HelperService.exportexcel(e, 'Yapı İşlemleri.xlsx', 'C')
  }
  exportPDF() {

    const headers: PdfMakeModel[] = [
      {
        key: 'id',
        display: 'Yapı ID',
      },
      {
        key: 'name',
        display: 'Yapı Adı',
      },
      {
        key: 'city',
        display: 'İl',

      },
    ]
    this.helperService.makePdf('Yapı İşlemleri', this.dataSource, headers);
  }

}
