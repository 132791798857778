<dx-popup #buildForm [height]="400" [deferRendering]="false" [width]="this.glb.setPopupWidth()" [showCloseButton]="true"
    [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="buildingFormPopupVisible" [title]="popupTitle">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%" *ngIf="buildForm?.visible">
            <app-building-form [myCallback]="theBoundCallback" [buildingId]="buildingId"></app-building-form>
        </dx-scroll-view>
    </div>
</dx-popup>
<dx-popup [height]="700" [deferRendering]="false" [width]="this.glb.setPopupWidth()" [showTitle]="true"
    [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="storeFormPopupVisible"
    [title]="popupTitle">
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%">
            <app-store-form [myCallback]="theBoundCallback" [storeId]="storeId"></app-store-form>
        </dx-scroll-view>
    </div>
</dx-popup>
<dx-popup [height]="400" [deferRendering]="false" [width]="this.glb.setPopupWidth()" [showTitle]="true"
    [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="storesPopupVisible"
    [title]="popupTitle">
    <div *dxTemplate="let data of 'title'">
        <div class="d-flex justify-content-between">
            <div class="d-flex text-bold" style="align-items: center;">
                <div>
                    <span style="font-weight: bolder;">
                        {{storeService.activeBuilding.name}}
                    </span>
                </div>
                <div>
                    <button (click)="newStore()" class="ml-2 k-button" nbButton>
                        <span class="material-icons-outlined mr-2" style="font-size: 20px;">
                            store
                        </span>
                        Mağaza Ekle
                    </button>
                </div>
            </div>
            <div>
                <button (click)="storesPopupVisible=false" class="ml-2 k-button" nbButton>
                    <span class="material-icons-outlined mr-2" style="font-size: 20px;">
                        close
                    </span>

                </button>
            </div>
        </div>
    </div>
    <div *dxTemplate="let data of 'content'">
        <dx-scroll-view width="100%" height="100%" *ngIf="storesPopupVisible">
            <store-list-popup [refresh]="storeFormPopupVisible"></store-list-popup>
        </dx-scroll-view>
    </div>
</dx-popup>
<!-- <div class="row mr-auto operaion-buttons">
    <ul>
        <li data-toggle="tooltip" (click)="newBuilding()" title="Yeni Yapı Ekle">
            <span class="material-icons-outlined">
                add_circle_outline
            </span>
            <span></span>
        </li>

    </ul>
</div> -->
<div class="d-none d-md-flex  justify-content-md-end  my-2">
    <div style="min-width: 500px; text-align:end;">
        <button class="k-button" (click)="exportexcel(table)" nbButton>
            <span class="mr-2">
                <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
            </span>
            Excel İndir
        </button>
        <button class="ml-2 k-button" (click)="exportPDF()" nbButton>
            <span class="material-icons-outlined mr-2" style="font-size: 18px;">
                picture_as_pdf
            </span>
            PDF İndir
        </button>
        <button *ngIf="userService.user.userRight!='user'" (click)="newBuilding()" class="ml-2 k-button" nbButton>
            <span class="material-icons-outlined mr-2" style="font-size: 20px;">
                business
            </span>
            Yapı Ekle
        </button>
    </div>
</div>
<div style="clear:both"></div>
<!-- Mobile menu -->
<div class="d-md-none d-flex justify-content-end mb-2">
    <button [nbContextMenu]="operationMenuItems" nbContextMenuTag="build-operation" size="large" nbButton ghost>
        <nb-icon icon="menu-outline"></nb-icon>
    </button>
</div>
<!-- Mobile menu End -->

<div class="row">
    <div class="col-12 table-style">
        <table class="table" #table>
            <thead style="max-height: 50px;">
                <tr>
                    <th>Yapı ID</th>
                    <th>Yapı Adı</th>
                    <th>İl</th>
                    <!-- <th>Loglar</th> -->
                    <th *ngIf="userService.user.userRight!='user'">Mağazalar</th>
                    <!-- <th>Durum</th> -->
                    <th *ngIf="userService.user.userRight!='user'">İşlemler </th>
                </tr>
                <tr [formGroup]="filterForm">
                    <th>
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="id">
                        </nb-form-field>
                    </th>

                    <th>
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="name">
                        </nb-form-field>
                    </th>
                    <th>
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="city">
                        </nb-form-field>
                    </th>
                    <!-- <th>

                    </th> -->
                    <th *ngIf="userService.user.userRight!='user'">

                    </th>
                    <th *ngIf="userService.user.userRight!='user'">

                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataSource">
                    <td>
                        {{item.id}}
                    </td>
                    <td>{{item.name}}</td>
                    <td>{{item.city}}</td>
                    <!-- <td>
                        <span data-toggle="tooltip" title="Loglar">
                            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/clipboard-list--v3.png"
                                width="24" style="margin-right: 5px;" />
                        </span>
                    </td> -->
                    <td style="padding: 0 !important;" *ngIf="userService.user.userRight!='user'">

                        <button nbButton ghost size="small" (click)="showStores(item)">
                            <span class="material-icons-outlined" data-toggle="tooltip" title="Mağazalar">
                                storefront
                            </span>
                        </button>
                    </td>
                    <td *ngIf="userService.user.userRight!='user'" style="padding: 0 !important;">


                        <button nbButton ghost (click)="edit(item.id)">
                            <nb-icon icon="edit-outline"></nb-icon>
                        </button>
                        <button nbButton ghost (click)="delete(item.id,item.name)">
                            <nb-icon icon="trash-2-outline"></nb-icon>
                        </button>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>
</div>