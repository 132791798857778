import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';
import { confirm } from 'devextreme/ui/dialog';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { MockService } from 'src/app/services/mock.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DefaultErrorResponse } from 'src/app/models/global-models';
import { resourceUsage } from 'node:process';

@Component({
  selector: 'app-store-operations',
  templateUrl: './store-operations.component.html',
  styleUrls: ['./store-operations.component.scss'],
})
export class StoreOperationsComponent implements OnInit, OnDestroy {
  @ViewChild('table', { static: false }) table: ElementRef;

  popupTitle: string = '';
  apiKeyPopupVisible: boolean = false;
  storeId: string = '';
  storeUserPopupVisible: boolean = false;
  storeFormPopupVisible: boolean = false;
  deleteStoreTransactionFormVisible : boolean = false;

  buildList = this.storeService.buildingList;

  public theBoundCallback: Function;
  operationMenuItems: any[] = [
    {
      title: 'EXCELL İNDİR',
      tag: 'excel',
    },
    {
      title: 'PDF İNDİR',
      tag: 'pdf',
    },
    {
      title: 'MAĞAZA EKLE',
      tag: 'add',
    },
  ];
  dataSource: Store[] = [];
  filterForm: FormGroup;
  filterFormSubscription: Subscription;
  constructor(
    public storeService: StoreService,
    public us : UserService,
    public glb: GlobalService,
    private fb: FormBuilder,
    public userService: UserService,
    private nbMenuService: NbMenuService,
    private helperService: HelperService,
    private mockService: MockService
  ) {
    this.storeService.getBuildings();
    this.getStores();
    const formControls: any = {
      id: [],
      name: [],
      commercialName: [],
      taxOffice: [],
      taxNo: [],
      address: [],
      buildingId: [],
      status: [],
    };
    this.filterForm = this.createFilterForm(formControls);
  }
  ngOnDestroy(): void {
    if (!HelperService.isNullOrUndefined(this.filterFormSubscription)) {
      this.filterFormSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);
    this.triggerMobileMenu();
  }
  triggerMobileMenu() {
    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'store-operation'),
        map(({ item }) => item)
      )
      .subscribe((item: any) => {
      //  console.log(item);
        // Export excell
        if (item.tag === 'excel') {
          this.exportExcel(this.table.nativeElement);
          return;
        }
        // Export PDF
        if (item.tag === 'pdf') {
          this.exportPDF();
          return;
        }
        // Add User
        if (item.tag === 'add') {
          this.newStore();
          return;
        }
      });
  }
  public theCallback() {
    this.apiKeyPopupVisible = false;
    this.storeFormPopupVisible = false;
    this.storeUserPopupVisible = false;
    this.deleteStoreTransactionFormVisible = false;
    this.storeService.getStores().then(() => {
      this.filterForm.reset();
    });
  }

  newStore() {
    this.storeId = '';
    this.popupTitle = 'Yeni Mağaza';
    this.storeFormPopupVisible = true;
  }

  editStore(storeId) {
    this.storeId = storeId;
    this.popupTitle = 'Mağaza Düzenle';
    this.storeFormPopupVisible = true;
  }

  requestKey(store: Store) {
    // sinan.demir@alshaya.com
    // 90(535) 666 95 81
    if (
      HelperService.isEmptyString(store?.person?.gsm) ||
      HelperService.isEmptyString(store?.person?.mail) 
    ) {
      this.glb.pushToast(
        ` ${store.name} mağazası için yetkili bilgileri doldurulmadan Apı-Key bilgisi alınamaz. Lütfen Mağaza Yetkilisi bilgilerini tamamlayıp tekrar deneyin`,
        'warning',
        4000
      );

      return;
    }
    const storeId: string = store.id;
    this.storeService.getStoreById(storeId).then((o) => {
      this.storeId = this.storeService.selectedStore.id;
      this.popupTitle = this.storeService.selectedStore.name + ' API-KEY';
      this.apiKeyPopupVisible = true;
    });
  }

  getBuildingName(buildingId) {
    return this.storeService.buildingList.find((x) => x.id == buildingId).name;
  }

  delete(storeId) {
    const result = confirm(
      'Mağazayı silmek istediğinize emin misiniz?',
      'Mağaza Sil'
    );
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.glb.loadingText = 'Siliniyor...';
        this.glb.loadingVisible = true;
        this.storeService.deleteStore(storeId).then(
          (o) => {
            if (o) {
              this.glb.loadingVisible = false;
              this.glb.pushToast('Mağaza Silindi', 'success', 2000);
              this.getStores();
            }
          },
          (e: HttpErrorResponse) => {
            this.glb.loadingVisible = false;

          //  console.log('Hata:', e);
            const error: DefaultErrorResponse = e.error;
            if (error.message === 'store_in_use') {
              this.glb.pushToast(
                'Bu mağaza silinemez.Üzerinde satış verisi bulunmaktadır.',
                'error',
                3000
              );
            }
          }
        );
      }
    });
  }

  deleteTransactions(storeId)
  {
    this.storeId = storeId
    this.popupTitle = this.dataSource.find(x=>x.id == storeId).name + " Mağaza Hareketlerini Sil"
    this.deleteStoreTransactionFormVisible = true
  }

  getstoreUser(storeId) {
    this.storeId = storeId;
    this.popupTitle = 'Mağaza Yetkili';
    this.storeUserPopupVisible = true;
  }

  getStores() {
    this.glb.loadingText = 'Mağazalar Listeleniyor...';
    this.glb.loadingVisible = true;
    this.storeService.getStores().then((o) => {
      this.glb.loadingVisible = false;
      this.dataSource = HelperService.Filter<Store>(
        this.storeService.storeList,
        {}
      );
    });
  }
  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe((filters) => {
    //  console.log('Filters:', filters);

      this.dataSource = [
        ...HelperService.Filter<Store>(this.storeService.storeList, filters),
      ];
     // console.log('dataSource:', this.dataSource);
    });
    return form;
  }
  exportExcel(e: HTMLElement) {
    HelperService.exportexcel(e, 'Mağaza İşlemleri.xlsx', 'H');
  }
  exportPDF() {
    const headers: PdfMakeModel[] = [
      {
        key: 'id',
        display: 'Mağaza ID',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'name',
        display: 'Mağaza Adı',
      },
      {
        key: 'commercialName',
        display: 'Ünvan',
      },
      {
        key: 'taxOffice',
        display: 'Vergi Dairesi',
      },
      {
        key: 'taxNo',
        display: 'Vergi No',
        cell: { styles: { halign: 'right' } },
      },
      {
        key: 'address',
        display: 'Adres',
      },
      {
        key: 'buildingId',
        display: 'Yapı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'status',
        display: 'Durum',
        lookup: (value) =>
          this.mockService.getuserStatusMocks().find((el) => el.value === value)
            ?.text,
      },
    ];
    this.helperService.makePdf('Mağaza İşlemleri', this.dataSource, headers);
  }
}
