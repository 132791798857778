import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BuildingOperationsComponent } from './components/building-operations/building-operations.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { MonthClosingReportComponent } from './components/report-operations/month-closing-report/month-closing-report.component';
import { MonthlySalesReportComponent } from './components/report-operations/monthly-sales-report/monthly-sales-report.component';
import { SaleDifferenceReportComponent } from './components/report-operations/sale-difference-report/sale-difference-report.component';
import { SalesDetailReportComponent } from './components/report-operations/sales-detail-report/sales-detail-report.component';
import { SalesSummaryReportComponent } from './components/report-operations/sales-summary-report/sales-summary-report.component';
import { ZReportDetailComponent } from './components/report-operations/z-report-detail/z-report-detail.component';
import { ZReportSummaryComponent } from './components/report-operations/z-report-summary/z-report-summary.component';
import { ReserPwdComponent } from './components/reser-pwd/reser-pwd.component';
import { StoreOperationsComponent } from './components/store-operations/store-operations.component';
import { UserOperationsComponent } from './components/user-operations/user-operations.component';
import { AuthGuardService } from './services/auth-guard.service';
import { RouterGuardService } from './services/router-guard.service';
import { UserPremissionGuardService } from './services/user-premission-guard.service';

const ROUTES: Routes = [
  { path: '', redirectTo: 'layout', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'passwordReset', component: ReserPwdComponent },
  { path: 'passwordReset/:token', component: ReserPwdComponent },
  {
    path: 'layout',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    canActivateChild: [RouterGuardService],
    children: [
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'user-operations',
        component: UserOperationsComponent,
        canActivate: [UserPremissionGuardService],
      },
      { path: 'store-operations', component: StoreOperationsComponent },
      { path: 'building-operations', component: BuildingOperationsComponent },
      { path: 'month-closing-report', component: MonthClosingReportComponent },
      { path: 'monthly-sales-report', component: MonthlySalesReportComponent },
      { path: 'sales-detail-report', component: SalesDetailReportComponent },
      { path: 'sales-summary-report', component: SalesSummaryReportComponent },
      { path: 'z-report-detail', component: ZReportDetailComponent },
      { path: 'z-report-summary', component: ZReportSummaryComponent },
      { path: 'sales-difference-report', component: SaleDifferenceReportComponent },
      { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
