import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Menu } from 'src/app/models/sidebar-menu-model';

@Pipe({
  name: 'menu',
})
export class MenuPipe implements PipeTransform {
  /**
   *
   */
  constructor(private deviceService: DeviceDetectorService) {}
  transform(value: Menu[], ...args: any): Menu[] {
    const isMobile: boolean = this.deviceService.isMobile();
    // console.log('isMobile:', isMobile);
    return isMobile ? this.getMobiles(value) : value;
  }
  getMobiles(value: Menu[]): Menu[] {
    value.forEach((el: Menu, index: number) => {
      if (el.children?.length > 0) {
        value[index].children = this.getMobiles(el.children);
      }
    });
    return value.filter((el) => el.isMobile);
  }
}
