<dx-popup
  [height]="200"
  [deferRendering]="false"
  [width]="this.glb.setPopupWidth()"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="apiKeyPopupVisible"
  [title]="popupTitle"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <app-store-api-key
        [myCallback]="theBoundCallback"
        [storeId]="storeId"
      ></app-store-api-key>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  #storeForm
  [deferRendering]="false"
  [maxWidth]="760"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="storeFormPopupVisible"
  [title]="popupTitle"
  position="center"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%" *ngIf="storeForm?.visible">
      <app-store-form
        [myCallback]="theBoundCallback"
        [storeId]="storeId"
      ></app-store-form>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  #storePersonPopup
  [maxHeight]="550"
  [deferRendering]="false"
  [maxWidth]="this.glb.setPopupWidth()"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="storeUserPopupVisible"
  [title]="popupTitle"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%" *ngIf="storePersonPopup.visible">
      <app-store-person-form
        [myCallback]="theBoundCallback"
        [storeId]="storeId"
      ></app-store-person-form>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  #deleteTransctionForm
  [deferRendering]="false"
  [maxWidth]="700"  
  [width]="600"
  [height]="400"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="deleteStoreTransactionFormVisible"
  [title]="popupTitle"
  position="center"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%" *ngIf="deleteTransctionForm?.visible">
      <delete-transactions
        [myCallback]="theBoundCallback"
        [storeId]="storeId"
      ></delete-transactions>
    </dx-scroll-view>
  </div>
</dx-popup>

<div class="d-none d-md-flex justify-content-md-end my-2">
  <div style="min-width: 500px; text-align: end">
    <button class="k-button" nbButton (click)="exportExcel(table)">
      <span class="mr-2">
        <img
          src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png"
          width="18"
        />
      </span>
      Excel İndir
    </button>
    <button class="ml-2 k-button" (click)="exportPDF()" nbButton>
      <span class="material-icons-outlined mr-2" style="font-size: 18px">
        picture_as_pdf
      </span>
      PDF İndir
    </button>
    <button
      *ngIf="userService.user.userRight != 'user'"
      (click)="newStore()"
      class="ml-2 k-button"
      nbButton
    >
      <span class="material-icons-outlined mr-2" style="font-size: 20px">
        store
      </span>
      Mağaza Ekle
    </button>
  </div>
</div>
<div style="clear: both"></div>
<!-- Mobile menu -->
<div class="d-md-none d-flex justify-content-end mb-2">
  <button
    [nbContextMenu]="operationMenuItems"
    nbContextMenuTag="store-operation"
    size="large"
    nbButton
    ghost
  >
    <nb-icon icon="menu-outline"></nb-icon>
  </button>
</div>
<!-- Mobile menu End -->
<div class="row">
  <div class="col-12 shadow table-style">
    <table class="table" #table>
      <thead>
        <tr>
          <th>Mağaza ID</th>
          <th>Mağaza Adı</th>
          <th>Ünvan</th>
          <th>Vergi Dairesi</th>
          <th>Vergi No</th>
          <th>Adres</th>
          <th class="durum">Yapı</th>
          <th class="durum">Pos Firması</th>
          <th class="durum">Durum</th>
          <th>API-KEY</th>
          <th *ngIf="userService.user.userRight != 'user'">İşlemler</th>
        </tr>
        <tr [formGroup]="filterForm">
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="id"
              />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="name"
              />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="commercialName"
              />
            </nb-form-field>
          </th>

          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="taxOffice"
              />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="taxNo"
              />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="address"
              />
            </nb-form-field>
          </th>
          <th class="durum">
            <nb-form-field>
              <!-- <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="buildingId"> -->
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>

              <nb-select
                class="mySelect"
                placeholder="Yapı"
                size="small"
                style="box-shadow: unset !important; width: 100px !important"
                formControlName="buildingId"
              >
                <nb-option *ngFor="let item of buildList" [value]="item.id">{{
                  item.name
                }}</nb-option>
              </nb-select>
            </nb-form-field>
          </th>

          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input
                fieldSize="small"
                type="text"
                nbInput
                formControlName="posCompany"
              />
            </nb-form-field>
          </th>

          <th class="durum">
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <!-- <input fieldSize="small" type="text" nbInput formControlName="status"> -->
              <nb-select
                class="mySelect"
                placeholder="Durum"
                size="small"
                style="box-shadow: unset !important; width: 100px !important"
                formControlName="status"
              >
                <nb-option [value]="null">Temizle</nb-option>

                <nb-option value="open">Açık</nb-option>
                <nb-option value="closed">Kapalı</nb-option>
                <nb-option value="out-of-service">Hizmet Dışı</nb-option>
                <nb-option value="repair">Tadilatta</nb-option>
              </nb-select>

              <!--
                                 <div class="text-center passive" *ngIf="item.status ==  'closed'">
                            <span>Kapalı</span>
                        </div>
                        <div class="text-center active" *ngIf="item.status ==  'open'">
                            <span>Açık</span>
                        </div>
                        <div class="text-center outOfService" *ngIf="item.status ==  'out-of-service'">
                            <span>Hizmet Dışı</span>
                        </div>
                        <div class="text-center outOfService" *ngIf="item.status ==  'repair'">
                            <span>Tadilatta</span>
                        </div>
                            -->
            </nb-form-field>
          </th>
          <th></th>
          <th *ngIf="userService.user.userRight != 'user'"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataSource">
          <td>
            {{ item.id }}
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.commercialName }}</td>
          <td>{{ item.taxOffice }}</td>
          <td class="text-right">{{ item.taxNo }}</td>
          <td>{{ item.address }}</td>
          <!-- <td>{{item.buildingId}}</td> -->
          <td class="durum">{{ this.getBuildingName(item.buildingId) }}</td>
          <td class="durum">{{ item.posCompany }}</td>
          <td class="durum">
            <div class="text-center passive" *ngIf="item.status == 'closed'">
              <span>Kapalı</span>
            </div>
            <div class="text-center active" *ngIf="item.status == 'open'">
              <span>Açık</span>
            </div>
            <div
              class="text-center outOfService"
              *ngIf="item.status == 'out-of-service'"
            >
              <span>Hizmet Dışı</span>
            </div>
            <div
              class="text-center outOfService"
              *ngIf="item.status == 'repair'"
            >
              <span>Tadilatta</span>
            </div>
          </td>
          <td>
            <button
              nbButton
              ghost
              (click)="requestKey(item)"
              style="height: 15px !important; width: 15px !important"
            >
              <span>
                <img
                  style="
                    filter: contrast(0.1);
                    transform: scaleX(-1) scaleY(-1);
                  "
                  src="https://img.icons8.com/metro/26/000000/key.png"
                  width="15"
                />
              </span>
            </button>
          </td>
          <td *ngIf="userService.user.userRight != 'user'" class="d-flex">
            <button
              nbButton
              class="mr-2"
              style="margin: 0; padding: 0;padding-right: 4px;"
              ghost
              (click)="getstoreUser(item.id)"
            >
              <!-- <span class="material-icons-outlined" data-toggle="tooltip" title="Kullanıcılar">
                                people_alt
                            </span> -->

              <nb-icon icon="people-outline"></nb-icon>
            </button>
            <button
              nbButton
              class="mr-2"
              style="margin: 0; padding: 0"
              ghost
              (click)="editStore(item.id)"
            >
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
            <button
              nbButton
              class="mr-2"
              style="margin: 0; padding: 0"
              ghost
              (click)="delete(item.id)"
            >
              <nb-icon icon="trash-2-outline"></nb-icon>
            </button>
            <button
            *ngIf="this.us.user.userRight == 'administrator'"
            nbButton
            class="mr-2"
            style="margin: 0; padding: 0;margin-right: 5px;"
            ghost
            (click)="deleteTransactions(item.id)"
          >
            <nb-icon icon="menu-arrow-outline"></nb-icon>
          </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
