<kerzz-report-filter (onSearched)="onSearched($event)"></kerzz-report-filter>

<div class="d-flex my-2 flex-wrap">
  <button [disabled]="!dataSource || dataSource?.length === 0" class="k-button" (click)="exportExcel(null)" nbButton>
    <span class="mr-2">
      <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
    </span>
    Excel İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="exportPDF()" class="ml-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      picture_as_pdf
    </span>

    PDF İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-lg-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      <nb-icon icon="trash-2-outline"></nb-icon>
    </span>
    Filtreyi Temizle
  </button>
</div>
<div class="col-12 md-wd overflow-auto">
  <dx-data-grid class="" [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false" [showRowLines]="true"
    [cellHintEnabled]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager [visible]="true"  [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="showPageSizeSelector"
      [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
    </dxo-pager>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="sales-detail-state"></dxo-state-storing>
    <dxo-export></dxo-export>
    <dxo-summary>
      <dxi-total-item column="grandTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="vatTotal" [valueFormat]="{ type: 'currency', precision: 2 }" summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="netTotal" [valueFormat]="{ type: 'currency', precision: 2 }" summaryType="sum">
      </dxi-total-item>
    </dxo-summary>

    <dxi-column [allowHiding]="true" dataField="businessDate" dataType="date" format="dd/MM/yyyy" caption="Tarih">
    </dxi-column>
    <dxi-column [allowHiding]="true" dataField="buildingName" caption="Yapı Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="storeName" caption="Mağaza Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="itemGroup" caption="Ürün Grubu"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="itemId" caption="Ürün Kodu"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="itemName" caption="Ürün Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="quantity" caption="T. Miktar">
      <dxo-format type="fixedPoint" [precision]="2"> </dxo-format>
    </dxi-column>
    <dxi-column [allowHiding]="true" dataField="vatRate" dataType="number" caption="KDV Oranı">
      <dxo-format [formatter]="avg"></dxo-format>
    </dxi-column>
    <dxi-column [allowHiding]="true" dataField="grandTotal" dataType="number"
      [format]="{ type: 'currency', precision: 2 }" caption="T. Ciro">
    </dxi-column>
    <dxi-column [allowHiding]="true" dataField="vatTotal" [format]="{ type: 'currency', precision: 2 }"
      caption="T. KDV Tutarı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="netTotal" [format]="{ type: 'currency', precision: 2 }"
      caption="T. Net Satış"></dxi-column>
  </dx-data-grid>
</div>