<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" display="none">
  <symbol id="search" viewBox="0 0 32 32">
    <path
      d="M 19.5 3 C 14.26514 3 10 7.2651394 10 12.5 C 10 14.749977 10.810825 16.807458 12.125 18.4375 L 3.28125 27.28125 L 4.71875 28.71875 L 13.5625 19.875 C 15.192542 21.189175 17.250023 22 19.5 22 C 24.73486 22 29 17.73486 29 12.5 C 29 7.2651394 24.73486 3 19.5 3 z M 19.5 5 C 23.65398 5 27 8.3460198 27 12.5 C 27 16.65398 23.65398 20 19.5 20 C 15.34602 20 12 16.65398 12 12.5 C 12 8.3460198 15.34602 5 19.5 5 z"
    />
  </symbol>
</svg>
<dx-popup
  [height]="500"
  [deferRendering]="false"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="userFormPopupVisible"
  [title]="popupTitle"
>
  <div *dxTemplate="let data of 'content'">
    <app-user-form
      *ngIf="userFormPopupVisible"
      [myCallback]="theBoundCallback"
      [userId]="userId"
    ></app-user-form>
  </div>
</dx-popup>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <a class="navbar-brand" href="#">
    <img [src]="logo" width="150" />
  </a>
  <button
    #btn
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarText"
    aria-controls="navbarText"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <div class="search-panel d-none d-sm-block">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="container">
              <div class="row">
                <div class="col-12 col-xs-12">
                  <!-- <form class="search-form">
                                        <input type="search" value="" placeholder="Arama Yap" class="search-input">
                                        <button type="submit" class="search-button" style="margin-top:-2px">
                                            <svg class="submit-button">
                                                <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#search">
                                                </use>
                                            </svg>
                                        </button>
                                    </form> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ul class="nav navbar-nav navbar-right ml-auto">
      <!-- <li class="nav-item">
                <span class="material-icons-outlined" style="margin-top:10px" data-toggle="tooltip" title="Ayarlar">
                    settings
                </span>
            </li>
            <li class="nav-item">
                <span class="material-icons-outlined" style="margin-top:10px" data-toggle="tooltip" title="Bildirimler">
                    notifications
                </span>
            </li> -->
      <li class="nav-item">
        <!-- <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg" width="40" height="40" class="rounded-circle z-depth-0"
                alt="avatar image">
              </li>
              <li class="nav-item avatar dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink-saju" data-toggle="dropdown"
                  aria-haspopup="false" aria-expanded="false">
                 
                   <span>{{this.us.user.name}}</span><br>
                   <small>{{this.us.user.userRight}}</small>
                </a>
                <div class="dropdown-menu dropdown-menu-lg-right dropdown"
                  aria-labelledby="navbarDropdownMenuLink-saju">
                  <a class="dropdown-item" href="#">Profil</a>
                  <a class="dropdown-item" (click)="this.us.logOut()">Çıkış Yap</a>
                </div> -->
        <nb-actions class="my-2 my-lg-0">
          <nb-action>
            <div
              [nbContextMenu]="userMenu"
              #menu
              nbContextMenuTag="my-context-menu"
              class="d-flex"
            >
              <span style="margin-right: 10px">
                <kerzz-user
                  [name]="us?.user?.name"
                  [title]="getRight(us?.user?.userRight)"
                ></kerzz-user>

                <!-- <nb-user
                  class="mr-3"
                  size="large"
                  [name]="us?.user?.name"
                  [title]="getRight(us?.user?.userRight)"
                  picture="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/fox.jpg"
                >
                </nb-user> -->
              </span>
              <span>
                <nb-icon
                  style="font-size: 2rem"
                  size="large"
                  icon="arrow-ios-downward-outline"
                >
                </nb-icon>
              </span>
            </div>
          </nb-action>
        </nb-actions>
      </li>
      <li></li>
    </ul>
    <app-sidebar
      (itemSelect)="btn.click()"
      class="d-block d-lg-none"
    ></app-sidebar>
  </div>
</nav>
