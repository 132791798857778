<div class="container-fluid">
  <div class="row">
    <app-navbar class="col-12"></app-navbar>
  </div>
  <div class="d-flex flex-nowrap">
    <div class="sidebar">
      <app-sidebar></app-sidebar>
    </div>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
