import { Component, OnInit } from '@angular/core';
import { NbMenuService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  userMenu = [
    { title: 'Profil', id: 0 },
    { title: 'Çıkış Yap', id: 1 },
  ];

  popupTitle: string = 'Profili Düzenle';
  userId: string = '';
  userFormPopupVisible: boolean = false;
  public theBoundCallback: Function;

  isExpanded: boolean = true;
  constructor(
    public us: UserService,
    private menuService: NbMenuService,
    private nbMenuService: NbMenuService,
    public glb: GlobalService,
    private mockService: MockService
  ) {}

  logo = this.glb.getLogoForDomain()
  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);

    this.nbMenuService
      .onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item }) => item)
      )
      .subscribe(async (selected: any) => {
      //  console.log(selected);
        if (selected.id == 0) {
          // TODO: Profile

          this.userId = this.us.user.id;
          this.userFormPopupVisible = true;
        } else if (selected.id == 1) {
          this.us.logOut();
        }
      });
  }

  public theCallback(): void {
    this.userFormPopupVisible = false;
  }
  getRight(value: string): string {
    return this.mockService.getUserRightMocks().find((el) => el.value == value)
      ?.name;
  }
}
