import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from './services/global.service';
import { UserService } from './services/user.service';
import { locale, loadMessages } from 'devextreme/localization';
import dxTrMessages from '../assets/tr.json';
import { NbIconLibraries } from '@nebular/theme';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TestBed } from '@angular/core/testing';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'store-tracking-web';
  constructor(
    private nav: Router,
    public glb: GlobalService,
    public us: UserService,
    private route: ActivatedRoute,
    private iconLibraries: NbIconLibraries,
    private config: NgSelectConfig
  ) {
    this.config.notFoundText = ' Veri Yok';
    this.config.appendTo = 'body';
    // set the bindValue to global config when you use the same
    // bindValue in most of the place.
    // You can also override bindValue for the specified template
    // by defining `bindValue` as property
    // Eg : <ng-select bindValue="some-new-value"></ng-select>
    this.config.bindValue = 'value';
    this.iconLibraries.registerFontPack('font-awesome', { packClass: 'fa' });
    loadMessages(dxTrMessages);
    locale(navigator.language);
    //  this.initializeApp();
  }
  ngAfterViewInit(): void {
    // this.initializeApp();
  }
  ngOnInit(): void {}
  async initializeApp() {
    this.glb.loadingText = 'Sayfa Yükleniyor...';
    this.glb.loadingVisible = true;
    this.glb.loadingVisible = false;
    this.route.queryParams.subscribe((params) => {
      const resetToken = params['resetToken'];
      // console.log('resetToken', resetToken);
      if (resetToken !== undefined) {
        localStorage.setItem('resetToken', resetToken);
        this.nav.navigateByUrl('passwordReset');
      } else {
        let _resetToken = localStorage.getItem('resetToken');
        if (_resetToken != null) {
          this.nav.navigateByUrl('passwordReset');
        } else {
          let _refreshToken = localStorage.getItem('refreshToken');
          if (_refreshToken != null) {
            this.glb.loadingText = 'Giriş Yapılıyor...';
            this.glb.loadingVisible = true;
            this.us.autoLogin(_refreshToken).then(
              (o) => {
                this.glb.loadingVisible = false;
                if (o === true) {
                  this.nav.navigateByUrl('layout/dashboard');
                } else {
                  this.nav.navigateByUrl('login');
                }
              },
              () => {
                this.glb.pushToast('Giriş başarısız.', 'error', 3);
                this.nav.navigateByUrl('login');
              }
            );
          } else {
            this.nav.navigateByUrl('login');
          }
        }
      }
    });
  }
}
