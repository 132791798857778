import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { ChartDataSets } from 'chart.js';
import { Color } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { MothCloseReport, ReportSearch } from 'src/app/models/report.model';
import { Building, LinkedStore, Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { ReportService } from 'src/app/services/report.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [CurrencyPipe],
})
export class DashboardComponent implements OnInit, OnDestroy {
  form: FormGroup;
  millisecotsOfDay = 86400000;
  netTotals = 0;
  countFolios = 0;
  countPaxes = 0;
  isFirstConfig = true;
  get buildId(): FormControl {
    return this.form.get('buildId') as FormControl;
  }
  get storeId(): FormControl {
    return this.form.get('storeId') as FormControl;
  }
  get range(): FormControl {
    return this.form.get('range') as FormControl;
  }
  get serviceTypes(): FormControl {
    return this.form.get('serviceTypes') as FormControl;
  }
  stores: Store[] = [];
  fruits = [
    { fruit: 'Apples', count: 10 },
    { fruit: 'Oranges', count: 12 },
    { fruit: 'Lemons', count: 15 },
    { fruit: 'Pears', count: 20 },
    { fruit: 'Pineapples', count: 3 },
  ];

  colors: Color[] = [
    {
      backgroundColor: ['#6C5DD3', '#FF86EE', '#E7E8EA', '#101F41'],
    },
  ];

  public doughnutChartType = 'doughnut';
  public pieChartOptions = {
    responsive: true,
    position: 'left',
  };
  public barChartOptions = {
    scaleShowVerticalLines: false,
    responsive: true,
  };

  public barChartLabels = [];
  public pieChartLabels = [];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartData: ChartDataSets[] = [];
  public pieChartData: ChartDataSets[] = [];

  dataSource: MothCloseReport[] = [];
  allData: MothCloseReport[] = [];
  filterForm: FormGroup;
  filterFormSubscription: Subscription;

  linkedStores: LinkedStore[] = [];
  filterItems: { stores?: Store[]; builds?: Building[] } = {};
  constructor(
    public storeService: StoreService,
    public glb: GlobalService,
    private fb: FormBuilder,
    private router: Router,
    public helperService: HelperService,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private reportService: ReportService,
    public mockService: MockService
  ) {
    this.getBuildings();
    // this.getStores();
    this.createSearchForm();

    const formControls: any = {
      buildingName: [],
      storeName: [],
      grandTotal: [],
      countFolio: [],
      countPax: [],
    };
    this.filterForm = this.createFilterForm(formControls);
    // this.dataSource = HelperService.Filter<any>(this.list, {});
  }
  createSearchForm(): void {
    this.form = this.fb.group({
      buildId: [],
      storeId: [{ value: [], disabled: true }],
      serviceTypes: [, Validators.required],
      range: [0],
    });

    this.buildId.valueChanges.subscribe((value: string[]) => {
      this.storeId.reset([], { emitEvent: false });
      if (HelperService.isNullOrUndefined(value) || value.length === 0) {
        this.storeId.disable({ emitEvent: false });
      } else if (value.length === 1 && value.some((el) => el === 'All')) {
        this.storeId.disable({ emitEvent: false });
      } else {
        this.stores = [];
        value.forEach((el) => {
          this.stores = [
            ...this.stores,
            ...this.filterItems.stores.filter((f) => f.buildingId === el),
          ];
        });
        this.storeId.enable({ emitEvent: false });

        if (this.isFirstConfig) {
          setTimeout(() => {
            this.isFirstConfig = false;
            const stores: string[] = this.selectAllStore(this.stores, 'id');
          //  console.log('Stores:', stores);
            if (this.reportService.filters.storeId) {
              this.storeId.setValue(this.reportService.filters.storeId);
            } else {
              this.storeId.setValue(stores);
            }
          }, 100);
        }
      }
      // console.log("valueChage:", value);
    });

    this.storeId.valueChanges.subscribe(() => {
      this.refreshStores();
    });
    this.range.valueChanges.subscribe(() => {
      this.refreshStores();
    });
  }
  refreshStores(): void {
    const stores: any[] = this.storeId.value.filter((el) => el !== 'All') ?? [];
    const serviceTypes: any[] =
      this.serviceTypes.value.filter((el) => el !== 'All') ?? [];
    if (
      stores === null ||
      stores.length === 0 ||
      serviceTypes === null ||
      serviceTypes.length === 0 ||
      this.range.value === null
    ) {
      return;
    }
    this.glb.show({ text: 'Lütfen Bekleyin.' });
    if (this.storeId.value?.length > 0) {
      this.reportService.filters = {
        ...this.reportService.filters,
        buildId: this.buildId.value,
        storeId: this.storeId.value ?? null,
      };
    }
    const sDate: Date = this.getStartDate();
    const eDate: Date = this.getEndDate();

    sDate.setUTCDate(sDate.getDate());
    sDate.setUTCHours(0, 0, 0, 0);

    eDate.setUTCDate(eDate.getDate());
    eDate.setUTCHours(23, 59, 59, 999);

    const params: ReportSearch = {
      startDate: sDate,
      endDate: eDate,
      storeIdList: stores,
      serviceTypes: this.serviceTypes.value,
    };

    this.reportService.getMonthClosingReport(params).then(
      (res) => {
        this.glb.hide();
        this.dataSource = res;
        this.allData = res;
        this.refreshCharts();
        this.netTotals = this.sums('grandTotal');
        this.countFolios = this.sums('countFolio');
        this.countPaxes = this.sums('countPax');
      },
      () => {
        this.glb.hide();
      }
    );
  }
  getEndDate(): Date {
    let eDate: Date = new Date();
    const range = this.range.value;

    eDate.setHours(23, 59, 59, 99);

    if (range === 1) {
      eDate = this.getLastOfWeek(eDate);
    } else if (range === 2) {
      eDate = this.getLastOfMonth(eDate);
    }

    return eDate;
  }
  getLastOfWeek(eDate: Date): Date {
    const last = this.getMonday(eDate).getDate() + 6; // last day is the first day + 6
    const lastday: Date = new Date(eDate.setDate(last));

    return lastday;
  }
  refreshCharts(): void {
    const buildLabels: any = {};
    this.colors[0].backgroundColor = [];
    const colors: string[] = [];
    this.barChartLabels = this.dataSource.map((el) => {
      colors.push('#' + Math.floor(Math.random() * 16777215).toString(16));
      if (!buildLabels[el.buildingName]) {
        buildLabels[el.buildingName] = [];
      }
      buildLabels[el.buildingName].push(el);
      return el.storeName;
    });
    this.pieChartLabels = Object.keys(buildLabels);
    const pieChartData: number[] = [];
    Object.keys(buildLabels).forEach((key) => {
      pieChartData.push(
        buildLabels[key].reduce((acc, cur) => {
          return acc + cur.grandTotal;
        }, 0)
      );
    });
    // console.log(pieChartData)
    this.pieChartData = [];
    this.pieChartData.push({
      data: pieChartData,
    });

    this.colors[0].backgroundColor = colors;
    this.barChartData = [];

    this.barChartData.push({
      data: this.dataSource.map((el) => el.grandTotal),
    });
  }
  sums(key: string): number {
    return this.dataSource.reduce((acc, cur) => {
      return acc + cur[key];
    }, 0);
  }
  getStartDate(): Date {
    let sDate: Date = new Date();
    const range = this.range.value;

    sDate.setHours(0, 0, 0, 0);

    if (range === 1) {
      sDate = this.getMonday(sDate);
    } else if (range === 2) {
      sDate = this.getStartOfMonth(sDate);
    }

    return sDate;
  }

  getMonday(d): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  getStartOfMonth(date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 1);
  }
  getLastOfMonth(date): Date {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }
  setFilterItems(): void {
   // console.log('linkedStores:', this.linkedStores);

    this.filterItems.stores = this.storeService.storeList.filter((el) =>
      this.linkedStores.some((s) => s.storeId === el.id)
    );
    this.filterItems.builds = this.storeService.buildingList.filter((el) =>
      this.filterItems.stores.some((s) => s.buildingId === el.id)
    );
    // console.log('filterItem:', this.filterItems);
    this.firstConfigs();
  }
  firstConfigs(): void {
    const builds: string[] = this.selectAllBuilding(
      this.filterItems.builds,
      'id'
    );
    const serviceTypes: string[] = this.selectAllServiceTypes(
      this.mockService.getServiceTypes(),
      'value'
    );

    if (this.reportService.filters.buildId) {
      this.buildId.setValue(this.reportService.filters.buildId);
    } else {
      this.buildId.setValue(builds);
    }

    if (this.reportService.filters.serviceTypes) {
      this.serviceTypes.setValue(this.reportService.filters.serviceTypes);
    } else {
      this.serviceTypes.setValue(serviceTypes);
    }
  }
  getBuildings(): void {
    this.glb.loadingText = 'Yapılar Listeleniyor...';
    this.glb.loadingVisible = true;
    this.storeService.getBuildings().then((o) => {
      this.glb.loadingVisible = false;
      this.getStores();
    });
  }
  getStores(): void {
    this.glb.loadingText = 'Mağazalar Listeleniyor...';
    this.glb.loadingVisible = true;
    this.storeService.getStores().then((o) => {
      this.glb.loadingVisible = false;
      this.userService.getLinkedStores().then((res) => {
        this.linkedStores = res;
        this.setFilterItems();
      });
    });
  }
  ngOnInit(): void {}
  ngOnDestroy(): void {
    if (!HelperService.isNullOrUndefined(this.filterFormSubscription)) {
      this.filterFormSubscription.unsubscribe();
    }
  }
  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe((filters) => {
     // console.log('Filters:', filters);
      this.dataSource = [...HelperService.Filter<any>(this.allData, filters)];
    //  console.log('dataSource:', this.dataSource);
    });
    return form;
  }
  exportExcel(e: HTMLElement): void {
    HelperService.exportexcel(e, 'Bugün Toplam Dağılımları.xlsx', 'E');
  }
  //#region BildingSelection
  selectAllBuilding(buildingList: Building[], key: string): string[] {
    return buildingList.map((el) => el[key]);
  }
  isSlectedBuilding(value: string): boolean {
    return (
      this.buildId?.value?.filter((el) => el !== value).length ===
      this.filterItems.builds?.length
    );
  }
  selectAllBuildButton(): void {
    let values: string[] = [];
    if (!this.isSlectedBuilding('All')) {
      values = this.selectAllBuilding(this.storeService.buildingList, 'id');
    }
    this.buildId.setValue(values, { emitEvent: false });
  }
  //#endregion

  //#region  Store Selection

  selectAllStore(storeList: Store[], key: string): string[] {
    return storeList.map((el) => el[key]);
  }
  isSlectedStore(value: string): boolean {
    return (
      this.storeId?.value?.filter((el) => el !== value).length ===
      this.stores?.length
    );
  }
  selectAllStoreButton(): void {
    let values: string[] = [];
    if (!this.isSlectedStore('All')) {
      values = this.selectAllStore(this.stores, 'id');
    }
    this.storeId.setValue(values, { emitEvent: false });
  }
  //#endregion
  //#region  Service Type Selection

  selectAllServiceTypes(storeList: any[], key: string): string[] {
    return this.mockService.getServiceTypes().map((el) => el[key]);
  }
  isSlectedServiceTypes(value: string): boolean {
    return (
      this.serviceTypes?.value?.filter((el) => el !== value).length ===
      this.mockService.getServiceTypes()?.length
    );
  }
  selectAllServiceTypesButton(): void {
    let values: string[] = [];
    if (!this.isSlectedServiceTypes('All')) {
      values = this.selectAllServiceTypes(
        this.mockService.getServiceTypes(),
        'value'
      );
    }
    this.serviceTypes.setValue(values, { emitEvent: false });
  }
  //#endregion

  log(): void {
  //  console.log('Tüm!...');
  }
  exportPDF(): void {
    const statusLookUp: any[] = [
      {
        status: 'banned',
        text: 'Pasif',
      },
      {
        status: 'deleted',
        text: 'Pasif',
      },
      {
        status: 'registered',
        text: 'Aktif',
      },
      {
        status: 'new',
        text: 'Yeni',
      },
    ];
    const headers: PdfMakeModel[] = [
      {
        key: 'buildingName',
        display: 'Yapı Adı',
      },
      {
        key: 'storeName',
        display: 'Mağaza Adı',
      },
      {
        key: 'grandTotal',
        display: 'Toplam Satış',
        cell: { styles: { halign: 'right' } },
        lookup: (value) => this.currencyPipe.transform(value, '₺'),
      },
      {
        key: 'countFolio',
        display: 'T. Adisyon Sayısı',
        cell: { styles: { halign: 'right' } },
      },
      {
        key: 'countPax',
        display: 'T. Kişi Sayısı',
        cell: { styles: { halign: 'right' } },
      },
    ];
    this.helperService.makePdf(
      'Bugün Toplam Dağılımları',
      this.dataSource,
      headers
    );
  }
}
