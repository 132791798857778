<hr>

<form class="needs-validation store-form">
  <div class="form-row">
    <div class="col-xs-12 col-lg-6 col-xl-6 col-sm-12 col-md-12">
      <div class="form-group">
        <label for="commercialName">Ünvan*</label>
        <input tabindex="1" type="text" class="form-control" id="commercialName" [(ngModel)]="store.commercialName"
          [ngModelOptions]="{standalone: true}" required placeholder="Ad">
        <!-- <div class="invalid-feedback">Check this checkbox to continue.</div> -->
      </div>
      <div class="form-group">
        <label for="taxOffice">Vergi Dairesi*</label>
        <input tabindex="3" type="text" class="form-control" id="taxOffice" [(ngModel)]="store.taxOffice"
          [ngModelOptions]="{standalone: true}" required placeholder="Vergi Dairesi">
      </div>
      <div class="form-group">
        <label for="city">İl*</label>
        <select tabindex="5" class="form-control" #cities id="city" [(ngModel)]="store.city"
          (change)='onCityChanged(cities.value)' [ngModelOptions]="{standalone: true}" data-show-subtext="true"
          data-live-search="true" required>
          <option *ngFor="let item of this.hs.cities" [value]="item.name">{{item.name}}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="town">İlçe*</label>
        <select tabindex="7" class="form-control" #towns id="town" [(ngModel)]="store.town"
          (change)='onTownChanged(towns.value)' [ngModelOptions]="{standalone: true}" required>
          <option *ngFor="let item of this.hs.towns" [value]="item.name">{{item.name}}</option>
        </select>
      </div>


      <div class="form-group">
        <label for="phone">Tel No*</label>
        <input tabindex="8" type="text" class="form-control text-left" id="phone" [(ngModel)]="store.phone"
          [ngModelOptions]="{standalone: true}" mask="00(000) 000 00 00" pattern=".{17,}" required
          placeholder="90 (5xx) xxx xx xx">
      </div>

      <div class="form-group">
        <label for="section">Bölüm*</label>
        <input tabindex="10" type="text" class="form-control" id="section" [(ngModel)]="store.section"
          [ngModelOptions]="{standalone: true}" required placeholder="Bölüm">
      </div>

      <div class="form-group">
        <label for="buildingId">Yapı Seçimi*</label>
        <select tabindex="12" class="form-control" #buildings id="buildingId" [(ngModel)]="store.buildingId"
          (change)='onBuildingChanged(buildings.value)' [ngModelOptions]="{standalone: true}" required>
          <option *ngFor="let item of this.storeService.buildingList" [value]="item.id">{{item.name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="zReportControl">Z Rapor Kontrolü</label>
        <input tabindex="14" style="height: 18px;" type="checkbox" class="form-control text-right" id="zReportControl" [(ngModel)]="store.zReportControl"
         [ngModelOptions]="{standalone: true}">
      </div>

    </div>

    <div class="col-xs-12 col-lg-6 col-xl-6 col-sm-12 col-md-12">
      <div class="form-group">
        <label for="name">Mağaza Adı*</label>
        <input tabindex="2" type="text" class="form-control" id="name" [(ngModel)]="store.name"
          [ngModelOptions]="{standalone: true}" required placeholder="Mağaza Adı">
      </div>
      <div class="form-group">
        <label for="taxNo">Vergi No*</label>
        <input tabindex="4" type="text" class="form-control text-left" id="taxNo" [(ngModel)]="store.taxNo"
          [ngModelOptions]="{standalone: true}" mask="0000000000" [validation]="true" required
          placeholder="Vergi Numarası">
      </div>
      <div class="form-group">
        <label for="address">Adres*</label>
        <textarea tabindex="6" class="form-control" id="address" [(ngModel)]="store.address"
          [ngModelOptions]="{standalone: true}" required placeholder="Adres"></textarea>
      </div>

      <div class="form-group">
        <label for="floor">Kat*</label>
        <input tabindex="9" type="text" class="form-control text-right" id="floor" [(ngModel)]="store.floor" mask="000"
          [ngModelOptions]="{standalone: true}" required placeholder="Kat No">
      </div>

      <div class="form-group">
        <label for="storeM2">Mağaza M2*</label>
        <input tabindex="11" type="text" class="form-control text-right" id="storeM2" [(ngModel)]="store.storeM2"
          mask="000000" [ngModelOptions]="{standalone: true}" required placeholder="Mağaza M3">
      </div>

      <div class="form-group">
        <label for="status">Durum*</label>
        <select tabindex="13" class="form-control" #status id="status" [(ngModel)]="store.status"
          (change)='onStatusChanged(status.value)' [ngModelOptions]="{standalone: true}" required>
          <option *ngFor="let item of this.storeStatus" [value]="item.value">{{item.name}}</option>
        </select>
      </div>

      <div class="form-group">
        <label for="section">Pos Firması*</label>
        <input tabindex="10" type="text" class="form-control" id="section" [(ngModel)]="store.posCompany"
          [ngModelOptions]="{standalone: true}" required placeholder="Pos Firması">
      </div>
   

  

    </div>
    <div class="col-xs-12 col-lg-12 col-xl-12 col-sm-12 col-md-12">
      <div class="form-group">
        <label for="description">Açıklama*</label>
        <textarea tabindex="14" class="form-control" id="description" [(ngModel)]="store.description"
          [ngModelOptions]="{standalone: true}" placeholder="Açıklama Giriniz..."></textarea>
      </div>
    </div>
    <div class="d-flex mr-0 mr-sm-3 justify-content-sm-end justify-content-center  w-100">
      <button tabindex="15" type="submit" (click)="validate()" class="btn btn-submit px-4 px-sm-6">Kaydet</button>
    </div>
  </div>
</form>