<hr>

<form class="needs-validation">
    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label for="name">Ad*</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="store.person.name"
                [ngModelOptions]="{standalone: true}" required placeholder="Ad">
        </div>
        <div class="form-group col-21 col-md-6">
            <label for="lastName">Soyad*</label>
            <input type="text" class="form-control" id="lastName" [(ngModel)]="store.person.lastName"
                [ngModelOptions]="{standalone: true}" required placeholder="Soyad">
        </div>


    </div>
    <div class="form-row">
        <div class="form-group col-12 ">
            <label for="mailaddress">E-Posta*</label>
            <input type="email" class="form-control" id="mailaddress" [(ngModel)]="store.person.mail"
                [ngModelOptions]="{standalone: true}" required placeholder="E-Posta">
        </div>
    </div>
    <div class="form-row">
        <div class="form-group col-12 col-md-6">
            <label for="gsm">GSM*</label>
            <input type="text" class="form-control text-left" id="gsm" [(ngModel)]="store.person.gsm"
                [ngModelOptions]="{standalone: true}" mask="00(000) 000 00 00" pattern=".{17,}" required
                placeholder="GSM" required>
        </div>
    </div>
    <div class="dx-field d-flex justify-content-center">
        <button type="submit" (click)="validate()" class="btn btn-submit">Kaydet</button>
    </div>
</form>