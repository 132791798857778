<ul class="list-group">
    <li class="list-group-item"
        *ngFor="let item of this.storeService.getStoresByBuildId(this.storeService.activeBuilding.id)">

        <div class="d-flex justify-content-between" style="align-items: center;">
            <div>

                {{item.name}}
            </div>
            <div>
                <button nbButton ghost (click)="deleteStore(item.id)">
                    <nb-icon icon="trash-2-outline"></nb-icon>
                </button>
            </div>
        </div>

    </li>
</ul>