import { Injectable } from '@angular/core';
import notify from 'devextreme/ui/notify';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  loadingText: string = '';
  loadingVisible: boolean = false;
  indicatorUrl: string = '../assets/loading.svg';
  constructor() {}

  show(params?: { text: string }): void {
    this.loadingText = params?.text ?? 'Lütfen bekleyin.';
    this.loadingVisible = true;
  }
  hide(param?: { delay?: number }): void {
    setTimeout(() => {
      this.loadingVisible = false;
    }, param?.delay ?? 100);
  }
  pushToast(
    msg: string,
    color: 'error' | 'warning' | 'success',
    duration: number
  ) {
    notify(msg, color, duration);
  }

  setPopupWidth() {
    if (screen.width >= 576) {
      return '550px';
    } else {
      return 'auto';
    }
  }

  static isNullOrUndefined(item): boolean {
    return item == null || item == undefined;
  }
  static isEmptyString(item: string): boolean {
    if (this.isNullOrUndefined(item)) return true;
    return (
      item === '' ||
      item.trim() === '' ||
      item.length === 0 ||
      item.trim().length === 0
    );
  }


  getLogoForDomain(): string | undefined {
    let subDomain = window.location.hostname.split('.')[0];
    let logo = this.logos[subDomain];
    //   this.glb.dbName = this.dbNames[subDomain];
    return logo;
  }


  logos = {
    ozak: '/assets/images/ozak.png',
    istmarina: '/assets/images/istmarina.svg'
  }
}
