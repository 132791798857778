import { Injectable } from '@angular/core';
import { Menu } from '../models/sidebar-menu-model';

let sidebarMenu: Menu[] = [
  {
    name: 'Dashboard',
    active: true,
    icon: 'space_dashboard',
    navigate: '',
    tag: 'dashboard',
    link: 'dashboard',
    isMobile: true,
  },
  {
    name: 'Kullanıcı İşlemleri',
    active: false,
    icon: 'groups',
    navigate: '',
    tag: 'user-operations',
    link: 'user-operations',
  },
  {
    name: 'Yapı İşlemleri',
    active: false,
    icon: 'business',
    navigate: '',
    tag: 'building-operations',
    link: '',
  },
  {
    name: 'Mağaza İşlemleri',
    active: false,
    icon: 'store',
    navigate: '',
    tag: 'store-operations',
    link: 'store-operations',
  },
  {
    name: 'Raporlama İşlemleri',
    active: false,
    icon: 'store',
    navigate: '',
    tag: 'sales-detail-report',
    link: 'sales-detail-report',
    isMobile: true,
    children: [
      {
        name: 'Satış Detay Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'sales-detail-report',
        link: 'sales-detail-report',
        isMobile: true,
      },
      {
        name: 'Satış Fark Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'sales-difference-report',
        link: 'sales-difference-report',
        isMobile: true,
      },
      {
        name: 'Satış Özet Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'sales-summary-report',
        link: 'sales-summary-report',
        isMobile: true,
      },
      {
        name: 'Ay Kapanış Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'month-closing-report',
        link: 'month-closing-report',
        isMobile: true,
      },
      {
        name: 'Aylık Satış Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'monthly-sales-report',
        link: 'monthly-sales-report',
        isMobile: true,
      },
      {
        name: 'Z Detay Raporu ',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'z-report-detail',
        link: 'z-report-detail',
        isMobile: true,
      },
      {
        name: 'Z Özet Raporu',
        active: false,
        icon: 'store',
        navigate: '',
        tag: 'z-report-summary',
        link: 'z-report-summary',
        isMobile: true,
      },
    ],
  },
];

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  constructor() {}

  getSideMenu() {
    return sidebarMenu;
  }
}
