<kerzz-report-filter (onSearched)="onSearched($event)" [isHaveServiceType]="false" [endDate]="false"></kerzz-report-filter>

<div class="d-flex my-2 flex-wrap">
    <button class="k-button" (click)="exportExcel()" nbButton>
        <span class="mr-2">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
        </span>
        Excel İndir
    </button>
    <button (click)="exportPDF()" class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            picture_as_pdf
        </span>
        PDF İndir
    </button>
    <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-lg-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            <nb-icon icon="trash-2-outline"></nb-icon>
        </span>
        Filtreyi Temizle
    </button>
</div>

<div class="col-12 md-wd overflow-auto">
    <dx-data-grid class="" [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false" [showRowLines]="true"
      [cellHintEnabled]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
      <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
      <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxo-pager [visible]="true"  [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="showPageSizeSelector"
      [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
    </dxo-pager>
    <dxo-state-storing [enabled]="true" type="localStorage" storageKey="z-report-detail-state"></dxo-state-storing>
      <dxo-export></dxo-export>
  
      <dxo-summary>
        <dxi-total-item column="zReportTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
          summaryType="sum">
        </dxi-total-item> 
        <dxi-total-item column="zReportReceiptCount" 
          summaryType="sum">
        </dxi-total-item>
        <dxi-total-item column="saleDocumentTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
          summaryType="sum">
        </dxi-total-item>
        <dxi-total-item column="saleDocumentCount" 
          summaryType="sum">
        </dxi-total-item>
        <dxi-total-item column="differenceTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
          summaryType="sum">
        </dxi-total-item>
        <dxi-total-item column="differenceCount"  
          summaryType="sum">
        </dxi-total-item>
      </dxo-summary>
  
      <dxi-column  dataField="buildingName" caption="Yapı Adı"></dxi-column>
      <dxi-column  dataField="storeName" caption="Mağaza Adı"></dxi-column>
      <dxi-column  dataField="businessDate" dataType="date" format="dd/MM/yyyy" caption="Tarih">
      </dxi-column>    
 
      <dxi-column dataField="zReportTotal" caption="Z Toplamı" dataType="number">
        <dxo-format type="fixedPoint" [precision]="2">
        </dxo-format>
    </dxi-column>

    <dxi-column dataField="zReportReceiptCount" caption="Z Raporu Fiş Sayısı" dataType="number">
       
    </dxi-column>
    <dxi-column dataField="saleDocumentTotal" caption="Fiş Toplamı" dataType="number">
        <dxo-format type="fixedPoint" [precision]="2">
        </dxo-format>
    </dxi-column>

    <dxi-column dataField="saleDocumentCount" caption="Satış Adedi" dataType="number">        
    </dxi-column>

    <dxi-column dataField="differenceTotal" caption="Toplam Fark" dataType="number">
        <dxo-format type="fixedPoint" [precision]="2">
        </dxo-format>
    </dxi-column>

    <dxi-column dataField="differenceCount" caption="Fark Adedi" dataType="number">
        <!-- <dxo-format type="fixedPoint" [precision]="2">
        </dxo-format> -->
    </dxi-column>
     
    </dx-data-grid>
  </div>