import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localtTr from '@angular/common/locales/tr';
import { LOCALE_ID, NgModule } from '@angular/core';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import {
  NbDatepickerModule,
  NbMenuModule,
  NbThemeModule,
  NbTimepickerModule,
} from '@nebular/theme';
import { DxLoadPanelModule } from 'devextreme-angular';
import config from 'devextreme/core/config';
import { ChartsModule } from 'ng2-charts';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BuildingFormComponent } from './components/building-form/building-form.component';
import { BuildingOperationsComponent } from './components/building-operations/building-operations.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { MonthClosingReportComponent } from './components/report-operations/month-closing-report/month-closing-report.component';
import { MonthlySalesReportComponent } from './components/report-operations/monthly-sales-report/monthly-sales-report.component';
import { SaleDifferenceReportComponent } from './components/report-operations/sale-difference-report/sale-difference-report.component';
import { SalesDetailReportComponent } from './components/report-operations/sales-detail-report/sales-detail-report.component';
import { SalesSummaryReportComponent } from './components/report-operations/sales-summary-report/sales-summary-report.component';
import { ZReportDetailComponent } from './components/report-operations/z-report-detail/z-report-detail.component';
import { ZReportSummaryComponent } from './components/report-operations/z-report-summary/z-report-summary.component';
import { ReserPwdComponent } from './components/reser-pwd/reser-pwd.component';
import { SelectStoreComponent } from './components/select-store/select-store.component';
import { KerzzButtonGroupComponent } from './components/shared/components/kerzz-button-group/kerzz-button-group.component';
import { KerzzLogComponent } from './components/shared/components/kerzz-log/kerzz-log.component';
import { KzFilterYearComponent } from './components/shared/components/kerzz-report-filter/components/kz-filter-year/kz-filter-year.component';
import { KerzzTableComponent } from './components/shared/components/kerzz-table/kerzz-table.component';
import { NavbarComponent } from './components/shared/navbar/navbar.component';
import { SharedModule } from './components/shared/shared/shared.module';
import { SidebarComponent } from './components/shared/sidebar/sidebar.component';
import { StoreApiKeyComponent } from './components/store-api-key/store-api-key.component';
import { StoreFormComponent } from './components/store-form/store-form.component';
import { StoreListPopupComponent } from './components/store-list-popup/store-list-popup.component';
import { StoreOperationsComponent } from './components/store-operations/store-operations.component';
import { StorePersonFormComponent } from './components/store-person-form/store-person-form.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserOperationsComponent } from './components/user-operations/user-operations.component';
import { UserPermComponent } from './components/user-perm/user-perm.component';
import { GlobalCurrencyPipe } from './helpers/pipes/currency.pipe';
import { DeleteTransactionsComponent } from './delete-transactions/delete-transactions.component';
import { CustomHttpInterceptor } from './services/http.interceptor.service';
registerLocaleData(localtTr);
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

config({
  defaultCurrency: 'TRY',
});

@NgModule({
  declarations: [
    AppComponent,
    GlobalCurrencyPipe,
    LayoutComponent,
    DashboardComponent,
    NavbarComponent,
    SidebarComponent,
    UserOperationsComponent,
    UserPermComponent,
    LoginComponent,
    StoreOperationsComponent,
    StoreApiKeyComponent,
    BuildingOperationsComponent,
    ForgetPasswordComponent,
    UserFormComponent,
    SelectStoreComponent,
    StoreFormComponent,
    ReserPwdComponent,
    BuildingFormComponent,
    StorePersonFormComponent,
    SalesDetailReportComponent,
    SalesSummaryReportComponent,
    MonthClosingReportComponent,
    MonthlySalesReportComponent,
    KerzzButtonGroupComponent,
    KerzzTableComponent,
    StoreListPopupComponent,
    KerzzLogComponent,
    KzFilterYearComponent,
    ZReportDetailComponent,
    ZReportSummaryComponent,
    SaleDifferenceReportComponent,
    DeleteTransactionsComponent,
  ],
  imports: [
    SharedModule,
    AppRoutingModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbDateFnsDateModule.forRoot({ format: 'dd/MM/yyyy' }),

    HttpClientModule,
    DxLoadPanelModule,
    ChartsModule,
    NgxMaskModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: navigator.language },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
