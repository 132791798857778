import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  constructor(public us :UserService) { }

  ngOnInit(): void {
    if (this.us.logged == true)
    {
    //  console.log(this.us.user)
    }
  }

}
