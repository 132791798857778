<kerzz-report-filter (onSearched)="onSearched($event)" [isHaveServiceType]="false"></kerzz-report-filter>

<div class="d-flex my-2 flex-wrap">
  <button [disabled]="!dataSource || dataSource?.length === 0" class="k-button" (click)="exportExcel(null)" nbButton>
    <span class="mr-2">
      <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
    </span>
    Excel İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="exportPDF()" class="ml-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      picture_as_pdf
    </span>

    PDF İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-lg-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      <nb-icon icon="trash-2-outline"></nb-icon>
    </span>
    Filtreyi Temizle
  </button>
</div>

<div class="col-12 md-wd overflow-auto">
  <dx-data-grid class="" [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false" [showRowLines]="true"
    [cellHintEnabled]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager [visible]="true"  [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="showPageSizeSelector"
    [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
  </dxo-pager>
  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="z-report-summary-state"></dxo-state-storing>
    <dxo-export></dxo-export>

    <dxo-summary>


      <dxi-total-item column="vat0BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="vat1BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="receiptCount" [valueFormat]="{ precision: 0 }" summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="receiptTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="receiptVatTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="billCount" [valueFormat]="{ precision: 0 }" summaryType="sum">
      </dxi-total-item>

      <dxi-total-item column="billTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="billVatTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="otherCount" [valueFormat]="{ precision: 0 }" summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="otherTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>

    </dxo-summary>

    <dxi-column [allowHiding]="true" dataField="buildingName" caption="Yapı Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="storeName" caption="Mağaza Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="zReportDate" dataType="date"   caption="Tarih">
    </dxi-column>
    <!-- <div *dxTemplate="let data of 'orginalDate_CellTemplate'">
      {{data.value | date: 'dd/MM/yyyy':'GMT'}}
  </div> -->
    <dxi-column [allowHiding]="true" dataField="ecrSerialId" caption="ECR Serial"></dxi-column>
    <dxi-column [allowHiding]="true"  dataField="zReportNumber" caption="Z No"></dxi-column>
    <dxi-column [allowHiding]="true"  dataField="ekuNo" caption="Eku No"></dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ precision: 0 }" dataField="receiptCount" dataType="number"
      caption="Fiş Sayısı">
    </dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="receiptTotal"
      dataType="number" caption="Fiş Toplamı">
    </dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="receiptVatTotal"
      dataType="number" caption="Fiş KDV Toplamı">
    </dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ precision: 0 }" dataField="billCount" dataType="number"
      caption="Fatura Sayısı">
    </dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="billTotal"
      caption="Fatura Toplamı" [format]="{ precision: 2 }"></dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="billVatTotal"
      caption="Fatura KDV toplamı"></dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ precision: 0 }" dataField="otherCount" caption="Diğer  işlem sayısı">
    </dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="otherTotal"
      caption="Diğer işlem Toplamı"></dxi-column>
  </dx-data-grid>
</div>
