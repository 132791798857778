export class User {
  createDate: Date = new Date()
  updateDate: Date = new Date()
  id: string = ""
  name: string = ""
  mail: string = ""
  image: string = ""
  gsm: string = ""
  status: "new" | "registered" | "deleted" | "banned" = "new"
  dateOfBirth: Date = new Date()
  gender: "male" | "female" | "undefined" = "undefined"
  userRight: "user" | "power-user" | "administrator" = "user"
}
export interface UserLog {
  createDate: Date,
  userId: string,
  logType: string,
  ipAddress: string,
  oldValue: string,
  value: string
}