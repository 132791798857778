import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NbMenuService } from '@nebular/theme';
import { DxPopupComponent } from 'devextreme-angular';

import { from, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { User } from 'src/app/models/user-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { LogService } from 'src/app/services/log.service';
import { UserService } from 'src/app/services/user.service';
import { } from 'src/assets/tr.json'


import { HeaderRowOutlet } from '@angular/cdk/table';
import { Rights } from 'src/app/models/global-models';
import { MockService } from 'src/app/services/mock.service';
@Component({
  selector: 'app-user-operations',
  templateUrl: './user-operations.component.html',
  styleUrls: ['./user-operations.component.scss']
})
export class UserOperationsComponent implements OnInit, OnDestroy {
  @ViewChild('logPopup', { static: false }) logPopup: DxPopupComponent;
  @ViewChild('table', { static: false }) table: ElementRef;

  popupTitle: string = ''
  userId: string = ''
  resetPwdPopupVisible: boolean = false;
  permPopupVisible: boolean = false;
  userFormPopupVisible: boolean = false;
  public theBoundCallback: Function;
  closeResult: string;
  selectedUser: User;

  operationMenuItems: any[] = [
    {
      title: 'EXCELL İNDİR',
      tag: 'excel',
    },
    {
      title: 'PDF İNDİR',
      tag: 'pdf',
    },
    {
      title: 'KULLANICI EKLE',
      tag: 'add',
    },
  ];

  dataSource: User[] = [];
  filterForm: FormGroup;
  filterFormSubscription: Subscription;

  rights: Rights[] = [];

  constructor(
    public glb: GlobalService,
    public us: UserService,
    private fb: FormBuilder,
    private logService: LogService,
    public helperService: HelperService,
    public userService: UserService,
    private nbMenuService: NbMenuService,
    private mockService: MockService) {
    this.getUsers()
    this.fillUserRights();
    const formControls: any = {
      name: [],
      gsm: [],
      mail: [],
      userRight: [],
      status: [],
    };
    this.filterForm = this.createFilterForm(formControls);
  }

  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);

    this.triggerMobileMenu();

  }
  ngOnDestroy(): void {
    if (!HelperService.isNullOrUndefined(this.filterFormSubscription)) {
      this.filterFormSubscription.unsubscribe();
    }

  }
  triggerMobileMenu(): void {
    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user-operation'),
        map(({ item }) => item),
      )
      .subscribe((item: any) => {
      //  console.log(item)
        // Export excell
        if (item.tag === 'excel') {
          this.exportExcel(this.table.nativeElement);
          return;
        }
        // Export PDF
        if (item.tag === 'pdf') {
          this.exportPDF();
          return;
        }
        // Add User
        if (item.tag === 'add') {
          this.newUser();
          return;
        }
      });
  }


  getUsers(): void {
    this.glb.loadingText = 'Kullanıcılar Listeleniyor...'
    this.glb.loadingVisible = true;
    this.us.getUsers().then(o => {
      this.glb.loadingVisible = false;
      this.dataSource = HelperService.Filter<User>(this.us.userList, {});

    })
  }

  public theCallback(): void {
    this.permPopupVisible = false;
    this.userFormPopupVisible = false;
    this.resetPwdPopupVisible = false;
    // this.getUsers()
    this.us.getUsers().then(() => {
      this.filterForm.reset();
    })
  }

  openModal(item?: User): void {
    if (!HelperService.isNullOrUndefined(item)) {
      this.userId = item.id;
    }
    this.popupTitle = 'Yetki Düzenleme';
    this.permPopupVisible = true;


  }


  resetPwd(mailAddress): void {
    this.glb.loadingText = 'Şifre sıfırlama talebiniz e-posta adresinizie gönderiliyor...';
    this.glb.loadingVisible = true;
    this.us.resetPasswordMail(mailAddress).then(x => {
      this.glb.loadingVisible = false;
      if (x.message == 'success') {
        this.glb.pushToast('Şifre sıfırlama talebiniz mail adresinize gönderildi.', 'success', 2000)
      }

    })

  }

  newUser(): void {
    this.userId = '';
    this.popupTitle = 'Yeni Kullanıcı';
    this.userFormPopupVisible = true;
  }


  editUser(userId): void {
    this.userId = userId;
    this.popupTitle = 'Kullanıcı Düzenle';
    this.userFormPopupVisible = true;
  }





  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe(filters => {
    //  console.log('Filters:', filters);
      this.dataSource = [...HelperService.Filter<User>(this.us.userList, filters)];
     // console.log('dataSource:', this.dataSource);
    });
    return form;
  }
  exportExcel(e: HTMLElement): void {
    HelperService.exportexcel(e, 'Kullanıcı İşlemleri.xlsx', 'F');
  }
  getLogs(user: User): void {
    this.selectedUser = user;
    this.logPopup.visible = true;
  }
  exportPDF(): void {
    const statusLookUp: any[] = [
      {
        status: 'banned',
        text: 'Pasif'
      },
      {
        status: 'deleted',
        text: 'Pasif'
      },
      {
        status: 'registered',
        text: 'Aktif'
      },
      {
        status: 'new',
        text: 'Yeni'
      },
    ];
    const headers: PdfMakeModel[] = [
      {
        key: 'name',
        display: 'Adı Soyadı',
      },
      {
        key: 'gsm',
        display: 'Telefon NO',
      },
      {
        key: 'mail',
        display: 'Email',
      },
      {
        key: 'userRight',
        display: 'Yetki',
        lookup: (value) => this.rights.find(el => el.value === value)?.name
      },
      {
        key: 'status',
        display: 'Durum',
        lookup: (value) => statusLookUp.find(el => el.status === value)?.text
      }
    ];
    this.helperService.makePdf('Kullanıcı İşlemleri', this.dataSource, headers);
  }

  fillUserRights(): void {
    this.rights = this.mockService.getUserRightMocks();
  }
  getRight(user: User): string {
    // console.log(user, 'rights:', this.rights);
    return this.rights.find(el => el.value === user.userRight)?.name;
  }
}
