import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
@Pipe({
  name: 'currency'
})
export class GlobalCurrencyPipe implements PipeTransform {

  transform(value: number) {
   
    if (!value)  {
      value = 0;
    }   
    return new CurrencyPipe('tr').transform(value, '₺')
  }

}
