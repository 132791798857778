import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-person-form',
  templateUrl: './store-person-form.component.html',
  styleUrls: ['./store-person-form.component.scss']
})
export class StorePersonFormComponent implements OnInit, OnChanges {
  @Input() storeId = "";
  @Input() myCallback: Function;
  store : Store = new Store()
  constructor(public storeService: StoreService,public glb : GlobalService) { }
  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.storeId.currentValue;
    this.storeId = changes.storeId.currentValue;
  //  console.log('storeID', this.storeId)
    if (this.storeId != "") {
      this.getStore()
    }   
  }

  getStore() {
    this.storeService.getStoreById(this.storeId).then(x => {
      this.store = x     
    })
  }

  validate() {
    var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return
    }
   this.saveStore()
  }

  saveStore()
  {  
    this.store.person.gsm = this.store.person.gsm.replace("+","")
    this.store.person.gsm = '+' + this.store.person.gsm
    this.glb.loadingText = "Mağaza Kaydediliyor..."
    this.glb.loadingVisible = true;
  //  console.log(this.store)
    this.storeService.saveStore(this.store).then(x=> {
      this.glb.loadingVisible = false;
      if (x.id != "")
      {
        this.glb.pushToast('Mağaza Kaydedildi.',"success",2000)       
        this.myCallback()
      }
    })
  }


  ngOnInit(): void {
  }

}
