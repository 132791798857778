<!-- {{form.value | json}} -->
<div class="row" [formGroup]="form">


  <div class="col-12 "
    [ngClass]="{ 'col-md-6': !isHaveServiceType || kzFilterYearComponent, 'col-md-4': isHaveServiceType }">
    <nb-select fullWidth multiple formControlName="buildId"
      *ngIf="filterItems?.builds != null || filterItems?.builds != undefined" placeholder="Yapı Seçiniz" size="giant"
      class="my-2 w-100" shape="semi-round">
      <nb-select-label>
        {{
        filterItems?.builds
        | customSelectLabel: buildId.value:"id":"name":"Yapı Seçiniz"
        }}
      </nb-select-label>
      <nb-option [value]="null">Seçimi Temizle</nb-option>
      <nb-option class="AllOption m-0 p-0" [value]="'All'">
        <button class="w-100 multiple nb-transition AllButton text-left" (click)="selectAllBuildButton()">
          {{ isSlectedBuilding("All") ? "Tüm Seçimleri Kaldır" : "Tümünü Seç" }}
        </button>
      </nb-option>

      <nb-option [value]="item.id" *ngFor="let item of filterItems?.builds">{{ item.name }}
      </nb-option>
    </nb-select>
  </div>
  <div class="col-12  "
    [ngClass]="{ 'col-md-6': !isHaveServiceType || kzFilterYearComponent, 'col-md-4': isHaveServiceType }">
    <!-- <nb-select
      fullWidth
      style="width: 100%"
      multiple
      formControlName="storeId"
      placeholder="Mağaza Seçiniz"
      size="giant"
      class="my-2 w-100"
      shape="semi-round"
    >
      <nb-select-label>
        {{
          stores | customSelectLabel: storeId.value:"id":"name":"Mağaza Seçiniz"
        }}
      </nb-select-label>
      <nb-option [value]="null">Seçimi Temizle</nb-option>
      <nb-option class="AllOption m-0 p-0" [value]="'All'">
        <button
          class="w-100 multiple nb-transition AllButton text-left"
          (click)="selectAllStoreButton()"
        >
          {{ isSlectedStore("All") ? "Tüm Seçimleri Kaldır" : "Tümünü Seç" }}
        </button>
      </nb-option>
      <nb-option [value]="item.id" *ngFor="let item of stores"
        >{{ item.name }}
      </nb-option>
    </nb-select> -->

    <ng-select class="my-2  custom" [multiple]="true" [closeOnSelect]="false" [searchable]="true"
      formControlName="storeId" [items]="stores" bindLabel="name" bindValue="id" [clearable]="false">
      <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          <span class="ng-value-label"> {{item.name |slice:0:10}}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">...</span>
        </div>
      </ng-template>
      <!-- <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index">
        <span style="width: 100%;" (click)="selectAllStoreButton()">{{ isSlectedStore("All") ? "Tüm Seçimleri Kaldır" :
          "Tümünü Seç" }}</span>
      </ng-template> -->
      <ng-template ng-header-tmp>
        <div style="width: 100%;cursor: pointer;margin-bottom: 5px;font-size: 1.125rem;font-weight: 700;line-height: 1.5rem;font-family: 'Poppins'
          , sans-serif;" (click)="unSelectAllStore('storeId')">Seçimi Temizle
        </div>
        <div style="width: 100%;cursor: pointer;font-size: 1.125rem;font-weight: 700;line-height: 1.5rem;font-family: 'Poppins'
        , sans-serif;" (click)="selectAllStoreButton()">{{ isSlectedStore("All") ? "Tüm Seçimleri Kaldır" :
          "Tümünü Seç"
          }}</div>
      </ng-template>
      <ng-template ng-option-tmp [width]="150" let-item="item" let-item$="item$" let-index="index">
        <input class="custom-option" id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
        {{item.name}}
      </ng-template>

    </ng-select>
  </div>
  <div class="col-12 col-md-4  " *ngIf="isHaveServiceType">
    <nb-select fullWidth style="width: 100%" multiple formControlName="serviceTypes" placeholder="Servis Tipi"
      size="giant" class="my-2 w-100" shape="semi-round">
      <nb-select-label>
        {{
        mockService.getServiceTypes()
        | customSelectLabel: serviceTypes.value:"value":"text":"Servis Tipi"
        }}
      </nb-select-label>
      <nb-option [value]="null">Seçimi Temizle</nb-option>
      <nb-option class="AllOption m-0 p-0" [value]="'All'">
        <button class="w-100 multiple nb-transition AllButton text-left" (click)="selectAllServiceTypesButton()">
          {{
          isSlectedServiceTypes("All") ? "Tüm Seçimleri Kaldır" : "Tümünü Seç"
          }}
        </button>
      </nb-option>
      <nb-option [value]="item.value" *ngFor="let item of mockService.getServiceTypes()">{{ item.text }}
      </nb-option>
    </nb-select>
  </div>
  <ng-container *ngIf="!kzFilterYearComponent">
    <div class="col-12 col-md-4  ">
      <input fullWidth fieldSize="giant" formControlName="start" class="nb-input-shadow my-2 w-100" shape="semi-round"
        style="min-width: 60px !important" nbInput placeholder="Başlangıç Tarihi" [nbDatepicker]="startFormpicker" />
      <nb-date-timepicker format="dd-MM-yyyy HH:mm" fullWidth #startFormpicker ></nb-date-timepicker>
    </div>
    <div *ngIf="endDate" class="col-12 col-md-4">
      <input fullWidth fieldSize="giant" formControlName="end" class="nb-input-shadow my-2 w-100" shape="semi-round"
        style="min-width: 60px !important" nbInput placeholder="Bitiş Tarihi" [nbDatepicker]="endFormpicker" />
      <nb-date-timepicker #endFormpicker format="dd-MM-yyyy HH:mm" ></nb-date-timepicker>
    </div>
  </ng-container>
  <div class="col-12 col-md-4" *ngIf="kzFilterYearComponent">
    <nb-select fullWidth #yearSelect formControlName="start" placeholder="Yıl Seçiniz" size="giant"
      style="min-width: 120px" class="my-2 w-100" shape="semi-round">
      <nb-select-label>
        {{ "Ocak - Aralık " + start.value }}
      </nb-select-label>
      <nb-option [value]="null">Seçimi Temizle</nb-option>
      <nb-option [value]="item" *ngFor="let item of helperService.getYears({ firstYear: 2020 })">{{ item }}</nb-option>
    </nb-select>
  </div>
  <div class="col-12 col-md-4  ">
    <button size="giant" [disabled]="form.invalid" status="primary" class="my-2 w-100" shape="semi-round"
      (click)="onMainFilter()" nbButton>
      <nb-icon icon="clipboard-outline" class="mr-2"></nb-icon>
      Sonuçları Gör
    </button>
  </div>
</div>