import { Injectable } from '@angular/core';
import {
  MonthlySaleReport,
  MothCloseReport,
  ReportSearch,
  SaleDifferenceReport,
  SaltesDetailReport,
  SaltesSummaryReport,
  ZReportDetail,
  ZReportSummary,
} from '../models/report.model';
import { GlobalService } from './global.service';
import { MasterService } from './master.service';
import { UserService } from './user.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  filters: any = {};
  constructor(
    public ms: MasterService,
    public glb: GlobalService,
    public us: UserService
  ) {}

  getSaleDetailReport(
    reportSearch: ReportSearch
  ): Promise<SaltesDetailReport[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getSaleDetailReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: SaltesDetailReport[]) => {
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }

  getSaleSummaryReport(
    reportSearch: ReportSearch
  ): Promise<SaltesSummaryReport[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getSaleDailyReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: SaltesSummaryReport[]) => {
            o.forEach((el) => {
              el.businessDate = new Date(el.businessDate);
            });
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }

  getMonthClosingReport(
    reportSearch: ReportSearch
  ): Promise<MothCloseReport[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getSaleSummaryReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: MothCloseReport[]) => {
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
  getMonthlySaleReport(
    reportSearch: ReportSearch
  ): Promise<MonthlySaleReport[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getSaleMonthlyReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: MonthlySaleReport[]) => {
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
  getZReporDetailReport(reportSearch: ReportSearch): Promise<ZReportDetail[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getZReporDetailReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: ZReportDetail[]) => {
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
  getZReporSummaryReport(reportSearch: ReportSearch): Promise<ZReportSummary[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getZReporSummaryReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: ZReportSummary[]) => {
            // o.map(x=>{
            //   x.zReportDate = moment(x.zReportDate).add(-1,'hour').utc(false).toDate()
            // })
            console.log('summary',o)
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }

  getSaleDiffenceReport(reportSearch: ReportSearch): Promise<SaleDifferenceReport[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/report/getSaleDifferenceReport',
          reportSearch,
          this.us.token,
          'post'
        )
        .toPromise()
        .then(
          (o: SaleDifferenceReport[]) => {
            resolve(o);
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
}
