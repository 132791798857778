import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Building } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.scss']
})
export class BuildingFormComponent implements OnInit, OnChanges {
  building: Building = new Building()
  @Input() buildingId = "";
  @Input() myCallback: Function;
  constructor(public hs: HelperService, public storeService: StoreService, public glb: GlobalService) {
    this.getCities()
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.buildingId = changes.buildingId.currentValue;
   // console.log('buildingId', this.buildingId)
    if (this.buildingId != "") {
      this.getBuilding()
    }
    else {
      this.building = new Building()
    }
  }

  async getCities() {
    await this.hs.init()
  }

  validate() {
    var form = document.getElementsByClassName('building-form')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return
    }
    this.saveBuilding()
  }


  saveBuilding() {
    this.glb.loadingText = "Mağaza Kaydediliyor..."
    this.glb.loadingVisible = true;
 //   console.log(this.building)
    this.storeService.saveBuilding(this.building).then(x => {
      this.glb.loadingVisible = false;
      if (x.id != "") {
        this.glb.pushToast('Yapı Kaydedildi.', "success", 2000)
        this.myCallback()
      }
    })
  }



  getBuilding() {
    this.storeService.getBuildingById(this.buildingId).then(x => {
      this.building = x
    })
  }


  onCityChanged(city) {
    this.building.city = city
  }

  ngOnInit(): void {
  }

}
