<div class="input-group mb-3">
    <input type="text" class="form-control" readonly="true" #userinput  placeholder="API-KEY" aria-label="Recipient's username" [(ngModel)]="this.apiKey" [ngModelOptions]="{standalone: true}" aria-describedby="basic-addon2">
    <div class="input-group-append">
      <span class="input-group-text material-icons-outlined"  (click)="copyapiKey(userinput)" data-toggle="tooltip" title="Kopyala" id="basic-addon2" style="cursor: pointer;background-color: #7C70D2;color:#ffffff;">content_copy</span>
    </div>
  </div>


  <div class="dx-field d-flex justify-content-center">
    <button type="button" (click)="close()" class="btn btn-close">Kapat</button>
</div>

