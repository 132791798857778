import { EventEmitter } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'kerzz-button-group',
  templateUrl: './kerzz-button-group.component.html',
  styleUrls: ['./kerzz-button-group.component.scss'],
  providers:[
    {
      provide:NG_VALUE_ACCESSOR,
      multi:true,
      useExisting:KerzzButtonGroupComponent
    }
  ]
})
export class KerzzButtonGroupComponent implements OnInit, ControlValueAccessor {

  active: number = 0;

  onChange = (active) => { };

  onTouched = () => { };

  touched = false;

  disabled = false;

  constructor() { }
  writeValue(obj: any): void {
    this.active = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
  }
  select(val: number) {
    this.markAsTouched();
    if (!this.disabled) {
      this.active = val;
      this.onChange(this.active);
    }

  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

}
