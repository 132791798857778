import { Pipe, PipeTransform } from '@angular/core';
import { Building } from 'src/app/models/store-model';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {

  transform(value: Building, filter: string): boolean {
    console.log('arrayFilter:', filter);
    if (!filter || filter.trim() == '')
      return false;
    // return value.filter(el => el.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase()));
    return value.name.toLocaleLowerCase().includes(filter?.toLocaleLowerCase());
  }

}
