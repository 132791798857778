import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { Observable } from 'rxjs';
import { DarkModeService } from 'angular-dark-mode';
import { SidebarService } from 'src/app/services/sidebar.service';
import { Menu } from 'src/app/models/sidebar-menu-model';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  @Output('itemSelect') onSelect: EventEmitter<any> = new EventEmitter();
  darkMode$: Observable<boolean> = this.darkModeService.darkMode$;
  menu: Menu[] = [];
  constructor(
    private darkModeService: DarkModeService,
    private sidebar: SidebarService,
    private nav: Router,
    public userService: UserService
  ) {}

  onToggle(): void {
    this.darkModeService.toggle();
  }

  getSideBar(): void {
    this.menu = this.sidebar.getSideMenu();
  }

  navigateTo(item: Menu): void {
    this.onSelect.emit(item);

    this.closeAllMenus();
    this.setActive(item);
    this.nav.navigateByUrl('layout/' + item.tag);
  }
  setActive(item: Menu): void {
    item.active = true;
    if (!HelperService.isNullOrUndefined(item.children)) {
      if (item.children.length > 0) {
        item.children[0].active = true;
      }
    }
  }

  ngOnInit(): void {
    this.getSideBar();
  }
  closeAllMenus(): void {
    this.setPasive(this.menu);
  }
  setPasive(menu: Menu[]): void {
    for (const element of menu) {
      // const element: Menu = menu[index];
      element.active = false;
      if (!HelperService.isNullOrUndefined(element.children)) {
        this.setPasive(element.children);
      }
    }
  }

  isActive(item: Menu): boolean {
    // if (item.active) { return true; }
    // if (!HelperService.isNullOrUndefined(item.children)) {
    //   return item.children.some(el => el.active);
    // }
    // return false;
    const isHaveActiveChild = !HelperService.isNullOrUndefined(item.children)
      ? item.children.some((el) => this.isActive(el))
      : false;
    return item.active || isHaveActiveChild;
  }
}
