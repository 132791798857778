import { DatePipe } from '@angular/common';
import { ElementRef, Injectable, Optional, QueryList } from '@angular/core';
import { City, Town } from '../models/global-models';
import { GlobalService } from './global.service';
import { MasterService } from './master.service';
import { UserService } from './user.service';
import * as XLSX from 'xlsx';
import { Store } from '../models/store-model';
import jsPDF from 'jspdf';
import '../../assets/calibri-normal';

import autoTable, { CellDef, RowInput } from 'jspdf-autotable';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  activeCountry: string = '90';
  cities: City[] = [];
  towns: Town[] = [];
  dates: number[];

  constructor(
    @Optional() public ms: MasterService,
    @Optional() public glb: GlobalService,
    @Optional() public us: UserService,
    @Optional() private datePipe: DatePipe
  ) {}

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/api/helper/getCities',
          { countryCode: this.activeCountry },
          this.us.token,
          'post'
        )
        .subscribe((o) => {
          this.cities = Object.assign(o);
          resolve(this.cities);
        });
    });
  }

  getTowns(cityCode): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/api/helper/getTowns',
          { countryCode: this.activeCountry, cityCode: cityCode },
          this.us.token,
          'post'
        )
        .subscribe((o) => {
          this.towns = Object.assign(o);
          resolve(this.towns);
        });
    });
  }

  customSelectLabel(
    list: any[],
    selecteds: string[],
    key: string,
    valueKey: string,
    placeholder
  ): string[] {
    if (
      HelperService.isNullOrUndefined(list) ||
      HelperService.isNullOrUndefined(selecteds)
    ) {
      if (selecteds?.some((el) => el === 'All')) return [placeholder];
      return [];
    }
    const result = [...list]
      .map((el) => (selecteds.some((s) => s == el[key]) ? el[valueKey] : null))
      .filter((el) => el != null);
    if (selecteds.length === 1 && selecteds.some((el) => el === 'All'))
      return [placeholder];
    return result;
  }
  getYears(filter?: { firstYear?: number; lastYear?: number }): number[] {
    if (!HelperService.isNullOrUndefined(this.dates)) {
      return this.dates;
    }
    const first: number = filter?.firstYear ? filter.firstYear : 1970;
    const last: number = filter?.lastYear
      ? filter.lastYear
      : new Date().getFullYear() + 50;
    const diff: number = last - first + 1;
    const date: number[] = Array.from(Array(diff).keys());
    for (let index = 0; index < date.length; index++) {
      date[index] = first + index;
    }
    this.dates = date;
    return date;
  }

  makePdf(
    name: string,
    dataSource: any[],
    headers: PdfMakeModel[],
    debug?: boolean,
    summary?: CellDef[],
    isHorizontal?: boolean,
    fontSize?: number
  ): void {
    const pdf = new jsPDF(isHorizontal ? 'landscape' : 'portrait');

    const myHeaders: string[] = [];
    let print = [];

    // setum headers ['adı','soyadı']
    headers.forEach((el) => myHeaders.push(el.display));
    // setop rows any row is a CellDef auto generated by headers content is from datasource
    print = [...dataSource].map((el, index) => {
      const row: CellDef[] = [];
      headers.forEach((h) => {
        row.push({
          ...h.cell,
          styles: { fillColor: index % 2 === 1 ? '#f9f9f9' : null },
          content: this.getValue(el, h),
        });
      });
      return row;
    });
    if (!HelperService.isNullOrUndefined(summary)) {
      print.push(summary);
    }

    autoTable(pdf, {
      head: [myHeaders],
      body: print,
      headStyles: { fillColor: '#d8d8d8', textColor: 'black', lineWidth: 0.1 },
      footStyles: { fillColor: '#d8d8d8', textColor: 'black', lineWidth: 0.1 },
      styles: {
        font: 'calibri',
        fontSize: fontSize ?? 6,
      },
      theme: 'grid',
      didDrawPage: (data) => {
        pdf.setFont('calibri');
        pdf.addImage('assets/images/logo.png', 'PNG', 14, 4, 30, 10);
        // Get current font size
        const fontSize = pdf.getFontSize();

        // Get page width
        const pageWidth = pdf.internal.pageSize.width;

        // Get the actual text's width
        /* You multiply the unit width of your string by your font size and divide
         * by the pdf scale factor. The division is necessary
         * for the case where you use units other than 'pt' in the constructor
         * of jsPDF.
         */
        const txtWidth =
          (pdf.getStringUnitWidth(name) * fontSize) / pdf.internal.scaleFactor;

        // Calculate text's x coordinate
        const x = (pageWidth - txtWidth) / 2;
        pdf.text(name, x, 11);

        // Footer
        const str = 'Sayfa ' + data.pageNumber;
        // Total page number plugin only available in jspdf v1.0+
        // if (typeof pdf.putTotalPages === 'function') {
        //   str = str + ' of ' + data.pageCount;
        // }
        pdf.setFontSize(10);

        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        const pageSize = pdf.internal.pageSize;
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        pdf.text(str, data.settings.margin.left, pageHeight - 8);
      },
      didDrawCell: (data) => {
        // console.log(data.column);
        if (data.column.index === 1) {
          data.cell.contentWidth = 10;
        }
      },
    });
    if (debug) {
      pdf.output('dataurlnewwindow');
    } else {
      pdf.save(name + '.pdf');
    }
  }
  makePdfMonthlyCloseReport(params: {
    name: string;
    table: QueryList<ElementRef>;
  }): void {
    const pdf = new jsPDF('landscape');

    pdf.setFont('calibri');
    pdf.addImage('assets/images/logo.png', 'PNG', 8, 10, 30, 10);

    const fontSize = pdf.getFontSize();
    pdf.setTextColor('black');
    const pageWidth = pdf.internal.pageSize.width;
    const txtWidth =
      (pdf.getStringUnitWidth(params.name) * fontSize) /
      pdf.internal.scaleFactor;
    const x = (pageWidth - txtWidth) / 2;
    pdf.text(params.name, x, 17);
    const pageNamber = pdf.getNumberOfPages();
    params.table.forEach((element, index) => {
      const clone: any = (element.nativeElement as HTMLTableElement).cloneNode(
        true
      );
      clone.deleteRow(2);
      pdf.setPage(pageNamber);
      autoTable(pdf, {
        html: clone,
        headStyles: {
          fillColor: '#d8d8d8',
          textColor: 'black',
          lineWidth: 0.1,
          halign: 'center',
        },
        styles: {
          font: 'calibri',
          fontSize: 3,
          overflow: 'hidden',
        },
        startY: 20,
        tableWidth: 20,
        showHead: 'firstPage',
        margin: { left: 8 + 20 * index },
        didDrawCell: (cell) => {
          cell.cell.styles.halign = 'right';
        },
      });
    });
    pdf.save(params.name);
  }
  getValue(el: any, header: PdfMakeModel): string {
    if (!HelperService.isNullOrUndefined(header.lookup)) {
      return header.lookup(el[header.key]);
    }
    if (!HelperService.isNullOrUndefined(header.customValue)) {
      return header.customValue(el);
    }
    return el[header.key];
  }

  static isNullOrUndefined(item): boolean {
    return item == null || item == undefined;
  }
  static isEmptyString(item: string): boolean {
    if (HelperService.isNullOrUndefined(item)) return true;
    return (
      item === '' ||
      item.trim() === '' ||
      item.length === 0 ||
      item.trim().length === 0
    );
  }

  /**
   * Filter generic  list by filter
   * @param list filtered list
   * @param filter filter object
   */
  static Filter<T>(list: T[], filter: any): T[] {
    if (HelperService.isNullOrUndefined(list)) {
      return [];
    }
    return [...list].filter((el: T) => {
      // check is filter empty object
      // if true remove filter
      let isCurrect: boolean = Object.keys(filter).length === 0;

      // Check all filter is applieded
      isCurrect = Object.keys(filter).every((key) => {
        // check field is not null
        if (!HelperService.isNullOrUndefined(filter[key])) {
          // check el iclude filter by key
          if (this.isEqualString(el[key], filter[key])) {
            return true;
          }
          return false;
        }
        return true;
      });
      return isCurrect;
    });
  }

  static MainFilter(
    list: any[],
    filter: { storeName?: string[]; date?: any }
  ): any[] {
    // console.log('MainFilter filter:', filter);
    const storesFilter: any[] = list.filter((el) => {
      let isValid = false;
      const elDate: Date = this.removeTime(new Date(el.date));
      // Applay stores filter
      if (!HelperService.isNullOrUndefined(filter.storeName)) {
        isValid = filter.storeName.some((s) => s === el.id);
      }
      if (HelperService.isNullOrUndefined(filter.date)) {
        return isValid;
      }
      if (
        HelperService.isNullOrUndefined(filter.date.start) ||
        HelperService.isNullOrUndefined(filter.date.end)
      ) {
        // console.log('in date');
        isValid =
          isValid || list.some((el) => elDate.getFullYear() == filter?.date);
      } else {
        // console.log('in date range');

        const startDate: Date = this.removeTime(new Date(filter.date.start));
        const endDate: Date = this.removeTime(new Date(filter.date.end));
        isValid =
          isValid ||
          (startDate.getTime() <= elDate.getTime() &&
            elDate.getTime() <= endDate.getTime());
      }

      return isValid;
    });
    return storesFilter;
  }
  /**
   * Check first parameter include second parameters as string
   * @param str
   * @param other
   */
  static isEqualString(str: any, other: any): boolean {
    if (this.isNullOrUndefined(str) || this.isNullOrUndefined(other)) {
      return false;
    }
    // console.log(`str:${str} - other:${other}`);
    if (other instanceof Date) {
      // console.log(`${this.removeTime(new Date(str)).getTime() == this.removeTime(new Date(other)).getTime()}`);

      return (
        this.removeTime(new Date(str)).getTime() ==
        this.removeTime(new Date(other)).getTime()
      );
    }
    return str
      .toString()
      .toLocaleLowerCase('tr-TR')
      .includes(other.toString().toLocaleLowerCase('tr-TR'));
  }
  static removeTime(date: Date): Date {
    if (date == null || date == undefined) {
      return date;
    }
    const dateWithoutTime: Date = Object.assign(date);
    dateWithoutTime.setHours(0, 0, 0, 0);
    return date;
  }

  static exportHtml2Excel(params: {
    table: HTMLTableElement;
    filename: string;
  }): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(params.table);
    const variable = XLSX.utils.decode_range(ws['!ref']);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, params.filename);
    // console.log('Wb:', wb);
    /* save to file */
    XLSX.writeFile(wb, params.filename);
  }
  static exportexcel(element: HTMLElement, fileName, lastColName): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    this.delete_row(ws, 1);
    const variable = XLSX.utils.decode_range(ws['!ref']);
    // console.log('variable:', variable);
    ws['!ref'] = `A1:${lastColName}${variable.e.r + 1}`;
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    // console.log('Wb:', wb);
    /* save to file */
    XLSX.writeFile(wb, fileName);
  }
  static ec(r, c): string {
    return XLSX.utils.encode_cell({ r: r, c: c });
  }

  static delete_row(ws, row_index): void {
    const variable = XLSX.utils.decode_range(ws['!ref']);
    for (let R = row_index; R < variable.e.r; ++R) {
      for (let C = variable.s.c; C <= variable.e.c; ++C) {
        ws[this.ec(R, C)] = ws[this.ec(R + 1, C)];
      }
    }
    variable.e.r--;
    ws['!ref'] = XLSX.utils.encode_range(variable.s, variable.e);
  }

  static getStartOfWeek(d) {
    d = new Date(d);
    const day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
    return new Date(d.setDate(diff));
  }

  static getEndOfWeek(eDate: Date): Date {
    // console.log(`getDate :${eDate.getDate()} | getDay: ${eDate.getDay()}`);
    const last: number = this.getStartOfWeek(eDate).getDate() + 6; // last day is the first day + 6
    const lastday: Date = new Date(eDate.setDate(last));
    return lastday;
  }

  static getStarOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }
  static getEndOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  static getStartOfDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(),0,0,0);
  }

  static getEndOfDay(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate(),23,59,59);
  }
}
export interface PdfMakeModel {
  key: string;
  display: string;
  cell?: CellDef;
  lookup?: any;
  customValue?: any;
}
