<!DOCTYPE html>
<html lang="en">
  <head>
    <style>
      .input-style {
        background-color: transparent;
        color: #ffffff !important;
      }
    </style>

    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <title>Orwi Portal</title>
  </head>

  <body>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 col-xl-3 col-xs-12 col-sm-12 login-box">
          <div class="col-12" style="margin-bottom: 70px">
            <img [src]="logo" width="150" />
          </div>
          <div class="col-12" style="margin-bottom: 30px">
            <h3>Tekrar Hoş Geldiniz</h3>
          </div>
          <div class="col-12" style="margin-bottom: 30px">
            <form class="needs-validation login-form">
              <div class="form-group">
                <label for="mail">Mail Adresi</label>
                <input
                  type="email"
                  class="form-control"
                  id="mail"
                  [(ngModel)]="userMail"
                  aria-describedby="emailHelp"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Mail Adresinizi Giriniz..."
                  required
                />
                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
              </div>
              <div class="form-group">
                <label for="password">Şifre</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  [(ngModel)]="password"
                  [ngModelOptions]="{ standalone: true }"
                  placeholder="Şifre Giriniz..."
                  required
                />
              </div>
              <button type="submit" class="btn" (click)="validate()">
                Giriş Yap
              </button>
            </form>
          </div>
          <div class="col-12" style="text-align: center">
            <span (click)="resetPassword()"> Parolamı Unuttum </span>
          </div>
        </div>

        <div
          class="
            col-lg-7 col-xl-7
            d-none d-sm-block d-sm-none d-md-block
            login-side
          "
        >
          <h1>Online mağazanı yönet</h1>
          <h5>Hemen katıl ve içeriklerini düzenle</h5>
          <img src="../../../assets/images/login-bg.png" width="100%" />
        </div>
      </div>
    </div>
  </body>
</html>

<dx-popup
  [height]="300"
  [deferRendering]="false"
  [width]="this.glb.setPopupWidth()"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="forgetPasswordPopupVisible"
  title="Şifremi Unuttum"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <app-forget-password
        [myCallback]="theBoundCallback"
      ></app-forget-password>
    </dx-scroll-view>
  </div>
</dx-popup>

<dx-popup
  [height]="300"
  [deferRendering]="false"
  [width]="this.glb.setPopupWidth()"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [closeOnOutsideClick]="false"
  [(visible)]="resetPwdPopupVisible"
  title="Yeni Şifre Belirle"
>
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <app-reser-pwd [myCallback]="theBoundCallback"></app-reser-pwd>
    </dx-scroll-view>
  </div>
</dx-popup>
