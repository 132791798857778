import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ReportSearch } from 'src/app/models/report.model';
import { GlobalService } from 'src/app/services/global.service';
import { ReportService } from 'src/app/services/report.service';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { DxDataGridComponent } from 'devextreme-angular';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { CellDef } from 'jspdf-autotable';

@Component({
  selector: 'sale-difference-report',
  templateUrl: './sale-difference-report.component.html',
  styleUrls: ['./sale-difference-report.component.scss'],
  providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class SaleDifferenceReportComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  constructor(
    private globalService: GlobalService,
    private reportService: ReportService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private helperService: HelperService
  ) {}
  showInfo = true;
  dataSource: any[] = [];
  readonly allowedPageSizes = [10, 15, 'all'];
  showPageSizeSelector = true;

  showNavButtons = true;
  ngOnInit(): void {}

  clear() {
    this.dataSource = [];
  }

  onSearched(e: ReportSearch) {
    this.globalService.loadingText = 'Lütfen bekleyin.';
    this.globalService.loadingVisible = true;
    let params: any = {
      businessDate: e.startDate,
      storeIdList: e.storeIdList,
    };

    this.reportService.getSaleDiffenceReport(params).then((res) => {
      console.log('deneme', res);
      this.dataSource = res;

      setTimeout(() => {
        this.globalService.loadingVisible = false;
      }, 200);
    });
  }

  toplam(key: string, key2?: string): number {
    if (HelperService.isNullOrUndefined(this.dataSource)) {
      return 0;
    }
    return this.dataSource.reduce((accumulator, currentValue) => {
      if (!HelperService.isNullOrUndefined(key2)) {
        return (
          accumulator + Number(currentValue[key]) - Number(currentValue[key2])
        );
      }
      return accumulator + Number(currentValue[key]);
    }, 0);
  }
  divide(key: string, key2: string): number {
    return this.toplam(key) / this.toplam(key2);
  }

  exportExcel(e?: HTMLElement) {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Satış Fark Raporu');
    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Satış Detay Raporu.xlsx'
        );
      });
    });
  }

  exportPDF(): void {
    // console.log(this.dataGrid);
    const doc = new jsPDF();
    doc.setFont('calibri', 'normal');
    const statusLookUp: any[] = [];
    const headers: PdfMakeModel[] = [
      {
        key: 'buildingName',
        display: 'Yapı Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'storeName',
        display: 'Mağaza Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'businessDate',
        display: 'Tarih',
        customValue: (el) =>
          this.datePipe.transform(el.businessDate, 'dd/MM/yyyy'),
      },
      {
        key: 'zReportTotal',
        display: 'Z Toplamı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.zReportTotal, '₺'),
      },
      {
        key: 'zReportReceiptCount',
        display: 'Z Fiş Adedi',
        cell: { styles: { cellWidth: 20 } },
        customValue: (el) =>
          this.decimalPipe.transform(el.zReportReceiptCount ?? 0, '1.0-2'),
      },
      {
        key: 'saleDocumentTotal',
        display: 'Fiş Toplamı',
        cell: { styles: { halign: 'right' } },
        // customValue: (el) => el.vatRate + '%',
        customValue: (el) =>
          this.currencyPipe.transform(el.saleDocumentTotal, '₺'),
      },
      {
        key: 'saleDocumentCount',
        display: 'Satış Adedi',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.decimalPipe.transform(el.saleDocumentCount ?? 0, '1.0-2'),
      },
      {
        key: 'differenceTotal',
        display: 'Toplam Fark',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.differenceTotal, '₺'),
      },
      {
        key: 'differenceCount',
        display: 'Fark Adedi',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.decimalPipe.transform(el.differenceCount, '1.0-2'),
      },
    ];
    const summary: CellDef[] = [
      {
        content: 'Toplamlar',
        colSpan: 3,
        styles: { fontStyle: 'bold' },
      },

      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('zReportTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('zReportReceiptCount'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(
          this.toplam('saleDocumentTotal'),
          '₺'
        ),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('saleDocumentCount'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(
          this.toplam('differenceTotal'),
          '₺'
        ),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('differenceCount'),
      },
    ];
    this.helperService.makePdf(
      'Satış Fark Raporu',
      this.dataSource,
      headers,
      false,
      summary
    );
  }
}
