<kerzz-report-filter (onSearched)="onSearched($event)">
    <kz-filter-year></kz-filter-year>
</kerzz-report-filter>

<div class="d-flex my-2 ">
    <button (click)="exportExcel()" class="k-button" nbButton>
        <span class="mr-2">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
        </span>
        Excel İndir
    </button>
    <button [disabled]="!pivotGridDataSource || pivotGridDataSource?.length === 0" (click)="clear()"
        class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px">
            <nb-icon icon="trash-2-outline"></nb-icon>
        </span>
        Filtreyi Temizle
    </button>
</div>
<div class="content" style="overflow: scroll; max-height: 600px">
    <div class="d-flex p-4" style="min-inline-size: max-content">
        <dx-pivot-grid id="pivotgrid" [allowSortingBySummary]="true" [allowFiltering]="true" [showBorders]="true"
            [allowSortingBySummary]="true" [showColumnGrandTotals]="true" [showRowGrandTotals]="true"
            [showRowTotals]="true" [showColumnTotals]="true" [dataSource]="pivotGridDataSource">
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="monthly-sales-state"></dxo-state-storing>

            <dxo-field-chooser [enabled]="true" [height]="600"></dxo-field-chooser>
            <dxo-scrolling mode="virtual"></dxo-scrolling>
        </dx-pivot-grid>

        <!-- Pivot table -->
        <!-- <div class="table-style mx-1" style="width: 400px; overflow-x: auto;">

            <table #table class="table mb-0" style="width: 400px; min-width: 400px !important;">
                <thead style="max-height: 50px;">
                    <tr class="text-center th-header">
                        <td colspan="2">Mağazalar </td>
                    </tr>
                    <tr>
                        <th> Yapı Adı </th>
                        <th> Mağaza Adı </th>
                    </tr>

                    <tr>


                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>
                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>


                    </tr>
                </thead>

                <tbody style="overflow: visible !important;height: unset !important;max-height: unset !important;">
                    <tr *ngFor="let item of dataSource.pivots">
                        <td>{{item.buildName}}</td>
                        <td>{{item.storName}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">Toplamlar</td>
                    </tr>
                </tbody>
            </table>

        </div> -->
        <!-- Pivot table end-->
        <!-- 
        <div class="table-style mx-1" style="width: 500px; overflow-x: auto;"
            *ngFor="let month of dataSource?.months;let rIndex=index">

            <table #table class="table mb-0" style="width: 500px; min-width: 400px !important;">
                <thead style="max-height: 50px;">
                    <tr class="text-center th-header">
                        <td colspan="3"> {{month.name}} </td>
                    </tr>
                    <tr>
                        <th> Toplam Ciro </th>
                        <th> T.Adisyon Sayısı </th>
                        <th> T.Kişi Sayısı </th>
                    </tr>

                    <tr>



                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>

                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>
                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>

                    </tr>
                </thead>

                <tbody style="overflow: visible !important;height: unset !important;max-height: unset !important;">
                    <tr *ngFor="let report of month.reports">
                        <td class="text-right">{{report.totalQuantity | currency}}</td>
                        <td class="text-right">{{report.totalAdisyon }}</td>
                        <td class="text-right">{{report.totalPersonCount }}</td>
                    </tr>
                    <tr class="orwi-tabel-dark font-weight-bold">

                        <td class="text-right">{{toplam(rIndex,'totalQuantity') | currency}}</td>
                        <td class="text-right">{{toplam(rIndex,'totalAdisyon')}}</td>
                        <td class="text-right">{{toplam(rIndex,'totalPersonCount')}}</td>
                    </tr>
                </tbody>
            </table>

        </div>



        <div class="table-style mx-1" style="width: 500px;overflow-x: auto;">

            <table #table class="table mb-0" style="width: 500px; min-width: 400px !important;">
                <thead style="max-height: 50px;">
                    <tr class="text-center th-header orwi-table-general-sum">
                        <td colspan="3"> 2021 Genel Toplam </td>
                    </tr>
                    <tr>
                        <th> Toplam Ciro </th>
                        <th> T.Adisyon Sayısı </th>
                        <th> T.Kişi Sayısı </th>
                    </tr>

                    <tr>



                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>

                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>
                        <th scope="col">
                            <nb-form-field>
                                <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                                <input fieldSize="small" type="text" nbInput>
                            </nb-form-field>
                        </th>

                    </tr>
                </thead>

                <tbody style="overflow: visible !important;height: unset !important;max-height: unset !important;">
                    <tr *ngFor="let report of dataSource.pivots;let pIndex=index">
                        <td class="text-right">{{horizontalSum(pIndex,'totalQuantity') | currency}}</td>
                        <td class="text-right">{{horizontalSum(pIndex,'totalAdisyon') }}</td>
                        <td class="text-right">{{horizontalSum(pIndex,'totalPersonCount') }}</td>
                    </tr>
                    <tr class="orwi-tabel-dark font-weight-bold">

                        <td class="text-right">{{crossSum('totalQuantity') | currency}}</td>
                        <td class="text-right">{{crossSum('totalAdisyon') }}</td>
                        <td class="text-right">{{crossSum('totalPersonCount') }}</td>
                    </tr>
                </tbody>
            </table>

        </div> -->
    </div>
</div>

<!-- Table End-->