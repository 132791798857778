<hr />
<dx-scroll-view width="100%" height="100%">
  <form class="needs-validation user-form mb-4">
    <div class="form-row">
      <div class="form-group col-12 col-md-6">
        <label for="userName">Ad*</label>
        <input
          tabindex="1"
          type="text"
          class="form-control"
          id="userName"
          [(ngModel)]="user.name"
          [ngModelOptions]="{ standalone: true }"
          required
          placeholder="Ad"
        />
        <!-- <div class="invalid-feedback">Check this checkbox to continue.</div> -->
      </div>
      <div class="form-group col-12 col-md-6">
        <label for="gsm">GSM*</label>
        <input
          tabindex="2"
          type="text"
          class="form-control text-right"
          id="gsm"
          [(ngModel)]="user.gsm"
          [ngModelOptions]="{ standalone: true }"
          mask="00(000) 000 00 00"
          pattern=".{17,}"
          required
          placeholder="90 (5xx) xxx xx xx"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-12 col-md-6">
        <label for="rights">Durum*</label>
        <select
          tabindex="5"
          class="form-control"
          #myStatusSelect
          id="status"
          [(ngModel)]="user.status"
          (change)="onStatusChanged(myStatusSelect.value)"
          [ngModelOptions]="{ standalone: true }"
          required
        >
          <option *ngFor="let item of statuses" [value]="item.value">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="form-group col-12 col-md-6">
        <label for="rights">Yetki*</label>
        <select
          *ngIf="userId !== us.user.id"
          tabindex="4"
          class="form-control"
          #mySelect
          id="userRight"
          [(ngModel)]="user.userRight"
          (change)="onChange(mySelect.value)"
          [ngModelOptions]="{ standalone: true }"
          required
          [disabled]="userId === us.user.id"
        >
          <option *ngFor="let item of rights" [value]="item.value">
            {{ item.name }}
          </option>
        </select>

        <select
          *ngIf="userId === us.user.id"
          tabindex="4"
          class="form-control"
          #mySelect
          id="userRight"
          [ngModel]="user.userRight"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="userId === us.user.id"
        >
          <option *ngFor="let item of rights" [value]="item.value">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col">
        <label for="mailaddress">E-Posta*</label>
        <input
          tabindex="3"
          type="email"
          class="form-control"
          id="mailaddress"
          [disabled]="user.id != ''"
          [(ngModel)]="user.mail"
          [ngModelOptions]="{ standalone: true }"
          required
          placeholder="E-Posta"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <button type="submit" (click)="validate()" class="btn btn-submit">
        Kaydet
      </button>
    </div>
  </form>
</dx-scroll-view>
