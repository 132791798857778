import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { User, UserLog } from 'src/app/models/user-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { LogService } from 'src/app/services/log.service';
import { MockService } from 'src/app/services/mock.service';

@Component({
  selector: 'kerzz-log',
  templateUrl: './kerzz-log.component.html',
  styleUrls: ['./kerzz-log.component.scss'],
})
export class KerzzLogComponent implements OnInit, OnInit, OnDestroy {
  @Input('dataSource') user: User;
  logs: UserLog[] = [];

  status = {
    isLoading: true,
  };

  dataSource: UserLog[] = [];
  allDataSource: UserLog[] = [];
  pageRowCount = 13;

  filterForm: FormGroup;
  pageFormControl: FormControl = new FormControl({ value: 1, disabled: true });
  filterFormSubscription: Subscription;
  fieldNames: any;
  constructor(
    private logService: LogService,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private datePipe: DatePipe,
    private mockService: MockService,
    public helperService: HelperService
  ) {
    this.fieldNames = this.getFieldNames();
  }

  getLogs(): void {
    this.globalService.loadingText = 'Lütfen Bekleyin.';
    this.globalService.loadingVisible = true;
    this.logService.getUserLog(this.user.id).subscribe(
      (res: UserLog[]) => {
        this.globalService.loadingVisible = false;

        this.logs = res;
        this.allDataSource = res;
        this.dataSource = res
          .filter((el, index) => index <= this.pageRowCount)
          .sort((a, b) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return (
              new Date(b.createDate).getTime() -
              new Date(a.createDate).getTime()
            );
          });
        this.status.isLoading = false;
      },
      () => {
        this.globalService.loadingVisible = false;
      }
    );
  }
  ngOnDestroy(): void {
    if (!HelperService.isNullOrUndefined(this.filterFormSubscription)) {
      this.filterFormSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.getLogs();
    const formControls: any = {
      createDate: [],
      userId: [],
      logType: [],
      ipAddress: [],
      oldValue: [],
      value: [],
    };
    this.filterForm = this.createFilterForm(formControls);
    this.dataSource = this.logs;
  }

  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe((filters) => {
    //  console.log('Filters:', filters);
      this.dataSource = [...HelperService.Filter<UserLog>(this.logs, filters)];
    //  console.log('dataSource:', this.dataSource);
    });
    return form;
  }

  format(item: UserLog, isOld?: boolean): { display: string; value: string }[] {
    let result: { display: string; value: string }[] = [];
    const value = isOld ? item.oldValue : item.value;
    if (HelperService.isNullOrUndefined(value) || value == '') {
      return result;
    }
    result = this.parseFields(item, isOld);
    return result;
  }
  parseFields(
    item: UserLog,
    isOld?: boolean
  ): { display: string; value: string }[] {
    let result: { display: string; value: string }[] = [];
    const value = isOld ? item.oldValue : item.value;
    // console.log('item:', item);
    /** user-password-reset */
    if (
      item.logType == 'user-password-reset' ||
      item.logType == 'user-login' ||
      item.logType == 'user-password-change'
    ) {
      result.push({
        display: 'Kullanıcı Id',
        value: value,
      });
    } else if (item.logType == 'user-update') {
      /** user-update */
      const data: User = JSON.parse(value);
      Object.keys(data).forEach((key) => {
        result.push({
          display: this.fieldNames[key] ?? key,
          value: this.getUserValues(data[key], key),
        });
      });
    } else if (
      /** "building-update-delete" */
      item.logType == 'building-update' ||
      item.logType == 'building-delete' ||
      item.logType == 'building-create'
    ) {
    //  console.log('İtem value:', value);
      const fieldNames: any = {
        name: 'Yapı Adı',
        commercialName: 'Ünvan',
      };

      const data: any = JSON.parse(value);
      Object.keys(data).forEach((key) => {
        result.push({
          display: this.fieldNames[key] ?? fieldNames[key] ?? key,
          value: data[key],
        });
      });
    } else if (
      /** "store-update-delete" */
      item.logType == 'store-update' ||
      item.logType == 'store-delete' ||
      item.logType == 'store-create'
    ) {
     // console.log('İtem value:', value);
      const fieldNames: any = {
        name: 'Mağaza Adı',
      };

      const data: any = JSON.parse(value);
      Object.keys(data).forEach((key) => {
        if (HelperService.isNullOrUndefined(data[key]) || data[key] === '') {
          return;
        }
        result.push({
          display: this.fieldNames[key] ?? fieldNames[key] ?? key,
          value: data[key],
        });
      });
    }
  //  console.log('result:', result);
    return result;
  }

  getUserValues(value: any, key: string): string {
    if (key === 'updateDate' || key === 'dateOfBirth' || key === 'createDate') {
      return this.datePipe.transform(value, 'dd/MM/yyyy');
    }
    if (key === 'status') {
      const status: { name: string; value: string }[] = [
        {
          name: 'Yeni',
          value: 'new',
        },
        {
          name: 'Pasif',
          value: 'banned',
        },
        {
          name: 'Silinmiş',
          value: 'deleted',
        },
        {
          name: 'Aktif',
          value: 'registered',
        },
      ];
      return status.find((el) => el.value === value)?.name ?? value;
    }
    if (key === 'userRight') {
      return (
        this.mockService.getUserRightMocks().find((el) => el.value === value)
          ?.name ?? value
      );
    }
    if (key === 'gender') {
      return value === 'male' ? 'Erkek' : 'Kadın';
    }
    return value;
  }
  setPage(isNext: boolean): void {
    let page: number = this.pageFormControl.value;
    const pageRows: UserLog[] = [];
    if (isNext) {
      // Next
      page++;
      const lastIndex = page * this.pageRowCount;
      for (let index = this.pageRowCount; index > 0; index--) {
        const element = this.allDataSource[lastIndex - index];
        if (!HelperService.isNullOrUndefined(element)) {
          pageRows.push(element);
        }
      }
      this.pageFormControl.patchValue(page);
      this.dataSource = pageRows;
    } else {
      // Prev
      page--;
      const lastIndex = page * this.pageRowCount;
      for (let index = this.pageRowCount; index > 0; index--) {
        const element = this.allDataSource[lastIndex - index];
        if (!HelperService.isNullOrUndefined(element)) {
          pageRows.push(element);
        }
      }
      this.pageFormControl.patchValue(page);
      this.dataSource = pageRows;
    }
  }
  exportExcell(e): void {
    HelperService.exportexcel(e, 'Kullanıcı Logları.xlsx', 'F');
  }
  exportPDF(): void {
    const statusLookUp: any[] = [];
    const headers: PdfMakeModel[] = [
      {
        key: 'createDate',
        display: 'Taih',
        customValue: (el) =>
          this.datePipe.transform(el.createDate, 'dd/MM/yyyy'),
      },
      {
        key: 'userId',
        display: 'Kullanıcı Adı',
      },
      {
        key: 'logType',
        display: 'Log Tipi',
      },
      {
        key: 'ipAddress',
        display: 'İp Adresleri',
      },
      {
        key: 'vatTotal',
        display: 'Eski Değer',
        customValue: (el) => {
          const value = this.format(el, true)
            .map((m) => `${m.display}: ${m.value}`)
            .join(' , ');
          if (value === ',') {
            return '';
          }
          return value;
        },
      },
      {
        key: 'vatTotal',
        display: 'Yeni Değer',
        customValue: (el) => {
          const value = this.format(el, true)
            .map((m) => `${m.display}: ${m.value}`)
            .join(' , ');
          if (value === ',') {
            return '';
          }
          return value;
        },
      },
    ];

    this.helperService.makePdf(
      'Kullanıcı Logları',
      this.dataSource,
      headers,
      false
    );
  }

  getFieldNames(): any {
    return {
      city: 'İl',
      status: 'Durum',
      userRight: 'Yetki',
      gsm: 'GSM',
      dateOfBirth: 'Doğum Günü',
      gender: 'Cinsiyet',
      updateDate: 'Güncelleme Tarihi',
      mail: 'E Posta',
      createDate: 'Ekleme Tarihi',
      commercialName: 'Ünvan',
      description: 'Açıklama',
      apikey: 'API-KEY',
      taxOffice: 'Vergi Dairesi',
      taxNo: 'Vergi No',
      address: 'Adres',
    };
  }
}
