<hr>
<form class="needs-validation building-form">
    <div class="form-row">
    <div class="col-12">
        <div class="form-group">
            <label for="name">Yapı Adı*</label>
            <input type="text" class="form-control" id="name" [(ngModel)]="building.name" [ngModelOptions]="{standalone: true}" required placeholder="Yapı Adı">
            <!-- <div class="invalid-feedback">Check this checkbox to continue.</div> -->
        </div>
        <div class="form-group">
            <label for="city">İl*</label>
            <select class="form-control" #cities id="city" [(ngModel)]="building.city"  (change)='onCityChanged(cities.value)'  [ngModelOptions]="{standalone: true}" data-show-subtext="true" data-live-search="true" required>
              <option *ngFor="let item of this.hs.cities"  [value]="item.name">{{item.name}}</option>
            </select>
          </div>
          <div class="dx-field d-flex justify-content-center" >
              <button type="submit" (click)="validate()" class="btn btn-submit">Kaydet</button>
          </div>
    </div>
  </div>
</form>