import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Store } from '../models/store-model';
import { HelperService } from '../services/helper.service';
import { confirm } from 'devextreme/ui/dialog';
import { StoreService } from '../services/store.service';
import { GlobalService } from '../services/global.service';
import moment from 'moment';

@Component({
  selector: 'delete-transactions',
  templateUrl: './delete-transactions.component.html',
  styleUrls: ['./delete-transactions.component.scss']
})
export class DeleteTransactionsComponent implements OnInit {
  @Input() storeId = "";
  startDate : Date= new Date()
  endDate : Date= new Date()
  @Input() myCallback: Function;
  store: Store = new Store()
  constructor(private storeService : StoreService,private hs  : HelperService,private glb : GlobalService ) { }

  ngOnInit(): void {
  }


  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.storeId.currentValue;
    this.storeId = changes.storeId.currentValue;
    if (this.storeId != "") {
      this.getStore()
    }   
  }

  getStore() {
    this.storeService.getStoreById(this.storeId).then(x => {
      this.store = x
      this.hs.getTowns(this.hs.cities.find(x => x.name == this.store.city).code).then(o=> {
        
      })
    })
  }

  deleteTransactions()
  {
  let sDate : any= moment(this.startDate).format('DD.MM.YYYY')
  let eDate : any= moment(this.endDate).format('DD.MM.YYYY')
    const result = confirm(
      sDate+ ' - ' + eDate + ' Arası Kayıtları Silinecektir.Onaylıyor musunuz?' ,'Kayıtları Sil'
    );

    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.glb.loadingText = 'Siliniyor...';
        this.glb.loadingVisible = true;
        setTimeout(() => {
          this.glb.loadingVisible = false;
          this.myCallback()
          this.storeService.deleteStoreTransactions(this.storeId,this.startDate,this.endDate).then(x=>{
            console.log("deleteTransactions",x)
            if (x) {
              this.glb.pushToast("Satış Kayıtları Silindi.","success",3000)
            }
            else
            {
              this.glb.pushToast("Bir Sorun Oluştu.","error",3000)
            }
          })
        }, 2000);
        // this.storeService.deleteStore(storeId).then(
        //   (o) => {
        //     if (o) {
        //       this.glb.loadingVisible = false;
        //       this.glb.pushToast('Mağaza Silindi', 'success', 2000);
        //       this.getStores();
        //     }
        //   },
        //   (e: HttpErrorResponse) => {
        //     this.glb.loadingVisible = false;

        //   //  console.log('Hata:', e);
        //     const error: DefaultErrorResponse = e.error;
        //     if (error.message === 'store_in_use') {
        //       this.glb.pushToast(
        //         'Bu mağaza silinemez.Üzerinde satış verisi bulunmaktadır.',
        //         'error',
        //         3000
        //       );
        //     }
        //   }
        // );
      }
    });

  }

}
