import { Injectable } from '@angular/core';
import { timeout } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MasterService {
  private readonly baseUrl = environment.baseUrl
  private readonly apiKey = environment.apiKey
  token = ""
  constructor(private http: HttpClient) { }

  serviceRequest(path: string, postData: any, _token = "", type: "post" | "put" | "get" | "delete") {
    switch (type) {
      case "post":
        return this.postRequest(path, postData, _token);
        break;
      case "delete":
        return this.deleteRequest(path, postData, _token);
        break;
        case "put" :
          return this.putRequest(path, postData, _token);
          break;
      default:
        break;
    }
  }

  postRequest(path: string, data: any, _token = "") {
    this.token = _token
    let url = `${this.baseUrl}${path}`
    return this.http.post(url,
      data, this.getHeaders()).pipe(timeout(30000))
  }

  putRequest(path: string, data: any, _token = "") {
    this.token = _token
    let url = `${this.baseUrl}${path}`
    return this.http.put(url,
      data, this.getHeaders()).pipe(timeout(30000))
  }

 deleteRequest(path: string, data: any, _token = "") {
    this.token = _token
    let url = `${this.baseUrl}${path}` 
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json', 'Accept': 'application/json', 'x-api-key': this.apiKey, 'x-user-token': this.token
      }),
      body: data
    };
    return this.http.delete(url,options).pipe(timeout(30000))
  }

  serviceRequestPromise(path, postData, _token = "") {
    return new Promise((resolve, reject) => {

      if (!navigator.onLine) {
        // this.glb.pushToast("No Internet Connection", "error", 3000)
        reject("offline")
        return
      }

      this.token = _token
      let url = `${this.baseUrl}${path}`
      this.http.post(url, postData, this.getHeaders()).subscribe(o => {
        resolve(o)
      }, e => {


        if (e.status == 0) {
          // this.glb.pushToast("Hay Aksi! Bir Sorun var, Bir süre sonra tekrar deneyin", "error", 3000)
        } else if (e.status == 404 || e.status == 401) {
          reject(e.error.message)
        } else {
       //   console.log("MasterService",e)
          // this.glb.pushToast(e.error.message, "error", 3000)
        }
      })

    })

  }


  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'x-api-key': this.apiKey, 'x-user-token': this.token })
    }
    return httpOptions
  }
}
