import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reser-pwd',
  templateUrl: './reser-pwd.component.html',
  styleUrls: ['./reser-pwd.component.scss']
})
export class ReserPwdComponent implements OnInit {
  password: string = ""
  @Input() myCallback: Function;
  confirmPassword: string = ""
  constructor(public glb: GlobalService, private route: ActivatedRoute, public us: UserService, private nav: Router) {
   // console.log(route.snapshot.paramMap.get('token'));
    const token: string = route.snapshot.paramMap.get('token');
    if (!HelperService.isNullOrUndefined(token)) {
      localStorage.setItem('resetToken', token);
    }
  }

  validate() {
    // var form = document.getElementsByClassName('needs-validation')[0] as HTMLFormElement;
    // if (form.checkValidity() === false) {
    //   event.preventDefault();
    //   event.stopPropagation();
    // }
    // form.classList.add('was-validated');
    // if (form.checkValidity() === false) {
    //   return
    // }
    if (this.password == "" || this.confirmPassword == "") {
      this.glb.pushToast('Lütfen şifre alanlarını doldurunuz!', "warning", 2000)
      return
    }
    if (this.password != this.confirmPassword) {
      this.glb.pushToast('Lütfen girmiş olduğunuz değeleri tekrar kontrol ediniz...', "warning", 2000)
      return
    }

    this.resetPassword()
  }

  resetPassword() {
    this.glb.loadingText = "Şifreniz değiştirliyor.Giriş sayfasına yönlendiriliyorsunuz..."
    this.glb.loadingVisible = true

    this.us.resetPassword(localStorage.getItem('resetToken'), this.confirmPassword).then(x => {
      localStorage.removeItem('resetToken')
      localStorage.removeItem('refreshToken')
     // console.log(x)
      this.glb.loadingVisible = false;
      if (x.message == "success") {
        this.nav.navigateByUrl('login')
      }

    })


  }

  ngOnInit(): void {

  }

}
