import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResolveEnd, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, observeOn } from 'rxjs/operators';
import { LinkedStore, Store } from '../models/store-model';
import { User } from '../models/user-model';
import { GlobalService } from './global.service';
import { HelperService } from './helper.service';
import { MasterService } from './master.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User = new User();
  refreshToken: string = '';
  token: string = '';
  logged: boolean = false;
  userList: User[] = [];
  constructor(
    public ms: MasterService,
    public glb: GlobalService,
    private nav: Router
  ) {}

  login(_mail, _password): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/user/loginUserByMail',
          { mail: _mail, password: _password },
          '',
          'post'
        )
        .toPromise()
        .then(
          (o) => {
         //   console.log('userLogin', Object.assign(o));
            let result = Object.assign(o);
            if (result.user) this.refreshToken = result.refreshToken;
            this.token = result.token;
            this.user = result.user;
            this.logged = true;
            localStorage.setItem('refreshToken', this.refreshToken);
            resolve(true);
          },
          (e) => {
            this.glb.loadingVisible = false;
            let message = e.error?.message;
          //  console.log('userLogin', e);
            if (message == 'user_not_found') {
              this.glb.pushToast(
                'Sisteme Kayıtlı Kullanıcı Bulunamadı!',
                'warning',
                2000
              );
            } else {
              this.glb.pushToast(message, 'error', 2000);
            }
            reject(false);
          }
        );
    });
  }

  autoLogin(refreshToken): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/user/loginUserByRefreshToken',
          { token: refreshToken },
          '',
          'post'
        )
        .toPromise()
        .then((o) => {
        //  console.log('autoLogin', Object.assign(o));
          const result = Object.assign(o);
          if (result.user) {
            this.refreshToken = result.refreshToken;
          }
          this.token = result.token;
          this.user = result.user;
          //   this.user.userRight = 'power-user';

          this.logged = true;
          localStorage.setItem('refreshToken', this.refreshToken);
          resolve(true);
        })
        .catch((e) => {
        //  console.log('autoLogin', e);
          this.glb.pushToast(
            'Giriş başarısız. Lütfen tekrar giriş yapınız.',
            'warning',
            2000
          );
          localStorage.removeItem('refreshToken');
          reject(false);
        });
    });
  }

  saveUser(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest('/user/saveUser', user, this.token, 'put')
        .toPromise()
        .then(
          (o) => {
          //  console.log('saveUser', Object.assign(o));
            resolve(Object.assign(o));
          },
          (e) => {
            this.glb.loadingVisible = false;
            let message = e.error.message;
          //  console.log('SaveUser', e);
            if (message == 'user_mail_already_used') {
              this.glb.pushToast('Kullanıcı Sisteme Kayıtlı!', 'warning', 2000);
            } else {
              this.glb.pushToast(message, 'error', 2000);
            }
            reject(false);
          }
        );
    });
  }

  resetPassword(_resetToken, _confirmPassword): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/user/changePassword',
          { resetToken: _resetToken, password: _confirmPassword },
          '',
          'put'
        )
        .toPromise()
        .then(
          (o) => {
           // console.log('resetPassword', Object.assign(o));
            resolve(Object.assign(o));
          },
          (e) => {
           // console.log('resetPassword', e);
            this.glb.loadingVisible = false;
            this.glb.pushToast(e.error.message, 'error', 2000);
            reject(false);
          }
        );
    });
  }

  resetPasswordMail(_mailAddress): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/user/resetPasswordMail',
          { mail: _mailAddress },
          '',
          'post'
        )
        .toPromise()
        .then(
          (o) => {
          //  console.log('resetPasswordMail', Object.assign(o));
            resolve(Object.assign(o));
          },
          (e) => {
            this.glb.loadingVisible = false;
            let message = e.error.message;
            if (message == 'user_not_found') {
              this.glb.pushToast(
                'Sistemde bu mail adresine kullanıcı bulunamadı!',
                'warning',
                2000
              );
            } else {
              this.glb.pushToast(message, 'error', 2000);
            }
            reject(false);
          }
        );
    });
  }

  getUsers(): Promise<User[]> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest('/user/getUsers', {}, this.token, 'post')
        .toPromise()
        .then(
          (o) => {
           // console.log('getUsers', Object.assign(o));
            this.userList = Object.assign(o);
            resolve(this.userList);
          },
          (e) => {
            this.glb.loadingVisible = false;
          //  console.log('getUsers', e);
            reject(false);
          }
        );
    });
  }

  logOut(): void {
    this.glb.loadingText = 'Çıkış Yapııyor...';
    this.glb.loadingVisible = true;
    setTimeout(() => {
      this.glb.loadingVisible = false;
      localStorage.removeItem('refreshToken');
      this.user = new User();
      this.nav.navigateByUrl('login');
    }, 2000);
  }
  /**
   *  Kullanıcının yetkili olduğu mağaza id’lerini verir.
   * @param id Etkilenen kullanıcının id'si
   */
  getLinkedStores(id?: string): Promise<LinkedStore[]> {
    id = GlobalService.isNullOrUndefined(id) ? this.user.id : id;
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest(
          '/user/getLinkedStores',
          { userId: id },
          this.token,
          'post'
        )
        .toPromise()
        .then(
          (o) => {
           // console.log('getLinkedStores', Object.assign(o));
            resolve(Object.assign(o));
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }

  /**
   * Kullanıcıya mağazaya erişim yetkisi ver.
   * @param id Etkilenen kullanıcının id'si
   */
  linkToStore(params: { userId: string; storeId: string }): Promise<{
    statusCode?: string;
    message?: string;
    error?: string;
  }> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest('/user/linkToStore', params, this.token, 'put')
        .toPromise()
        .then(
          (o) => {
            resolve(Object.assign(o));
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
  /**
   * Kullanıcıya mağazaya erişim yetkisi ver.
   * @param id Etkilenen kullanıcının id'si
   */
  unlinkToStore(params: { userId: string; storeId: string }): Promise<{
    statusCode?: string;
    message?: string;
    error?: string;
  }> {
    return new Promise((resolve, reject) => {
      this.ms
        .serviceRequest('/user/unlinkToStore', params, this.token, 'delete')
        .toPromise()
        .then(
          (o) => {
            resolve(Object.assign(o));
          },
          (e) => {
            this.glb.loadingVisible = false;
            reject(e);
          }
        );
    });
  }
}
