import { CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DxDataGridComponent } from 'devextreme-angular';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { CellDef } from 'jspdf-autotable';
import { ReportSearch, SaltesDetailReport } from 'src/app/models/report.model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { ReportService } from 'src/app/services/report.service';
import { StoreService } from 'src/app/services/store.service';
import '../../../../assets/calibri-normal';


@Component({
  selector: 'app-sales-detail-report',
  templateUrl: './sales-detail-report.component.html',
  styleUrls: ['./sales-detail-report.component.scss'],
  providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class SalesDetailReportComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  readonly allowedPageSizes = [10, 15, 'all'];

  readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  displayMode = 'compact';

  showPageSizeSelector = true;

  showInfo = true;

  showNavButtons = true;
  dataSource: SaltesDetailReport[];
  constructor(
    public storeService: StoreService,
    public helperService: HelperService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private reportService: ReportService,
    private globalService: GlobalService,
  ) {
    this.avg = this.avg.bind(this);
  }

  ngOnInit(): void { }

  onSearched(e: ReportSearch) {
    this.globalService.loadingText = 'Lütfen bekleyin.';
    this.globalService.loadingVisible = true;

    this.reportService.getSaleDetailReport(e).then((res) => {
      this.dataSource = res;
      setTimeout(() => {
        this.globalService.loadingVisible = false;
      }, 200);
    });
  }

  clear() {
    this.dataSource = [];
  }
  toplam(key: string, key2?: string): number {
    if (HelperService.isNullOrUndefined(this.dataSource)) {
      return 0;
    }
    return this.dataSource.reduce((accumulator, currentValue) => {
      if (!HelperService.isNullOrUndefined(key2)) {
        return (
          accumulator + Number(currentValue[key]) - Number(currentValue[key2])
        );
      }
      return accumulator + Number(currentValue[key]);
    }, 0);
  }

  exportPDF(): void {
    // console.log(this.dataGrid);
    const doc = new jsPDF();
    doc.setFont('calibri', 'normal');
    const statusLookUp: any[] = [];
    const headers: PdfMakeModel[] = [
      {
        key: 'businessDate',
        display: 'Taih',
        customValue: (el) =>
          this.datePipe.transform(el.businessDate, 'dd/MM/yyyy'),
      },
      {
        key: 'buildingName',
        display: 'Yapı Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'storeName',
        display: 'Mağaza Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'itemGroup',
        display: 'Ürün Grubu',
      },
      {
        key: 'itemId',
        display: 'Ürün Kodu',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'itemName',
        display: 'Ürün Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'quantity',
        display: 'T. Miktarı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.decimalPipe.transform(el.quantity, '1.0-2'),
      },
      {
        key: 'vatRate',
        display: 'KDV Oranı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => el.vatRate + '%',
      },
      {
        key: 'grandTotal',
        display: 'T. Ciro',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.grandTotal, '₺'),
      },
      {
        key: 'vatTotal',
        display: 'T. KDV Tutarı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.vatTotal, '₺'),
      },
      {
        key: 'netTotal',
        display: 'T. Net Satış',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.netTotal, '₺'),
      },
      // {
      //   key: 'status',
      //   display: 'Durum',
      //   lookup: (key) => statusLookUp.find(el => el.status == key)?.text
      // }
    ];
    const summary: CellDef[] = [
      {
        content: 'Toplamlar',
        colSpan: 8,
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('grandTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('vatTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('netTotal'), '₺'),
      },
    ];
    this.helperService.makePdf(
      'Satış Detay Raporu',
      this.dataSource,
      headers,
      false,
      summary
    );
  }

  exportExcel(e: HTMLElement): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Satış Detay Raporu');

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Satış Detay Raporu.xlsx'
        );
      });
    });
  }
  avg(e): string {
    // console.log('avg:', e);
    if (!e) {
      return;
    }

    return this.decimalPipe.transform(e, '1.2-2') + '%';
  }

}
