
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({ name: 'TRYCurrecy' })
export class TRYCurrecyPipe implements PipeTransform {
    transform(value: string, symbol?: string): string {
        if (value === 'currency') {
            const currencySymbol = symbol ?? '₺';
            return currencySymbol + ' #,##0.##'

        }
        return value;
    }
}