<div style="height: 100%;" class="d-flex flex-column">
    <div class="d-flex justify-content-between">
        <span>
            <h5>Yetkileri Düzenle</h5>
        </span>
        <button nbButton ghost (click)="close()">
            <nb-icon icon="close"></nb-icon>
        </button>
    </div>
    <hr style="margin-top: 0px;">
    <!-- {{stores | json}} -->
    <div style="overflow: auto; height: 100%;">
        <dx-scroll-view>

            <div class="panel-group " id="accordion" role="tablist" aria-multiselectable="true"
                *ngFor="let item of this.storeService.buildingList;let buildIndex=index">
                <div class="panel panel-default">
                    <div class="panel-heading" role="tab" id="headingOne">
                        <h6 class="panel-title">
                            <a data-toggle="collapse" data-parent="#accordion" style="color:#292452"
                                [href]="'#collapseOne'+buildIndex" aria-expanded="false"
                                [attr.aria-controls]="'collapseOne'+buildIndex" class="collapsed">
                                {{item.name}}
                            </a>
                        </h6>

                    </div>
                    <div #col [id]="'collapseOne'+buildIndex" style="margin-top: 25px;margin-left: 34px;"
                        class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">

                        <div class="form-group form-check" *ngFor="let storeItem of stores[item.id]">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" data-ng-model="example.check" [(checked)]="storeItem.value"
                                        (change)="setPermission({buildId:item.id,storeId:storeItem.id,value:$event.target.checked})">
                                    <span class="box"></span>
                                    {{storeItem.name}}
                                </label>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </dx-scroll-view>

    </div>
    <!-- <div class="d-flex justify-content-center ">
        <button type="button" class="btn btn-next">Kaydet</button>
    </div> -->
</div>