import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';
import { confirm } from 'devextreme/ui/dialog';

@Component({
  selector: 'store-list-popup',
  templateUrl: './store-list-popup.component.html',
  styleUrls: ['./store-list-popup.component.scss']
})
export class StoreListPopupComponent implements OnInit {

  @Input() set refresh(val) {
    if (val != this._refresh) {
      this.getStores();
    }
    this._refresh = val;
  }
  _refresh: boolean = false;
  stores: Store[] = [];
  constructor(
    public storeService: StoreService,
    public glb: GlobalService,
  ) {
    this.getStores();

  }

  ngOnInit(): void {
  }

  getStores() {
    this.glb.loadingText = "Yükleniyor..."
    this.glb.loadingVisible = true
    this.storeService.getStores().then(o => {
      this.glb.loadingVisible = false;
      this.stores = this.storeService.getStoresByBuildId(this.storeService.activeBuilding.id);

    })
  }

  deleteStore(storeId) {
    const result = confirm("Mağazayı silmek istediğinize emin misiniz?", "Mağaza Sil");
    result.then((dialogResult: any) => {
      if (dialogResult) {
        this.glb.loadingText = "Siliniyor..."
        this.glb.loadingVisible = true
        this.storeService.deleteStore(storeId).then(o => {
          if (o) {
            this.glb.loadingVisible = false;
            this.glb.pushToast('Mağaza Silindi', "success", 2000)
            this.getStores()
          }
        })
      }
    })
  }
}
