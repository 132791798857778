<form class="needs-validation store-form" style="margin-bottom: 50px;">
    <div class="form-row">
      <div class="col-xs-12 col-lg-12 col-xl-12 col-sm-12 col-md-12">
       
        <div class="dx-field">
            <div class="dx-field-label">Başlangıç Tarihi : </div>
            <div class="dx-field-value">
              <dx-date-box [(value)]="startDate" type="date" displayFormat="dd.MM.yyyy"> </dx-date-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">Bitiş Tarihi :</div>
            <div class="dx-field-value">
              <dx-date-box  [(value)]="endDate"  type="date" displayFormat="dd.MM.yyyy"> </dx-date-box>
            </div>
          </div>
      </div>
      <button nbButton class="mx-auto" style="margin-top: 20px;" status="danger" (click)="deleteTransactions()">Kayıtları Sil</button>
    </div>
</form>
<p style="text-decoration: underline;color:brown;font-weight: bold;">ÖNEMLİ</p>
<small style="margin-top: 20px;">Seçilen tarihler arasında ilgili mağazanın hem satış hemde Z verileri <strong style="color:brown;font-weight: bold;">silinecektir</strong>.
  Yapılan bu işlemin geri dönüşü <strong style="color:brown;font-weight: bold;">yoktur.</strong></small>

