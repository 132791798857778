import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-api-key',
  templateUrl: './store-api-key.component.html',
  styleUrls: ['./store-api-key.component.scss']
})
export class StoreApiKeyComponent implements OnInit, OnChanges {
  @Input() storeId = "";
  @Input() myCallback: Function;
  apiKey: string = ""
  constructor(public glb: GlobalService, public storeService: StoreService) { }
  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.storeId.currentValue;
    this.storeId = changes.storeId.currentValue;
   // console.log('storeID', this.storeId)
    if (this.storeId != "") {
      this.getApiKey()
    }
  }

  ngOnInit(): void {
  }

  getApiKey() {
    this.storeService.getStoreApiKey(this.storeId).then(o => {
      this.apiKey = o
    })
  }

  copyapiKey(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    this.glb.pushToast('API-KEY Kopyalandı', 'success', 2000)
    setTimeout(() => {
      this.myCallback()
    }, 2000);
  }

  close() {
    this.myCallback()
  }

}
