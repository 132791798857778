import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Building, Store } from '../models/store-model';

import { GlobalService } from './global.service';
import { MasterService } from './master.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class StoreService {
  activeStore: Store = new Store()
  selectedStore: Store = new Store()
  storeList: Store[] = []
  buildingList: Building[] = []
  activeBuilding: Building = new Building()
  constructor(public ms: MasterService, public glb: GlobalService, public us: UserService) { }
  /**
   * Get Stores by build id
   * @param buildId 
   * @returns 
   */
  getStoresByBuildId(buildId: string): Store[] {
    return this.storeList.filter(el => el.buildingId === buildId);
  }
  getStores(): Promise<Store[]> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/store/getStores", {}, this.us.token, "post").toPromise().then(o => {
        //  console.log('storeList', Object.assign(o))
        this.storeList = Object.assign(o)
        resolve(this.storeList)
      }, e => {
        // this.glb.pushToast(e.error.message, 'error', 2000)
        this.glb.loadingVisible = false;
        //  console.log('storeList', e)
        reject(false)
      })
    })
  }

  getStoreById(storeId): Promise<Store> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/store/getStore", { id: storeId }, this.us.token, "post").toPromise().then(o => {
        // console.log('activeStore', Object.assign(o))
        this.selectedStore = Object.assign(o)
        resolve(this.selectedStore)
      }, e => {
        //   console.log('activeStore', e)
        reject(false)
      })
    })
  }

  deleteStore(storeId): Promise<Boolean | any> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/store/deleteStore", { id: storeId }, this.us.token, "delete").toPromise().then(o => {
        // console.log('deleteStore', Object.assign(o))
        resolve(Object.assign(o))
      }, e => {
        //  console.log('deleteStore', e)
        reject(e)
      })
    })
  }



  saveStore(store: Store): Promise<Store> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/store/saveStore", store, this.us.token, "put").toPromise().then(o => {
        //  console.log('saveStore', Object.assign(o))
        resolve(Object.assign(o))
      }, (e: HttpErrorResponse) => {
        this.glb.loadingVisible = false
        this.glb.pushToast(e.error.message, 'error', 2000)
        // console.log('saveStore', e)
        reject(e)
      })
    })
  }

  getStoreApiKey(storeId): Promise<string> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/store/getStoreApiKey", { id: storeId }, this.us.token, "post").toPromise().then(o => {
        let response: any = Object.assign(o)
        resolve(response.apiKey)
      }, e => {
        this.glb.loadingVisible = false
        // console.log('getStoreApiKey', e)
        reject(false)
      })
    })
  }

  deleteStoreTransactions(storeId, startedDate, endDate): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/document/deleteDocuments", { storeId: storeId, startDate: startedDate, endDate: endDate }, this.us.token, "delete").toPromise().then(o => {
        let response: any = Object.assign(o)
       if(response.message == "success")
       {
        resolve(true)
       }
       else{response(false)}
     
      }, e => {
        this.glb.loadingVisible = false
        // console.log('getStoreApiKey', e)
        reject(false)
      })
    })
  }



  getBuildings(): Promise<Building[]> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/building/getBuildings", {}, this.us.token, "post").toPromise().then(o => {
        //  console.log('getBuildings', Object.assign(o))
        this.buildingList = Object.assign(o)
        resolve(Object.assign(o))
      }, e => {
        this.glb.loadingVisible = false
        if (e.message == "Timeout has occurred") {
          this.glb.pushToast('İstek zaman aşınmına uğradı.', 'warning', 2000)
        }
        // console.log('getBuildings', e)
        reject(false)
      })
    })
  }

  getBuildingById(buildingId): Promise<Building> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/building/getBuilding", { id: buildingId }, this.us.token, "post").toPromise().then(o => {
        // console.log('activeBuilding', Object.assign(o))
        this.activeBuilding = Object.assign(o)
        resolve(this.activeBuilding)
      }, e => {
        this.glb.loadingVisible = false
        if (e.message == "Timeout has occurred") {
          this.glb.pushToast('İstek zaman aşınmına uğradı.', 'warning', 2000)
        }
        // console.log('activeBuilding', e)
        reject(false)
      })
    })
  }

  saveBuilding(store: Building): Promise<Building> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/building/saveBuilding", store, this.us.token, "put").toPromise().then(o => {
        //  console.log('saveBuilding', Object.assign(o))
        resolve(Object.assign(o))
      }, e => {
        this.glb.loadingVisible = false
        if (e.message == "Timeout has occurred") {
          this.glb.pushToast('İstek zaman aşınmına uğradı.', 'warning', 2000)
        }
        //  console.log('saveBuilding', e)
        reject(false)
      })
    })
  }


  deleteBuilding(id): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      this.ms.serviceRequest("/building/deleteBuilding", { id: id }, this.us.token, "delete").toPromise().then(o => {
        //  console.log('deleteBuilding', Object.assign(o))
        resolve(Object.assign(o))
      }, e => {
        this.glb.loadingVisible = false
        if (e.message == "Timeout has occurred") {
          this.glb.pushToast('İstek zaman aşınmına uğradı.', 'warning', 2000)
        }
        //  console.log('deleteBuilding', e)
        reject(e)
      })
    })
  }



}
