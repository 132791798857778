import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';
import { HelperService } from './helper.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public us: UserService,
    private router: Router,
    private glb: GlobalService
  ) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const refreshToken = localStorage.getItem('refreshToken');
    // console.log('canActivate:', refreshToken);
    if (HelperService.isNullOrUndefined(refreshToken)) {
      this.router.navigate(['login']);
      return false;
    }
    if (!this.us.logged) {
      //   console.log('Loging Status: False');
      await this.us
        .autoLogin(refreshToken)
        .then((o) => {
          if (o) {
            this.router.navigate(['layout/dashboard']);
          } else {
            this.router.navigate(['login']);
          }
        })
        .catch((e) => {
          this.router.navigate(['login']);
        });
    }
    return true;
  }
}
