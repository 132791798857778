import { Component, Input, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  @Input() myCallback: Function;
  mailAddress: string = ""
  constructor(public us: UserService, public glb: GlobalService) { }

  ngOnInit(): void {
  }

  
  validate() {
    var form = document.getElementsByClassName('forget-password-form')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return
    }
    this.resetPassword()
  }

  resetPassword() {
    this.glb.loadingText = "Şifre sıfırlama talebiniz e-posta adresinizie gönderiliyor..."
    this.glb.loadingVisible = true
      this.us.resetPasswordMail(this.mailAddress).then(x => {
        this.glb.loadingVisible = false;
        this.myCallback()
        if (x.message == "success") {
          this.glb.pushToast('Şifre sıfırlama talebiniz mail adresinize gönderildi.', "success", 2000)
        }

      })   

  }

  // resetPassword() {
  //   this.glb.loadingText = "Şifre sıfırlama talebiniz e-posta adresinizie gönderiliyor..."
  //   this.glb.loadingVisible = true
  //   setTimeout(() => {
  //     this.glb.loadingVisible = false
  //     this.glb.pushToast('Şifre sıfırlama talebiniz e-posta adresinize iletildi.', "success", 2000)
  //     this.myCallback()
  //   }, 2000);
  // }

}
