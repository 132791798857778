<form class="needs-validation forget-password-form">


  <div class="form-group">
    <label for="mailaddress">E-Posta*</label>
    <input type="email" class="form-control" id="mailaddress" [(ngModel)]="mailAddress" [ngModelOptions]="{standalone: true}" required placeholder="E-Posta" required>
    <small id="emailHelp" class="form-text text-muted">Lütfen sisteme kayıtlı mail adresinizi giriniz.</small>
</div>

  <div class="dx-field d-flex justify-content-center">
    <button type="submit" (click)="validate()" class="btn btn-resetpwd">Parola Sıfırla</button>
</div>
</form>


 
