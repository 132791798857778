import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { url } from 'inspector';
import { Observable } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private glb: GlobalService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let url = req.url;
    let newUrl = new URL(url);
    let path = newUrl.pathname;
    console.log(newUrl);
    let apiKey = environment.apiKey;
    // if (url.includes('api/faq/') || url.includes('api/feed/')) {
    //   if (req.body.specialApps) {
    //     delete req.body.specialApps;
    //     if (store.apiKey) {
    //       apiKey = store.apiKey;
    //     }
    //   }
    // }

    let user = localStorage.getItem('userInfoPortal');
    let token = {};
    if (user) {
      token['token'] = JSON.parse(user)['token'];
    }

    if (this.getUrlForDomain()) {
      url = this.getUrlForDomain() + newUrl.pathname;
      console.log(url);
    }

    if (this.getApiKeyForDomain()) {
      apiKey = this.getApiKeyForDomain();
    }

    let usedHeader: any = {};
    let cdnHeader = {
      'x-api-key': 'VkV2VtUkRJRVp6WVZOQ',
      // 'Content-Type': 'multipart/form-data',
      'external-id': req.headers.get('external-id'),
    };
    let header = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      apikey: apiKey,
    };
    //   if (url.includes(environment.cdnService)) {
    //     usedHeader = cdnHeader;
    //   } else {
    //     usedHeader = header;
    //   //  usedHeader.apikey=this.domains.bodrum;
    //   }

    const newReq = req.clone({
      setHeaders: {
        apiKey: apiKey,
        'x-api-key': apiKey,
        language: 'en',
        region: 'us',
      },
      url: url,
    });
    return next.handle(newReq);
  }

  getApiKeyForDomain(): string | undefined {
    let subDomain = window.location.hostname.split('.')[0];
   let apiKey = this.keys[subDomain];
    //let apiKey = "b3phay1zdG9yZS10YWNraW5nLWV3ZndmZXdlZnNkZmRlcjQ0M3QzcnZkc2Vyd2Vm"
    //   this.glb.dbName = this.dbNames[subDomain];
    return apiKey;
  }

  getUrlForDomain(): string | undefined {
    let subDomain = window.location.hostname.split('.')[0];
    let baseUrl = this.urls[subDomain];
    //   this.glb.dbName = this.dbNames[subDomain];
    if (!baseUrl) baseUrl = environment.baseUrl;
    return baseUrl;
  }

  keys = {
    istmarina: 'YjNwaGF5MXpkIDBZV05yYVc1bkxXVjNabmRtWiAwTTNRemNuWmtjMlZ5ZDJWbQ',
    ozak: 'b3phay1zdG9yZS10YWNraW5nLWV3ZndmZXdlZnNkZmRlcjQ0M3QzcnZkc2Vyd2Vm',
  };

  urls = {
    istmarina: 'https://istmarina-service.cloudlabs.llc:6600',
    ozak: 'https://ozak-service.cloudlabs.llc:6600',
  };
}
