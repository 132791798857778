import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MenuPipe } from '../components/shared/services/menu.pipe';
import { Menu } from '../models/sidebar-menu-model';
import { SidebarService } from './sidebar.service';

@Injectable({
  providedIn: 'root',
})
export class RouterGuardService implements CanActivateChild {
  constructor(
    private router: Router,
    private sidebar: SidebarService,
    private menuPipe: MenuPipe
  ) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const menus: Menu[] = this.menuPipe.transform(
      this.getMobiles(this.sidebar.getSideMenu())
    );
   // console.log('RouterGuardService:', childRoute.url);
    return menus.some((el) => el.tag === childRoute.url[0]?.path);
  }
  getMobiles(value: Menu[]): Menu[] {
    const all: Menu[] = [];

    value.forEach((el: Menu) => {
      if (el?.children?.length > 0) {
        all.push(...this.getMobiles(el.children));
        all.push({ ...el, children: [] });
      } else {
        all.push({ ...el, children: [] });
      }
    });
    return all;
  }
}
