import { Component, Input, OnInit } from '@angular/core';
import { Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-perm',
  templateUrl: './user-perm.component.html',
  styleUrls: ['./user-perm.component.scss']
})
export class UserPermComponent implements OnInit {
  @Input() userId = '';
  @Input() myCallback: Function;
  stores = {};

  storeList: any[] = [];
  constructor(public storeService: StoreService,
    public glb: GlobalService,
    private userService: UserService) { }
  ngOnInit(): void {
    this.getBuildings();
  }
  getLinkedStores(): void {
    this.userService.getLinkedStores(this.userId).then(res => {
      this.storeList = res;
      Object.keys(this.stores).forEach(key => {
        this.stores[key].forEach(el => {
          el.value = this.storeList.some(s => s.storeId === el.id);
        });
      });
      this.stores = { ...this.stores };
    }, e => {

    });
  }

  getStores(): void {
    this.glb.loadingText = 'Yükleniyor...';
    this.glb.loadingVisible = true;
    this.storeService.getStores().then(o => {
      this.glb.loadingVisible = false;
      this.storeService.buildingList.forEach(el => {
        this.stores[el.id] = this.storeService.getStoresByBuildId(el.id);
      });
      this.getLinkedStores();

    });
  }
  getBuildings(): void {
    this.glb.loadingText = 'Yapılar Listeleniyor...';
    this.glb.loadingVisible = true;
    this.storeService.getBuildings().then(o => {
      this.glb.loadingVisible = false;
      this.getStores();
    });
  }

  taskId;
  setPermission(e): void {
    // console.log('setPermission:', e);
    this.glb.loadingText = 'Lütfen Bekleyin.';
    this.taskId = setTimeout(() => {
      this.glb.loadingVisible = true;
    }, 1000);
    if (e.value) {
      this.linkStore(e);
    } else {
      this.unLinkStore(e);
    }
  }
  linkStore(e): void {
    this.userService.linkToStore({ storeId: e.storeId, userId: this.userId }).then(res => {
      clearTimeout(this.taskId);
      this.glb.loadingVisible = false;

    }, e => {
      this.glb.pushToast('Yetki Verilemedi.', 'error', 200);
      e.value = false;
      clearTimeout(this.taskId);
      this.glb.loadingVisible = false;

    });

  }
  unLinkStore(e): void {
    this.userService.unlinkToStore({ storeId: e.storeId, userId: this.userId }).then(res => {
      clearTimeout(this.taskId);
      this.glb.loadingVisible = false;

    }, e => {
      this.glb.pushToast('Yetki Verilemedi.', 'error', 200);
      e.value = true;
      clearTimeout(this.taskId);
      this.glb.loadingVisible = false;

    });
  }

  close(): void {
    this.myCallback();
  }
}
