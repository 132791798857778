import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { Rights, UserStatus } from 'src/app/models/global-models';
import { User } from 'src/app/models/user-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnChanges {
  user: User = new User();
  public customPatterns = { '0': { pattern: new RegExp('[a-zA-Z]') } };
  statuses: UserStatus[] = [];
  rights: Rights[] = [];
  @Input() userId = '';
  @Input() myCallback: Function;
  constructor(
    public glb: GlobalService,
    public us: UserService,
    private mockService: MockService,
    private router: Router
  ) {
    this.fillUserRights();
    this.getUserStatusList();
  }

  getUserStatusList(): void {
    // status: 'new' | 'registered' | 'deleted' | 'banned' = 'new'
    this.statuses.push(
      {
        name: 'Yeni',
        value: 'new',
      },
      {
        name: 'Pasif',
        value: 'banned',
      },
      {
        name: 'Silinmiş',
        value: 'deleted',
      },
      {
        name: 'Aktif',
        value: 'registered',
      }
    );
  }

  fillUserRights(): void {
    this.rights = this.mockService.getUserRightMocks();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.userId.currentValue;
    this.userId = changes.userId.currentValue;
   // console.log('UserID', this.userId);
    if (this.userId !== '') {
      this.getUser();
    } else {
      this.user = new User();
      this.user.gsm = '90';
    }
  }

  validate() {
    let form = document.getElementsByClassName(
      'user-form'
    )[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return;
    }
    this.saveUser();
  }

  saveUser(): void {
   // console.log(this.user);
    this.glb.loadingText = 'Kullanıcı Kaydediliyor...';
    this.glb.loadingVisible = true;
    this.user.gsm = this.user.gsm.replace('+', '');
    this.user.gsm = '+' + this.user.gsm;
    this.us.saveUser(this.user).then((o) => {
      this.glb.loadingVisible = false;
      if (o.id !== '') {
        this.glb.pushToast('Kullanıcı Kaydedildi.', 'success', 2000);
        this.us.getUsers();
        if (this.userId === this.us.user?.id) {
          const oldRight: string = this.us.user.userRight;
          this.us.user = o;
          if (
            this.router.url.includes('user-operations') &&
            oldRight !== this.user.userRight
          ) {
            this.glb.show();
            location.reload();
          }
        }
        this.myCallback();
      }
    });
  }

  onChange(value) {
    this.user.userRight = value;
  }

  onStatusChanged(value) {
    this.user.status = value;
  }

  async getUser() {
    this.glb.loadingText = 'Lütfen bekleyin...';
    this.glb.loadingVisible = true;

    if (HelperService.isNullOrUndefined(this.us.userList)) {
      await this.us.getUsers().then((r) => null);
    }
    if (this.us.userList.length === 0) {
      await this.us.getUsers().then((r) => null);
    }
    this.glb.loadingVisible = false;

    this.user = this.us.userList.find((x) => x.id === this.userId);
  }

  ngOnInit(): void {}
}
