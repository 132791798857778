<kerzz-report-filter (onSearched)="onSearched($event)"></kerzz-report-filter>


<!-- Table -->

<div class="d-flex my-2 flex-wrap">
    <button class="k-button" (click)="exportExcel()" nbButton>
        <span class="mr-2">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
        </span>
        Excel İndir
    </button>
    <button (click)="exportPDF()" class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            picture_as_pdf
        </span>
        PDF İndir
    </button>
    <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-lg-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            <nb-icon icon="trash-2-outline"></nb-icon>
        </span>
        Filtreyi Temizle
    </button>
</div>
<div class="col-12 md-wd overflow-auto">
    <dx-data-grid class=" " [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false"
        [showRowLines]="true" [cellHintEnabled]="true" [allowColumnReordering]="true" [allowColumnResizing]="true">
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-export></dxo-export>
        <dxo-pager [visible]="true"  [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="showPageSizeSelector"
        [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
      </dxo-pager>
      <dxo-state-storing [enabled]="true" type="localStorage" storageKey="month-closing-state"></dxo-state-storing>
        <dxo-summary>
            <dxi-total-item column="grandTotal" [valueFormat]="{type:'currency',precision:2}" summaryType="sum">
            </dxi-total-item>
            <dxi-total-item column="vatTotal" [valueFormat]="{type:'currency',precision:2}" summaryType="sum">
            </dxi-total-item>
            <dxi-total-item column="netTotal" [valueFormat]="{type:'currency',precision:2}" summaryType="sum">
            </dxi-total-item>

            <dxi-total-item column="countFolio" [valueFormat]="{type:'fixedPoint',precision:2}" dataType="number"
                summaryType="sum">
            </dxi-total-item>
            <dxi-total-item column="totalPersonCount" dataType="number" summaryType="sum">
            </dxi-total-item>

            <dxi-total-item column="avgPerFolioTotal" [valueFormat]="{type:'currency',precision:2}" summaryType="avg">
            </dxi-total-item>
            <dxi-total-item column="countPax" [valueFormat]="{type:'fixedPoint',precision:2}" dataType="number"
                summaryType="sum">
            </dxi-total-item>
            <dxi-total-item column="avgPerPaxTotal" [valueFormat]="{type:'currency',precision:2}" summaryType="avg">
            </dxi-total-item>
        </dxo-summary>

        <dxi-column dataField="buildingName" caption="Yapı Adı" dataType="number"></dxi-column>
        <dxi-column dataField="storeName" caption="Mağaza Adı" dataType="number"></dxi-column>
        <dxi-column dataField="grandTotal" caption="T.Ciro" dataType="number" [format]="{type:'currency',precision:2}">
        </dxi-column>
        <dxi-column dataField="vatTotal" caption="T.KDV Tutarı" dataType="number"
            [format]="{type:'currency',precision:2}"></dxi-column>
        <dxi-column dataField="netTotal" caption="T.Net Satış" dataType="number"
            [format]="{type:'currency',precision:2}">
        </dxi-column>
        <dxi-column dataField="countFolio" caption="T.Adisyon Sayısı" dataType="number">
            <dxo-format type="fixedPoint" [precision]="2">
            </dxo-format>
        </dxi-column>
        <dxi-column dataField="avgPerFolioTotal" caption="O.Adisyon Tutarı " dataType="number"
            [format]="{type:'currency',precision:2}">
        </dxi-column>
        <dxi-column dataField="countPax" dataType="number" caption="T.Kişi">
            <dxo-format type="fixedPoint" [precision]="2">
            </dxo-format>
        </dxi-column>
        <dxi-column dataField="avgPerPaxTotal" dataType="number" [format]="{type:'currency',precision:2}"
            caption="O.Kişi Tutarı"></dxi-column>

    </dx-data-grid>

</div>