export class Store {
    createDate: Date = new Date()
    updateDate: Date = new Date()
    id: string = ""
    name: string = ""
    person: Person = new Person()
    status: "none" | "open" | "out-of-service" | "closed" | "repair" = "open"
    commercialName: string = ""
    taxOffice: string = ""
    taxNo: string = ""
    city: string = ""
    town: string = ""
    address: string = ""
    phone: string = ""
    floor: string = ""
    section: string = ""
    storeM2: number = 0
    buildingId: string = ""
    description: string = ""
    apikey: string = ""
    zReportControl : boolean = false
    saleControl : boolean = false
    posCompany : string = ""
}

export class Person {
    name: string = ""
    lastName: string = ""
    gsm: string = ""
    mail: string = ""
    isActive: boolean = false
}

export class Building {
    id: string = ""
    name: string = ""
    city: string = ""
}
export interface LinkedStore {
    id: string;
    createDate: string;
    userId: string;
    storeId: string;
}