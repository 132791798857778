<div class="d-flex my-2">
    <button class="k-button" nbButton>
        <span class="mr-2">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
        </span>
        Excel İndir
    </button>
    <button class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            picture_as_pdf
        </span>
        PDF İndir
    </button>
</div>
<div class="col-12 table-style table-style-head">
    <table class="table mb-0">
        <thead>
            <tr>
                <th scope="col">Yapı Adı</th>
                <th scope="col">Mağaza Adı</th>
                <th scope="col">Toplam Satış</th>
                <th scope="col">T. Adisyon Sayısı</th>
                <th scope="col">T. Kişi Sayısı</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td scope="col">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                        <input fieldSize="small" type="text" nbInput>
                    </nb-form-field>
                </td>
                <td scope="col">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                        <input fieldSize="small" type="text" nbInput>
                    </nb-form-field>
                </td>
                <td scope="col">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                        <input fieldSize="small" type="text" nbInput>
                    </nb-form-field>
                </td>
                <td scope="col">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                        <input fieldSize="small" type="text" nbInput>
                    </nb-form-field>
                </td>
                <td scope="col">
                    <nb-form-field>
                        <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                        <input fieldSize="small" type="text" nbInput>
                    </nb-form-field>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="col-12 table-style table-style-body" style="height: 500px;">
    <table class="table">
        <thead style="display: none;">
            <tr>
                <th scope="col">Yapı Adı</th>
                <th scope="col">Mağaza Adı</th>
                <th scope="col">Toplam Satış</th>
                <th scope="col">T. Adisyon Sayısı</th>
                <th scope="col">T. Kişi Sayısı</th>
            </tr>
        </thead>
        <tbody>

            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
            <tr>
                <td>
                    Balçova Kipa AVM
                </td>
                <td>Balçova Kipa AVM</td>
                <td style="padding-left: 75px;">0,00 ₺</td>
                <td style="padding-left: 122px;">50</td>
                <td style="padding-left: 75px;">130</td>
            </tr>
        </tbody>
    </table>
</div>