import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { CellDef } from 'jspdf-autotable';
import { Subscription } from 'rxjs';
import { MonthlySaleReport, ReportSearch } from 'src/app/models/report.model';
import { Building, LinkedStore, Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { ReportService } from 'src/app/services/report.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';

import { exportPivotGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { DxDataGridComponent, DxPivotGridComponent } from 'devextreme-angular';

@Component({
  selector: 'app-monthly-sales-report',
  templateUrl: './monthly-sales-report.component.html',
  styleUrls: ['./monthly-sales-report.component.scss'],
  providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class MonthlySalesReportComponent implements OnInit {
  @ViewChildren('table') tables!: QueryList<ElementRef>;
  @ViewChild(DxPivotGridComponent, { static: false })
  dxPivotGrid: DxPivotGridComponent;

  dataSource: MonthlySaleReport[] = [];
  pivotGridDataSource: any;

  filterForm: FormGroup;
  filterFormSubscription: Subscription;
  form: FormGroup;

  get date(): FormControl {
    return this.form.get('date') as FormControl;
  }
  get buildId(): FormControl {
    return this.form.get('buildId') as FormControl;
  }
  get storeId(): FormControl {
    return this.form.get('storeId') as FormControl;
  }
  stores: Store[] = [];
  isMainFiltred: boolean = false;
  linkedStores: LinkedStore[] = [];
  filterItems: { stores?: Store[]; builds?: Building[] } = {};
  constructor(
    private mockServiec: MockService,
    private fb: FormBuilder,
    public storeService: StoreService,
    public helperService: HelperService,
    private userService: UserService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private globalService: GlobalService,
    private reportService: ReportService
  ) {
    this.dataSource = mockServiec.getMonthlySale();
    this.initFilterForm();
    this.initSlectForm();
    this.generateData();
  }
  onSearched(e: ReportSearch): void {
    this.globalService.loadingText = 'Lütfen bekleyin.';
    this.globalService.loadingVisible = true;

    this.reportService.getMonthlySaleReport(e).then((res) => {
      this.dataSource = res;
      this.pivotGridDataSource = {
        ...this.pivotGridDataSource,
        store: this.dataSource,
      };
      this.dxPivotGrid.instance.repaint();
      setTimeout(() => {
        this.globalService.loadingVisible = false;
      }, 200);
    });
  }
  clear(): void {
    this.pivotGridDataSource = {
      ...this.pivotGridDataSource,
      store: [],
    };
  }
  generateData(): void {
    this.pivotGridDataSource = {
      allowExpandAll: false,

      fields: [
        {
          visible: true,
          groupName: 'stores',
        },
        {
          caption: 'Mağazalar',
          dataField: 'buildingName',
          width: 120,
          area: 'row',
          expanded: true,
        },
        {
          caption: 'Yapı Adı',
          dataField: 'storeName',
          width: 120,
          area: 'row',
        },
        {
          // groupInterval: "month",
          visible: true,
          dataField: 'month',
          area: 'column',
          format: (el) => {
            const date: Date = new Date();
            date.setMonth(+el - 1);
            return this.datePipe.transform(date, 'MMMM');
          },
        },
        {
          dataField: 'grandTotal',
          caption: 'T.Ciro',
          area: 'data',
          precision: 2,
          summaryType: 'sum',
          format: 'currency',
        },
        {
          dataField: 'countFolio',
          caption: 'T.Adisyon Sayısı',
          area: 'data',
          dataType: 'number',
          summaryType: 'sum',
        },
        {
          dataField: 'countPax',
          caption: 'T.Kişi',
          area: 'data',
          dataType: 'number',
          summaryType: 'sum',
        },
      ],
      store: this.dataSource,
    };
  }

  ngOnInit(): void {
    this.userService.getLinkedStores().then((res) => {
      this.linkedStores = res;
      this.setFilterItems();
    });
  }
  setFilterItems(): void {
 //   console.log('linkedStores:', this.linkedStores);

    this.filterItems.stores = this.storeService.storeList.filter((el) =>
      this.linkedStores.some((s) => s.storeId == el.id)
    );
    this.filterItems.builds = this.storeService.buildingList.filter((el) =>
      this.filterItems.stores.some((s) => s.buildingId === el.id)
    );
  //  console.log('filterItem:', this.filterItems);
  }

  createFilterForm(group): FormGroup {
    const form = this.fb.group(group);
    this.filterFormSubscription = form.valueChanges.subscribe((filters) => {
      this.dataSource = [
        ...HelperService.Filter<any>(
          this.mockServiec.getMonthlySale(),
          filters
        ),
      ] as any;
    });
    return form;
  }
  onMainFilter(): void {
    if (this.isMainFiltred) {
      this.dataSource = [...this.mockServiec.getMonthlySale()] as any;
    } else {
      this.dataSource = [
        ...HelperService.MainFilter(this.mockServiec.getMonthlySale(), {
          storeName: this.storeId.value,
          date: this.date.value,
        }),
      ] as any;
    }
    this.isMainFiltred = !this.isMainFiltred;
  }
  initSlectForm(): void {
    this.form = this.fb.group({
      date: [new Date().getFullYear()],
      buildId: [],
      storeId: [{ value: null, disabled: true }],
    });
    this.buildId.valueChanges.subscribe((value: string[]) => {
      this.storeId.reset([], { emitEvent: false });
      if (HelperService.isNullOrUndefined(value) || value.length === 0) {
        this.storeId.disable({ emitEvent: false });
      } else if (value.length === 1 && value.some((el) => el === 'All')) {
        this.storeId.disable({ emitEvent: false });
      } else {
        this.stores = [];
        value.forEach((el) => {
          this.stores = [
            ...this.stores,
            ...this.filterItems.stores.filter((f) => f.buildingId == el),
          ];
        });
        this.storeId.enable({ emitEvent: false });
      }
      // console.log('valueChage:', value);
    });
  }
  initFilterForm(): void {
    const formControls: any = {
      date: [],
      buildName: [],
      storName: [],
      totalQuantity: [],
      totalKDV: [],
      totalNetSale: [],
      totalAdisyon: [],
      middleAdisyon: [],
      totalPersonCount: [],
      middlePersonCount: [],
    };
    //  this.dataSource = HelperService.Filter<any>(this.mockServiec.getMonthlySale(), {});
    this.filterForm = this.createFilterForm(formControls);
  }
  //#region BildingSelection
  selectAllBuilding(buildingList: Building[], key: string): string[] {
    return buildingList.map((el) => el[key]);
  }
  isSlectedBuilding(value: string): boolean {
    return (
      this.buildId?.value?.filter((el) => el != value).length ===
      this.storeService?.buildingList?.length
    );
  }
  selectAllBuildButton(): void {
    let values: string[] = [];
    if (!this.isSlectedBuilding('All')) {
      values = this.selectAllBuilding(this.storeService.buildingList, 'id');
    }
    this.buildId.setValue(values, { emitEvent: false });
  }
  //#endregion

  //#region  Store Selection
  selectAllStore(storeList: Store[], key: string): string[] {
    return storeList.map((el) => el[key]);
  }
  isSlectedStore(value: string): boolean {
    return (
      this.storeId?.value?.filter((el) => el != value).length ===
      this.stores?.length
    );
  }
  selectAllStoreButton(): void {
    let values: string[] = [];
    if (!this.isSlectedStore('All')) {
      values = this.selectAllStore(this.stores, 'id');
    }
    this.storeId.setValue(values, { emitEvent: false });
  }
  exportExcel(): void {
    // const table: HTMLTableElement = (this.tables.first.nativeElement as HTMLTableElement).cloneNode(true) as HTMLTableElement;
    // table.deleteTHead();
    // table.createTHead();
    // const rowCount: number = table.rows.length;
    // for (let i = 0; i < rowCount; i++) {
    //   table.deleteRow(0);
    // }
    // this.tables.forEach(el => {
    //   const contentTabele: HTMLTableElement = (el.nativeElement as HTMLTableElement).cloneNode(true) as HTMLTableElement;
    //   for (let index = 0; index < contentTabele.rows.length; index++) {
    //     const row: HTMLTableRowElement = table.rows[index] ?? table.insertRow(-1);
    //     const contentRow: HTMLTableRowElement = contentTabele.rows[index].cloneNode(true) as any;

    //     const contentRowCount: number = contentRow.cells.length;
    //     for (let j = 0; j < contentRowCount; j++) {
    //       const contentCell: HTMLTableDataCellElement | HTMLTableHeaderCellElement =
    //         contentRow.cells.item(j).cloneNode(true) as HTMLTableDataCellElement | HTMLTableHeaderCellElement;
    //       const newCell = row.insertCell(-1);

    //       newCell.colSpan = contentCell.colSpan;
    //       newCell.appendChild(contentCell);
    //     }
    //   }
    // });
    // table.deleteRow(2);
    // HelperService.exportHtml2Excel({ table: table, filename: 'Aylık Satış Raporu.xlsx' });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Aylık Satış Raporu');

    exportPivotGrid({
      component: this.dxPivotGrid.instance,
      worksheet: worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Aylık Satış Raporu.xlsx'
        );
      });
    });
  }
  exportPDF(): void {
    this.helperService.makePdfMonthlyCloseReport({
      name: 'Aylık Satış Raporu',
      table: this.tables,
    });
  }
  // printData(): any[] {
  //   const data: any[] = [];

  //   this.dataSource.pivots.forEach((el, index: number) => {
  //     const item: any = { ...el };
  //     this.dataSource.months.forEach((mo, moIndex) => {
  //       const re = mo.reports[index];
  //       const key = moIndex;
  //       item['totalAdisyon' + key] = re.totalAdisyon;
  //       item['totalPersonCount' + key] = re.totalPersonCount;
  //       item['totalQuantity' + key] = re.totalQuantity;
  //     });
  //     // Add summary table
  //     item['totalAdisyon' + 12] = this.horizontalSum(index, 'totalAdisyon');
  //     item['totalPersonCount' + 12] = this.horizontalSum(index, 'totalPersonCount');
  //     item['totalQuantity' + 12] = this.horizontalSum(index, 'totalQuantity');

  //     data.push(item);
  //   });
  //   const summaryItem: any = {
  //     buildName: 'Toplamlar',
  //     storName: '',
  //   };
  //   for (let index = 0; index < 12; index++) {
  //     summaryItem['totalAdisyon' + index] = this.toplam(index, 'totalAdisyon');
  //     summaryItem['totalPersonCount' + index] = this.toplam(index, 'totalPersonCount');
  //     summaryItem['totalQuantity' + index] = this.toplam(index, 'totalQuantity');
  //   }
  //   summaryItem['totalAdisyon' + 12] = this.crossSum('totalAdisyon');
  //   summaryItem['totalPersonCount' + 12] = this.crossSum('totalPersonCount');
  //   summaryItem['totalQuantity' + 12] = this.crossSum('totalQuantity');

  //   data.push(summaryItem);
  //   return data;
  // }
}
