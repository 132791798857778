import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { StoreStatus } from 'src/app/models/global-models';
import { Person, Store } from 'src/app/models/store-model';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';
import { StoreService } from 'src/app/services/store.service';

@Component({
  selector: 'app-store-form',
  templateUrl: './store-form.component.html',
  styleUrls: ['./store-form.component.scss']
})
export class StoreFormComponent implements OnInit {
  @Input() storeId = "";
  @Input() myCallback: Function;
  storeStatus : StoreStatus[] = []
  store: Store = new Store()
  constructor(public storeService: StoreService, public glb: GlobalService, public hs: HelperService) {  
    this.getBuildings()
    this.fillStatus()  
    this.getCities()
  }

  fillStatus()
  {
    this.storeStatus = [
      {"name": "Açık","value":"open"},
      {"name": "Hizmet Dışı","value":"out-of-service"},
      {"name": "Kapalı","value":"closed"},
      {"name": "Tadilatta","value":"repair"},
    ]
  }

  getBuildings()
  {
    this.storeService.getBuildings().then(o => {})
  }


  async getCities() {
    await this.hs.init()
  }

  ngOnChanges(changes: SimpleChanges): void {
    const currentItem: SimpleChange = changes.storeId.currentValue;
    this.storeId = changes.storeId.currentValue;
// ('storeID', this.storeId)
    if (this.storeId != "") {
      this.getStore()
    }
    else {
      this.store = new Store()
    }
  }

  onCityChanged(city) {
    this.store.city = city
    let cityCode = this.hs.cities.find(x => x.name == city).code
    this.hs.getTowns(cityCode).then(o => {

    })
  }

  onTownChanged(town) {
    this.store.town = town
  }

  onBuildingChanged(buildingId) {
    this.store.buildingId = buildingId
  }
  
  onStatusChanged(status)
  {
    this.store.status = status
  }

  getStore() {
    this.storeService.getStoreById(this.storeId).then(x => {
      this.store = x
      this.hs.getTowns(this.hs.cities.find(x => x.name == this.store.city).code).then(o=> {
        
      })
    })
  }


  saveStore()
  {  
    this.glb.loadingText = "Mağaza Kaydediliyor..."
    this.glb.loadingVisible = true;
   // console.log(this.store)
    this.storeService.saveStore(this.store).then(x=> {
      this.glb.loadingVisible = false;
      if (x.id != "")
      {
        this.glb.pushToast('Mağaza Kaydedildi.',"success",2000)
       
        this.myCallback()
      }
    })
  }


  validate() {
    var form = document.getElementsByClassName('store-form')[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return
    }
   this.saveStore()
  }



  ngOnInit(): void {
  }

}
