<dx-popup #userPrem [maxHeight]="700" [maxWidth]="600" [deferRendering]="false" [showTitle]="false"
  [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="permPopupVisible"
  [title]="popupTitle">
  <div *dxTemplate="let data of 'content'">
    <app-user-perm *ngIf="userPrem.visible" [myCallback]="theBoundCallback" [userId]="userId"></app-user-perm>
  </div>
</dx-popup>

<dx-popup #userForm [height]="500" [deferRendering]="false" [width]="this.glb.setPopupWidth()" [showTitle]="true"
  [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false" [(visible)]="userFormPopupVisible"
  [title]="popupTitle">
  <div *dxTemplate="let data of 'content'">
    <app-user-form [myCallback]="theBoundCallback" [userId]="userId"></app-user-form>
  </div>
</dx-popup>
<dx-popup #logPopup [height]="'90vh'" [deferRendering]="false" [maxWidth]="960" [showTitle]="true"
  [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false"
  [title]="selectedUser?.name + ' Logları.'">
  <div *dxTemplate="let data of 'content'">
    <!-- <dx-scroll-view > -->
    <kerzz-log [dataSource]="selectedUser" *ngIf="logPopup.visible"></kerzz-log>
    <!-- </dx-scroll-view> -->
  </div>
</dx-popup>

<dx-popup [height]="300" [maxHeight]="900" [deferRendering]="false" [width]="this.glb.setPopupWidth()"
  [showTitle]="true" [showCloseButton]="true" [dragEnabled]="false" [closeOnOutsideClick]="false"
  [(visible)]="resetPwdPopupVisible" title="Şifremi Unuttum">
  <div *dxTemplate="let data of 'content'">
    <dx-scroll-view width="100%" height="100%">
      <app-forget-password [myCallback]="theBoundCallback"></app-forget-password>
    </dx-scroll-view>
  </div>
</dx-popup>

<div class="d-none d-md-flex justify-content-md-end my-2">
  <div style="min-width: 500px; text-align: end">
    <button class="k-button" (click)="exportExcel(table)" nbButton>
      <span class="mr-2">
        <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
      </span>
      Excel İndir
    </button>
    <button class="ml-2 k-button" (click)="exportPDF()" nbButton>
      <span class="material-icons-outlined mr-2" style="font-size: 18px">
        picture_as_pdf
      </span>
      PDF İndir
    </button>
    <button *ngIf="
        userService.user.userRight != 'user' &&
        userService.user.userRight != 'power-user'
      " (click)="newUser()" class="ml-2 k-button" nbButton>
      <span class="material-icons-outlined" style="font-size: 18px">
        person_add
      </span>
      <span>Kullanıcı Ekle</span>
    </button>
  </div>
</div>

<!-- Mobile menu -->
<div class="d-md-none d-flex justify-content-end mb-2">
  <button [nbContextMenu]="operationMenuItems" nbContextMenuTag="user-operation" size="large" nbButton ghost>
    <nb-icon icon="menu-outline"></nb-icon>
  </button>
</div>
<!-- Mobile menu End -->
<!-- <div class="row mr-auto operaion-buttons">
    <ul>
        <li data-toggle="tooltip" title="Excel İndir" (click)="exportExcel(table)">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18"
                style="margin-right: 5px;margin-top: -5px;" />
            <span>Excel İndir</span>
        </li>
        <li data-toggle="tooltip" title="PDF İndir">
            <span class="material-icons-outlined" style="font-size: 18px;">
                picture_as_pdf
            </span>
            <span>PDF İndir</span>
        </li>

        <li data-toggle="tooltip" title="Yeni Kullanıcı Ekle" (click)="newUser()">
            <span class="material-icons-outlined" style="font-size: 18px;">
                person_add
            </span>
            <span>Kullanıcı Ekle</span>
        </li>

    </ul>
</div> -->
<div style="clear: both"></div>

<div class="row">
  <div class="col-12 table-style">
    <table class="table" #table>
      <thead>
        <tr>
          <th style="width: 120px">Yetki</th>
          <th>Ad Soyad</th>
          <th>Telefon No</th>
          <th>E-Mail Adresi</th>
          <th class="durum">Yetki</th>

          <th class="durum">Durumu</th>
          <th class="text-center" *ngIf="
              userService?.user?.userRight != 'user' &&
              userService?.user?.userRight != 'power-user'
            ">
            İşlemler
          </th>
        </tr>
        <tr [formGroup]="filterForm">
          <th style="width: 120px"></th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input fieldSize="small" type="text" nbInput formControlName="name" />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input fieldSize="small" type="text" nbInput formControlName="gsm" />
            </nb-form-field>
          </th>
          <th>
            <nb-form-field>
              <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
              <input fieldSize="small" type="text" nbInput formControlName="mail" />
            </nb-form-field>
          </th>
          <th class="durum">
            <nb-select class="mySelect" placeholder="Yetki" size="small"
              style="box-shadow: unset !important; width: 100px !important" formControlName="userRight">
              <nb-option [value]="null">Seçimi Temizle</nb-option>

              <nb-option value="user">Kullanıcı</nb-option>
              <nb-option value="power-user">Süper Kullanıcı</nb-option>
              <nb-option value="administrator">Admin</nb-option>
            </nb-select>
          </th>
          <th class="durum">
            <nb-select class="mySelect" placeholder="Durum" size="small"
              style="box-shadow: unset !important; width: 100px !important" formControlName="status">
              <nb-option [value]="null">Seçimi Temizle</nb-option>

              <nb-option value="registered">Aktif</nb-option>
              <nb-option value="banned">Pasif</nb-option>
              <nb-option value="new">Yeni</nb-option>
              <nb-option value="deleted">Silinmiş</nb-option>
            </nb-select>
          </th>
          <th class="text-center" *ngIf="
              userService.user.userRight != 'user' &&
              userService.user.userRight != 'power-user'
            "></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of dataSource">
          <td style="width: 120px">
            <span class="material-icons-outlined" (click)="openModal(item)" data-toggle="tooltip"
              title="Kullanıcı Yetkileri">
              tune
            </span>
          </td>
          <td>{{ item.name }}</td>
          <td class="text-right">
            {{ item.gsm.toString() | mask: "+00(000) 000 00 00" }}
          </td>
          <td>{{ item.mail }}</td>
          <td class="durum">
            <div class="text-center" style="width: 90px">
              <span>
                {{ getRight(item) }}
              </span>
            </div>
          </td>
          <td class="durum">
            <div class="text-center" style="width: 90px" [ngClass]="{
                passive: item.status == 'banned' || item.status == 'deleted',
                active: item.status == 'registered',
                new: item.status == 'new'
              }">
              <span>
                {{
                item.status == "banned" || item.status == "deleted"
                ? "Pasif"
                : item.status == "registered"
                ? "Aktif"
                : "Yeni"
                }}
              </span>
            </div>
          </td>
          <td *ngIf="
              userService.user.userRight != 'user' &&
              userService.user.userRight != 'power-user'
            " class="d-flex justify-content-start justify-content-xl-center">
            <button class="operation-button" (click)="getLogs(item)" nbButton ghost data-toggle="tooltip"
              title="Loglar">
              <span>
                <img src="https://img.icons8.com/fluent-systems-regular/48/000000/clipboard-list--v3.png" width="24"
                  style="margin-right: 5px" />
              </span>
            </button>
            <button class="operation-button" nbButton ghost (click)="editUser(item.id)" data-toggle="tooltip"
              title="Düzenle">
              <nb-icon icon="edit-outline"></nb-icon>
            </button>
            <!-- <button nbButton ghost (click)="delete(item.id)">
                            <nb-icon icon="trash-2-outline"></nb-icon>
                        </button> -->
            <button class="operation-button" nbButton ghost (click)="resetPwd(item.mail)" data-toggle="tooltip"
              title="Şifreyi Sıfırla">
              <!-- <nb-icon icon="edit-outline"></nb-icon> -->
              <span>
                <img style="
                    filter: contrast(0.1);
                    transform: scaleX(-1) scaleY(-1);
                  " src="https://img.icons8.com/metro/26/000000/key.png" width="18" />
              </span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>