import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { User, UserLog } from '../models/user-model';
import { MasterService } from './master.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor(
    private userService: UserService,
    public masterService: MasterService
  ) {}
  getUserLog(userId: string): Observable<UserLog[]> {
    return this.masterService.serviceRequest(
      '/log/getUserLogs',
      { userId },
      this.userService.token,
      'post'
    ) as Observable<UserLog[]>;
  }
}
