import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { HelperService } from 'src/app/services/helper.service';
@Pipe({
  name: 'customSelectLabel',
})
export class CustomSelectLabelPipe implements PipeTransform {
  transform(
    list: any[],
    selecteds: string[],
    key: string,
    valueKey: string,
    placeholder
  ): string[] {
    if (!list || !selecteds) {
      if (selecteds?.some((el) => el === 'All')) {
        return [placeholder];
      }
      return [];
    }
    const result = [...list]
      .map((el) => (selecteds.some((s) => s === el[key]) ? el[valueKey] : null))
      .filter((el) => el != null);
    if (selecteds.length === 1 && selecteds.some((el) => el === 'All')) {
      return [placeholder];
    }
    return result;
  }
}
