import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userMail: string = '';
  password: string = '';
  forgetPasswordPopupVisible: boolean = false;
  resetPwdPopupVisible: boolean = false;
  public theBoundCallback: Function;
  constructor(
    public us: UserService,
    public glb: GlobalService,
    private nav: Router
  ) {}

  logo = this.glb.getLogoForDomain()
  login(): void {
    if (this.userMail == '' || this.password == '') {
      this.glb.pushToast(
        'Lütfen Mail Adresinizi ve Şifrenizi Eksiksiz Giriniz!',
        'warning',
        2000
      );
      return;
    }
    this.glb.loadingText = 'Giriş Yapılıyor';
    this.glb.loadingVisible = true;
    this.us.login(this.userMail, this.password).then((o) => {
      setTimeout(() => {
      //  console.log('loginResult', o);
        if (o == true) {
          this.glb.loadingVisible = false;
          this.nav.navigateByUrl('layout/dashboard');
        }
      }, 2000);
    });
  }

  validate(): void {
    const form = document.getElementsByClassName(
      'login-form'
    )[0] as HTMLFormElement;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add('was-validated');
    if (form.checkValidity() === false) {
      return;
    }
    this.login();
  }

  resetPassword(): void {
    this.forgetPasswordPopupVisible = true;
  }

  public theCallback(): void {
    this.forgetPasswordPopupVisible = false;
  }

  ngOnInit(): void {
    this.theBoundCallback = this.theCallback.bind(this);
  }
}
