import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { HelperService } from 'src/app/services/helper.service';

export const resultFilterValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const buildId = control.get('buildId');
  const storeId = control.get('storeId');
  const serviceTypes = control.get('serviceTypes');

  return !HelperService.isNullOrUndefined(buildId.value) &&
    !HelperService.isNullOrUndefined(storeId.value) &&
    !HelperService.isNullOrUndefined(serviceTypes.value) &&
    (buildId.value.filter((el) => el !== 'All').length === 0 ||
      storeId.value.filter((el) => el !== 'All').length === 0 ||
      serviceTypes.value.filter((el) => el !== 'All').length === 0)
    ? { storeSelect: true }
    : null;
};
