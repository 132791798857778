<kerzz-report-filter (onSearched)="onSearched($event)" [isHaveServiceType]="false"></kerzz-report-filter>

<div class="d-flex my-2 flex-wrap">
  <button [disabled]="!dataSource || dataSource?.length === 0" class="k-button" (click)="exportExcel(null)" nbButton>
    <span class="mr-2">
      <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
    </span>
    Excel İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="exportPDF()" class="ml-lg-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      picture_as_pdf
    </span>

    PDF İndir
  </button>
  <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-lg-2 k-button" nbButton>
    <span class="material-icons-outlined mr-2" style="font-size: 18px">
      <nb-icon icon="trash-2-outline"></nb-icon>
    </span>
    Filtreyi Temizle
  </button>
</div>
<div class="col-12 md-wd overflow-auto">
  <dx-data-grid class="" [dataSource]="dataSource" [showBorders]="true" [showColumnLines]="false" [showRowLines]="true"
    [cellHintEnabled]="true" [allowColumnReordering]="true" [allowColumnResizing]="true" [columnAutoWidth]="true">
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-pager [visible]="true"  [allowedPageSizes]="allowedPageSizes" [showPageSizeSelector]="showPageSizeSelector"
    [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
  </dxo-pager>
  <dxo-state-storing [enabled]="true" type="localStorage" storageKey="z-report-detail-state"></dxo-state-storing>
    <dxo-export></dxo-export>

    <dxo-summary>
      <dxi-total-item column="vat0BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="vat1BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="vat8BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="vat18BaseTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="grandVatTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
      <dxi-total-item column="grandTotal" [valueFormat]="{ type: 'currency', precision: 2 }" valueFormat="currency"
        summaryType="sum">
      </dxi-total-item>
    </dxo-summary>

    <dxi-column [allowHiding]="true" dataField="buildingName" caption="Yapı Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="storeName" caption="Mağaza Adı"></dxi-column>
    <dxi-column [allowHiding]="true" dataField="zReportDate" dataType="date" format="dd/MM/yyyy" caption="Tarih">
    </dxi-column>
    <dxi-column [allowHiding]="true" dataField="ecrSerialId" caption="ECR Serial"></dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ precision: 0 }" dataField="zReportNumber" caption="Z No"></dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ precision: 0 }" dataField="ekuNo" caption="Eku No"></dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="vat0BaseTotal"
      dataType="number" caption="	%0  Matrah">
    </dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="vat1BaseTotal"
      dataType="number" caption="%1  Matrah">
    </dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="vat8BaseTotal"
      dataType="number" caption="%8  Matrah">
    </dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="vat18BaseTotal"
      dataType="number" caption="%18  Matrah">
    </dxi-column>

    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="grandVatTotal"
      caption="Toplam KDV" [format]="{ precision: 2 }"></dxi-column>
    <dxi-column [allowHiding]="true" [format]="{ type: 'currency', precision: 2 }" dataField="grandTotal"
      caption="Toplam Satış"></dxi-column>
  </dx-data-grid>
</div>