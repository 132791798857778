<div class="d-flex my-2">
    <button class="k-button" (click)="exportExcell(table)" nbButton>
        <span class="mr-2">
            <img src="https://img.icons8.com/fluent-systems-regular/48/000000/ms-excel.png" width="18" />
        </span>
        Excel İndir
    </button>
     <button (click)="exportPDF()" class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            picture_as_pdf
        </span>
        PDF İndir
    </button>
    <!--
    <button [disabled]="!dataSource || dataSource?.length === 0" (click)="clear()" class="ml-2 k-button" nbButton>
        <span class="material-icons-outlined mr-2" style="font-size: 18px;">
            <nb-icon icon="trash-2-outline"></nb-icon>
        </span>
        Filtreyi Temizle
    </button> -->
</div>
<div class="table-style mx-2 " style="height: calc(100vh - 300px) !important; max-width: 100%;overflow-x: scroll;">
    <dx-scroll-view direction="both">

        <table #table class="table" >
            <thead>
                <tr>
                    <th class="date">İşlem Tarihi</th>
                    <th class="userName">Kullanıcı Adı</th>
                    <th class="logType">Log Tipi</th>
                    <th class="ip">İp Adresi</th>
                    <th>Eski Değer</th>
                    <th>Yeni Değer</th>

                </tr>
                <tr [formGroup]="filterForm">
                    <th class="date">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput [nbDatepicker]="createDatePicker" formControlName="createDate">
                            <nb-datepicker #createDatePicker></nb-datepicker>
                        </nb-form-field>
                    </th>
                    <th class="userName">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="userId">
                        </nb-form-field>
                    </th>
                    <th class="logType">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="logType">
                        </nb-form-field>
                    </th>
                    <th class="ip">
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="ipAddress">
                        </nb-form-field>
                    </th>
                    <th>
                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="oldValue">
                        </nb-form-field>

                    </th>
                    <th>

                        <nb-form-field>
                            <nb-icon nbPrefix icon="funnel-outline" pack="eva"></nb-icon>
                            <input fieldSize="small" type="text" nbInput formControlName="value">
                        </nb-form-field>
                    </th>

                </tr>
            </thead>

            <tbody #body>
                <tr *ngFor="let item of dataSource;let index = index">
                    <td class="date">
                        {{item.createDate | date:'dd/MM/yyyy'}}
                    </td>
                    <td class="userName">{{item.userId}}</td>
                    <td class="logType">{{item.logType}}</td>
                    <td class="ip">{{item.ipAddress}}</td>
                    <!-- <td>{{item.oldValue}} </td> -->
                    <td>
                        <div class="d-flex d-inline" style="overflow-wrap: anywhere;"
                            *ngFor="let row of format(item,true)">

                            <div style="font-weight: bolder; white-space: nowrap;margin-right: 3px;">
                                {{row.display}}:
                            </div>
                            {{row.value}}

                        </div>
                    </td>

                    <!-- <td>{{item.value}}</td> -->

                    <td>
                        <!-- <div *ngIf="!item['expand']" class="d-flex justify-content-end">
        
                                    <button nbButton ghost (click)="item.expand=true">
                                        Detaylar <nb-icon icon="arrow-ios-downward-outline"></nb-icon>
                                    </button>
                                </div> -->
                        <!-- <ng-container *ngIf="item['expand']"> -->

                        <div class="d-flex d-inline" style="overflow-wrap: anywhere;" *ngFor="let row of format(item)">

                            <div style="font-weight: bolder; white-space: nowrap;margin-right: 3px;">
                                {{row.display}}:
                            </div>
                            {{row.value}}

                        </div>
                        <!-- </ng-container> -->
                    </td>

                </tr>

            </tbody>


        </table>
    </dx-scroll-view>

</div>
<div class="d-flex justify-content-center my-1">
    <button [disabled]="pageFormControl.value<=1" nbButton (click)="setPage(false)" ghost>
        <nb-icon icon="arrow-ios-back-outline"></nb-icon>
    </button>
    <input style="width: 100px;text-align: center;" class="mx-2" type="text" [formControl]="pageFormControl" nbInput>
    <button [disabled]="pageFormControl.value>allDataSource.length/pageRowCount" nbButton (click)="setPage(true)" ghost>
        <nb-icon icon="arrow-ios-forward-outline"></nb-icon>
    </button>
</div>