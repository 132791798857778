import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { CellDef } from 'jspdf-autotable';
import { Subscription } from 'rxjs';
import { Store, Building, LinkedStore } from 'src/app/models/store-model';
import { HelperService, PdfMakeModel } from 'src/app/services/helper.service';
import { MockService } from 'src/app/services/mock.service';
import { StoreService } from 'src/app/services/store.service';
import { UserService } from 'src/app/services/user.service';
import { resultFilterValidator } from '../reportValidators';

import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { DxDataGridComponent } from 'devextreme-angular';
import { ReportSearch, SaltesSummaryReport } from 'src/app/models/report.model';
import { ReportService } from 'src/app/services/report.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-sales-summary-report',
  templateUrl: './sales-summary-report.component.html',
  styleUrls: ['./sales-summary-report.component.scss'],
  providers: [DatePipe, DecimalPipe, CurrencyPipe],
})
export class SalesSummaryReportComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  readonly allowedPageSizes = [10, 15, 'all'];

  readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  displayMode = 'compact';

  showPageSizeSelector = true;

  showInfo = true;

  showNavButtons = true;
  dataSource: SaltesSummaryReport[] = [];
  constructor(
    private mockServiec: MockService,
    private fb: FormBuilder,
    public storeService: StoreService,
    private reportService: ReportService,

    public helperService: HelperService,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private currencyPipe: CurrencyPipe,
    private userService: UserService,
    private globalService: GlobalService
  ) {}
  clear() {
    this.dataSource = [];
  }
  onSearched(e: ReportSearch) {
    // this.dataSource = [...HelperService.MainFilter(this.mockServiec.getSaleSummary(), e as any)];

    this.globalService.loadingText = 'Lütfen bekleyin.';
    this.globalService.loadingVisible = true;

    this.reportService.getSaleSummaryReport(e).then((res) => {
      this.dataSource = res;
      setTimeout(() => {
        this.globalService.loadingVisible = false;
      }, 200);
    });
  }

  ngOnInit(): void {}

  exportExcel(e?: HTMLElement) {
    // HelperService.exportexcel(e, "Satış Özet Raporu.xlsx", 'L');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Satış Detay Raporu');

    exportDataGrid({
      component: this.dataGrid.instance,
      worksheet: worksheet,
      autoFilterEnabled: true,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        FileSaver.saveAs(
          new Blob([buffer], { type: 'application/octet-stream' }),
          'Satış Detay Raporu.xlsx'
        );
      });
    });
  }

  toplam(key: string, key2?: string): number {
    if (HelperService.isNullOrUndefined(this.dataSource)) {
      return 0;
    }
    return this.dataSource.reduce((accumulator, currentValue) => {
      // console.log('accumulator:', accumulator);
      // console.log('currentValue:', currentValue);
      if (!HelperService.isNullOrUndefined(key2)) {
        return (
          accumulator + Number(currentValue[key]) - Number(currentValue[key2])
        );
      }
      return accumulator + Number(currentValue[key]);
    }, 0);
  }

  divide(key: string, key2: string): number {
    return this.toplam(key) / this.toplam(key2);
  }

  exportPDF() {
    /**
     * item.status == 'banned' || item.status == 'deleted')?'Pasif':(item.status ==
                                'registered')?'Aktif':'Yeni'
     */
    const statusLookUp: any[] = [];
    const headers: PdfMakeModel[] = [
      {
        key: 'businessDate',
        display: 'Taih',
        customValue: (el) =>
          this.datePipe.transform(el.businessDate, 'dd/MM/yyyy'),
      },
      {
        key: 'buildingName',
        display: 'Yapı Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'storeName',
        display: 'Mağaza Adı',
        cell: { styles: { cellWidth: 20 } },
      },
      {
        key: 'grandTotal',
        display: 'T.Ciro',
        cell: { styles: { halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.grandTotal, '₺'),
      },
      {
        key: 'vatTotal',
        display: 'T.KDV Tutarı',
        cell: { styles: { cellWidth: 20, halign: 'right' } },
        customValue: (el) => this.currencyPipe.transform(el.vatTotal, '₺'),
      },
      {
        key: 'netTotal',
        display: 'T.Net Satış',
        cell: { styles: { cellWidth: 20, halign: 'right' } },

        customValue: (el) => this.currencyPipe.transform(el.netTotal, '₺'),
      },

      {
        key: 'countFolio',
        display: 'T. Adisyon Sayısı',
        cell: { styles: { cellWidth: 20, halign: 'right' } },
      },
      {
        key: 'avgPerFolioTotal',
        display: 'O. Adisyon Tutarı ',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.avgPerFolioTotal, '₺'),
      },
      {
        key: 'countPax',
        display: 'T. Kişi Sayısı',
        cell: { styles: { halign: 'right' } },
      },
      {
        key: 'avgPerPaxTotal',
        display: 'O. Kişi Sayısı',
        cell: { styles: { halign: 'right' } },
        customValue: (el) =>
          this.currencyPipe.transform(el.avgPerPaxTotal, '₺'),
      },
      {
        key: 'discountTotal',
        display: 'İ. Toplamları',
        cell: { styles: { cellWidth: 20, halign: 'right' } },

        customValue: (el) => this.currencyPipe.transform(el.discountTotal, '₺'),
      },
    ];
    const summary: CellDef[] = [
      {
        content: 'Toplamlar',
        colSpan: 3,
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('grandTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('vatTotal'), '₺'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('netTotal'), '₺'),
      },

      {
        styles: { halign: 'right' },
        content: this.toplam('countFolio'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(
          this.toplam('avgPerFolioTotal'),
          '₺'
        ),
      },
      {
        styles: { halign: 'right' },
        content: this.toplam('countPax'),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(
          this.toplam('avgPerPaxTotal'),
          '₺'
        ),
      },
      {
        styles: { halign: 'right' },
        content: this.currencyPipe.transform(this.toplam('discountTotal'), '₺'),
      },
    ];
    this.helperService.makePdf(
      'Satış Özet Raporu',
      this.dataSource,
      headers,
      false,
      summary
    );
  }
}
