<div class="sidebar">
  <!-- <h6>Mağaza Seçimi</h6>
  <h5>Optimum AVM</h5>
  <small>Değiştir</small> -->
  <!-- <input
    type="checkbox"
    [checked]="darkMode$ | async"
    (change)="onToggle()"
  /> -->

  <ul>
    <ng-container *ngFor="let item of menu | menu">
      <li
        [ngClass]="{
          'active-menu': isActive(item),
          'd-none':
            item.tag === 'user-operations' &&
            userService?.user?.userRight === 'user'
        }"
        (click)="navigateTo(item)"
      >
        <div class="row mx-auto">
          <div class="col-2">
            <span style="font-size: 20px" class="material-icons-outlined">
              {{ item.icon }}
            </span>
          </div>
          <div class="col-10">
            <span>
              {{ item.name }}
            </span>
          </div>
        </div>
      </li>
      <!-- Secon Level  -->
      <ng-container *ngIf="isActive(item)">
        <ng-container *ngFor="let firstChild of item.children">
          <li
            class="ml-3"
            [ngClass]="{ 'active-submenu': isActive(firstChild) }"
            (click)="navigateTo(firstChild)"
          >
            <div class="row mx-auto text-center">
              <div class="col-2">
                <span style="font-size: 20px" class="material-icons-outlined">
                  {{ item.icon }}
                </span>
              </div>
              <div class="col-10 text-left">
                <span> {{ firstChild.name }} </span>
              </div>
            </div>
          </li>
        </ng-container>
      </ng-container>
      <!-- Secon Level  End-->
    </ng-container>
  </ul>
</div>
