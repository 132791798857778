import { Injectable } from '@angular/core';
import { Rights } from '../models/global-models';

@Injectable({
  providedIn: 'root',
})
export class MockService {
  reportes: any[] = [
    {
      date: new Date(),
      buildName: 'Balçova Kipa AVM',
      storName: 'Reyhan Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'AS25658DF',
      productName: 'Çiklatalı Pasta',
      totalQuantity: 10.155,
      kdv: 1,
      totalEndorsement: 7050000,
      totalKDV: 1,
      totalNetSales: 696645555,
    },
    {
      date: new Date(),
      buildName: 'kayseri AVM',
      storName: 'Kayı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'AasdfaS25658DF',
      productName: 'Muzlu Pasta',
      totalQuantity: 1.61,
      kdv: 1,
      totalEndorsement: 1020,
      totalKDV: 2,
      totalNetSales: 42322,
    },

    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      productGroup: 'Tatlılar',
      productCode: 'RT12231231',
      productName: 'Çilekli Pasta',
      totalQuantity: 120.12,
      kdv: 14,
      totalEndorsement: 7596,
      totalKDV: 3,
      totalNetSales: 6784534435,
    },
  ];
  saleSummary: any = [
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      totalQuantity: 10.12,
      totalKDV: 3,
      totalAdisyon: 2,
      totalNetSale: 56,
      middleAdisyon: 28,
      totalPersonCount: 5,
      middlePersonCount: 11.2,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      totalQuantity: 130.12,
      totalKDV: 3,
      total: 7554,
      totalAdisyon: 3,
      totalNetSale: 56,
      middleAdisyon: 18.6646546,
      totalPersonCount: 6,
      middlePersonCount: 9.3,
    },
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      totalQuantity: 120.12,
      totalKDV: 3,
      totalAdisyon: 2,
      totalNetSale: 42,
      middleAdisyon: 21,
      totalPersonCount: 1,
      middlePersonCount: 42,
    },
  ];
  monthlyClose: any = [
    {
      date: new Date(),
      buildName: 'Ankara AVM',
      storName: 'Kahtalı Pastanesi',
      totalQuantity: 10.12,
      totalKDV: 3,
      totalNetSale: 56,
      totalAdisyon: 7554,
      middleAdisyon: 2,
      totalPersonCount: 5,
      middlePersonCount: 5,
    },
    {
      date: new Date(),
      buildName: 'Urfa AVM',
      storName: 'Kahtalı Pastanesi',
      totalQuantity: 12.12,
      totalKDV: 3,
      totalNetSale: 6,
      totalAdisyon: 54,
      middleAdisyon: 8,
      totalPersonCount: 25,
      middlePersonCount: 15,
    },
  ];

  monthlySale: any = {
    pivots: [
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
      {
        buildName: 'Urfa AVM',
        storName: 'Kahtalı Pastanesi',
      },
      {
        buildName: 'Antep AVM',
        storName: 'Fıstık Pastanesi',
      },
    ],
    months: [
      {
        id: 0,
        name: 'Ocak',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 50,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 5,
            totalAdisyon: 4,
            totalPersonCount: 5,
          },
        ],
      },
      {
        id: 1,
        name: 'Şubat',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 2,
        name: 'Mart',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 3,
        name: 'Nisan',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 4,
        name: 'Mayıs',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 5,
        name: 'Haziran',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 6,
        name: 'Temmuz',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 7,
        name: 'Ağustos',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 8,
        name: 'Eylül',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 9,
        name: 'Ekim',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 10,
        name: 'Kasım',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
      {
        id: 11,
        name: 'Aralık',
        reports: [
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
          {
            totalQuantity: 1,
            totalAdisyon: 54,
            totalPersonCount: 25,
          },
        ],
      },
    ],
  };

  userRightMock: Rights[] = [
    { name: 'Kullanıcı', value: 'user' },
    { name: 'Süper Kullanıcı', value: 'power-user' },
    { name: 'Admin', value: 'administrator' },
  ];
  userStatus: {
    value?: 'none' | 'open' | 'out-of-service' | 'closed' | 'repair';
    text?: string;
  }[] = [
    {
      value: 'closed',
      text: 'Kapalı',
    },
    {
      value: 'open',
      text: 'Açık',
    },
    {
      value: 'out-of-service',
      text: 'Hizmet Dışı',
    },
    {
      value: 'repair',
      text: 'Tadilatta',
    },
  ];
  serviceTypes: any[] = [
    {
      value: 'in-store',
      text: 'Restoran ',
    },
    {
      value: 'delivery',
      text: 'Paket',
    },
    {
      value: 'self',
      text: 'Self Servis',
    },
    {
      value: 'take-away',
      text: 'Al-Götür',
    },
  ];

  constructor() {}

  getReportes(): any[] {
    return this.reportes;
  }
  getSaleSummary(): any[] {
    return this.saleSummary;
  }
  getMonthlyClose(): any[] {
    return this.monthlyClose;
  }
  getMonthlySale(): any {
    return this.monthlySale;
  }

  getUserRightMocks(): Rights[] {
    return this.userRightMock;
  }
  getuserStatusMocks(): {
    value?: 'none' | 'open' | 'out-of-service' | 'closed' | 'repair';
    text?: string;
  }[] {
    return this.userStatus;
  }

  getServiceTypes(): any[] {
    return this.serviceTypes;
  }
}
