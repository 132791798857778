import { NgModule } from '@angular/core';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonGroupModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbLayoutModule,
  NbLayoutScrollService,
  NbListModule,
  NbMenuModule,
  NbSelectModule,
  NbSpinnerModule,
  NbUserModule,
} from '@nebular/theme';
import {
  DxAccordionModule,
  DxAutocompleteModule,
  DxButtonModule,
  DxCheckBoxModule,
  DxDataGridModule,
  DxDateBoxModule,
  DxDropDownButtonModule,
  DxFileUploaderModule,
  DxFormModule,
  DxListModule,
  DxPivotGridModule,
  DxPopoverModule,
  DxPopupModule,
  DxRadioGroupModule,
  DxResponsiveBoxModule,
  DxScrollViewModule,
  DxSelectBoxModule,
  DxTabPanelModule,
  DxTabsModule,
  DxTemplateModule,
  DxTextBoxModule,
  DxToolbarModule,
} from 'devextreme-angular';
import {
  DxiItemModule,
  DxoColumnChooserModule,
  DxoLabelModule,
} from 'devextreme-angular/ui/nested';
import { NbMomentDateModule } from '@nebular/moment';
import { NbDateFnsDateModule } from '@nebular/date-fns';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import dxDateBox from 'devextreme/ui/date_box';
import { TRYCurrecyPipe } from 'src/app/services/custom-currency.pipe';
import { KerzzReportFilterComponent } from '../components/kerzz-report-filter/kerzz-report-filter.component';
import { HelperService } from 'src/app/services/helper.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomSelectLabelPipe } from '../services/customSelectLabel.pipe';
import { BrowserModule } from '@angular/platform-browser';
import { KerzzUserComponent } from '../components/kerzz-user/kerzz-user.component';
import { MenuPipe } from '../services/menu.pipe';
import { ArrayFilterPipe } from 'src/app/helpers/pipes/array-filter.pipe';

const DEVEXTREME: any[] = [
  DxTabsModule,
  DxButtonModule,
  DxFormModule,
  DxoLabelModule,
  DxTextBoxModule,
  DxiItemModule,
  DxToolbarModule,
  DxTabPanelModule,
  DxDataGridModule,
  DxPopupModule,
  DxListModule,
  DxAccordionModule,
  DxSelectBoxModule,
  DxScrollViewModule,
  DxTemplateModule,
  DxTabsModule,
  DxDropDownButtonModule,
  DxRadioGroupModule,
  DxResponsiveBoxModule,
  DxAutocompleteModule,
  DxPopoverModule,
  DxCheckBoxModule,
  DxFileUploaderModule,
  DxDateBoxModule,
  DxPivotGridModule,
  DxoColumnChooserModule,
];
const nbModules = [
  NbEvaIconsModule,
  NbIconModule,
  NbInputModule,
  NbCardModule,
  NbLayoutModule,
  NbSelectModule,
  NbButtonModule,
  NbButtonGroupModule,
  NbUserModule,
  NbActionsModule,
  NbContextMenuModule,
  NbMenuModule,
  NbDatepickerModule,
  NbMomentDateModule,
  NbDateFnsDateModule,
  NbListModule,
  NbFormFieldModule,
  NbBadgeModule,
  NbSpinnerModule,
];

@NgModule({
  exports: [
    ...DEVEXTREME,
    ...nbModules,
    FontAwesomeModule,
    NgSelectModule,
    KerzzReportFilterComponent,
    FormsModule,
    ReactiveFormsModule,
    TRYCurrecyPipe,
    CustomSelectLabelPipe,
    KerzzUserComponent,
    MenuPipe,
    BrowserModule,
    ArrayFilterPipe,

  ],
  imports: [
    ...DEVEXTREME,
    ...nbModules,
    FontAwesomeModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
  ],
  declarations: [
    KerzzReportFilterComponent,
    TRYCurrecyPipe,
    CustomSelectLabelPipe,
    KerzzUserComponent,
    MenuPipe,
    ArrayFilterPipe,

  ],
  providers: [MenuPipe],
})
export class SharedModule { }
